import React, { Component } from 'react'

export default class RecentVisitedAssets extends Component {
    render() {
        return (
            <div className="col-xs-12 col-md-12 col-sm-12  secton-headings best-Seller recently-added">
        <h1 className="acme-support-heading hidden-lg ">Recently Added Support Assets</h1>
        <h1 className="hidden-xs ipad-hidden">Recently Added Support Assets</h1>
        {/* Mobile View */}
        <div className="mobile-display container12">
          <div className="support-assets-slider">
            <div className="slide">
              <a className="recentSupport" href="/parker/OpenContent/2021+OES+Dist+price+list?staticfile=c1256ce1ea305710VgnVCM100000e6651dacRCRD" target="_blank">
                <img src="https://www.parker.com/parkerimages/Parker.com/Controlled Documents/Support/Support Category images/literature-documents1.gif" className height={90} width={90} alt="Support Image" title="OES LIST PRICE--2021" />
                <div className="text-details supportData support-text-height" data-rcrd="c1256ce1ea305710VgnVCM100000e6651dacRCRD" data-assettype="Static File" data-url="2021+OES+Dist+price+list" style={{minHeight: '0px'}}>
                  <p>OES LIST PRICE--2021</p>
                </div>
              </a>
            </div>
            <div className="slide">
              <a className="recentSupport" href="/parker/OpenContent/FCG-TFD-Metric-Part-Number-Announcement-Phase-1?staticfile=c88535e9993e6710VgnVCM100000e6651dacRCRD" target="_blank">
                <img src="https://www.parker.com/parkerimages/Parker.com/Controlled Documents/Support/Support Category images/literature-documents1.gif" className height={90} width={90} alt="Support Image" title="FCG TFD Metric Part Number Additions - Phase 1" />
                <div className="text-details supportData support-text-height" data-rcrd="c88535e9993e6710VgnVCM100000e6651dacRCRD" data-assettype="Static File" data-url="FCG-TFD-Metric-Part-Number-Announcement-Phase-1" style={{minHeight: '0px'}}>
                  <p>FCG TFD Metric Part Number Additions - Phase 1</p>
                </div>
              </a>
            </div>
            <div className="slide">
              <a className="recentSupport" href="/parker/OpenContent/MSG_HPS_Parker+HPS+Leadtime+Updates+2021_01_01?staticfile=6493f81b54fc6710VgnVCM100000e6651dacRCRD" target="_blank">
                <img src="https://www.parker.com/parkerimages/Parker.com/Controlled Documents/Support/Support Category images/literature-documents1.gif" className height={90} width={90} alt="Support Image" title="MSG HPS Parker HPS Leadtime Update 2021 01 01.xlsx" />
                <div className="text-details supportData support-text-height" data-rcrd="6493f81b54fc6710VgnVCM100000e6651dacRCRD" data-assettype="Static File" data-url="MSG_HPS_Parker+HPS+Leadtime+Updates+2021_01_01" style={{minHeight: '0px'}}>
                  <p>MSG HPS Parker HPS Leadtime Update 2021 01 01.xlsx</p>
                </div>
              </a>
            </div>
            <div className="slide">
              <a className="recentSupport" href="/parker/OpenContent/MSG-HPS+Product+Removed+from+Sale+REV122?staticfile=e5cc895e58627710VgnVCM100000e6651dacRCRD" target="_blank">
                <img src="https://www.parker.com/parkerimages/Parker.com/Controlled Documents/Support/Support Category images/literature-documents1.gif" className height={90} width={90} alt="Support Image" title="MSG-HPS Product Removed from Sale REV122" />
                <div className="text-details supportData support-text-height" data-rcrd="e5cc895e58627710VgnVCM100000e6651dacRCRD" data-assettype="Static File" data-url="MSG-HPS+Product+Removed+from+Sale+REV122" style={{minHeight: '0px'}}>
                  <p>MSG-HPS Product Removed from Sale REV122</p>
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* Desktop View */}
        <div className="col-xs-6 col-md-3 col-sm-3 padL0 padLM15 padRM5 desktop-display">
          <a className="recentSupport" href="/parker/OpenContent/2021+OES+Dist+price+list?staticfile=c1256ce1ea305710VgnVCM100000e6651dacRCRD" target="_blank">
            <div className="support-assets supportData" data-rcrd="c1256ce1ea305710VgnVCM100000e6651dacRCRD" data-assettype="Static File" data-url="2021+OES+Dist+price+list" style={{minHeight: '335px'}}>
              <img src="https://www.parker.com/parkerimages/Parker.com/Controlled Documents/Support/Support Category images/literature-documents1.gif" height={90} width={90} className="hidden-lg hidden-sm" alt="Support Image" title="Support Image" />
              <img src="https://www.parker.com/parkerimages/Parker.com/Controlled Documents/Support/Support Category images/literature-documents1.gif" height={115} width={115} className="hidden-xs" alt="Support Image" title="OES LIST PRICE--2021" />
              <h3>OES LIST PRICE--2021</h3>
              <p>2021 List pricing for OES</p>
            </div>
          </a>
        </div>
        <div className="col-xs-6 col-md-3 col-sm-3  padLM15 padRM5 desktop-display">
          <a className="recentSupport" href="/parker/OpenContent/FCG-TFD-Metric-Part-Number-Announcement-Phase-1?staticfile=c88535e9993e6710VgnVCM100000e6651dacRCRD" target="_blank">
            <div className="support-assets supportData" data-rcrd="c88535e9993e6710VgnVCM100000e6651dacRCRD" data-assettype="Static File" data-url="FCG-TFD-Metric-Part-Number-Announcement-Phase-1" style={{minHeight: '335px'}}>
              <img src="https://www.parker.com/parkerimages/Parker.com/Controlled Documents/Support/Support Category images/literature-documents1.gif" height={90} width={90} className="hidden-lg hidden-sm" alt="Support Image" title="Support Image" />
              <img src="https://www.parker.com/parkerimages/Parker.com/Controlled Documents/Support/Support Category images/literature-documents1.gif" height={115} width={115} className="hidden-xs" alt="Support Image" title="FCG TFD Metric Part Number Additions - Phase 1" />
              <h3>FCG TFD Metric Part Number Additions - Phase 1</h3>
              <p>1/8/21: Announcement detailing phase one of our metric part number additions to standard based on distributor requests. A list of part numbers and list price are included. </p>
            </div>
          </a>
        </div>
        <div className="col-xs-6 col-md-3 col-sm-3  padLM15 padRM5 desktop-display">
          <a className="recentSupport" href="/parker/OpenContent/MSG_HPS_Parker+HPS+Leadtime+Updates+2021_01_01?staticfile=6493f81b54fc6710VgnVCM100000e6651dacRCRD" target="_blank">
            <div className="support-assets supportData" data-rcrd="6493f81b54fc6710VgnVCM100000e6651dacRCRD" data-assettype="Static File" data-url="MSG_HPS_Parker+HPS+Leadtime+Updates+2021_01_01" style={{minHeight: '335px'}}>
              <img src="https://www.parker.com/parkerimages/Parker.com/Controlled Documents/Support/Support Category images/literature-documents1.gif" height={90} width={90} className="hidden-lg hidden-sm" alt="Support Image" title="Support Image" />
              <img src="https://www.parker.com/parkerimages/Parker.com/Controlled Documents/Support/Support Category images/literature-documents1.gif" height={115} width={115} className="hidden-xs" alt="Support Image" title="MSG HPS Parker HPS Leadtime Update 2021 01 01.xlsx" />
              <h3>MSG HPS Parker HPS Leadtime Update 2021 01 01.xlsx</h3>
              <p>MSG HPS Parker HPS Leadtime Update 2021 01 01.xlsx</p>
            </div>
          </a>
        </div>
        <div className="col-xs-6 col-md-3 col-sm-3 padR0 padLM15 padRM5 desktop-display">
          <a className="recentSupport" href="/parker/OpenContent/MSG-HPS+Product+Removed+from+Sale+REV122?staticfile=e5cc895e58627710VgnVCM100000e6651dacRCRD" target="_blank">
            <div className="support-assets supportData" data-rcrd="e5cc895e58627710VgnVCM100000e6651dacRCRD" data-assettype="Static File" data-url="MSG-HPS+Product+Removed+from+Sale+REV122" style={{minHeight: '335px'}}>
              <img src="https://www.parker.com/parkerimages/Parker.com/Controlled Documents/Support/Support Category images/literature-documents1.gif" height={90} width={90} className="hidden-lg hidden-sm" alt="Support Image" title="Support Image" />
              <img src="https://www.parker.com/parkerimages/Parker.com/Controlled Documents/Support/Support Category images/literature-documents1.gif" height={115} width={115} className="hidden-xs" alt="Support Image" title="MSG-HPS Product Removed from Sale REV122" />
              <h3>MSG-HPS Product Removed from Sale REV122</h3>
              <p>MSG-HPS Product Removed from Sale REV122</p>
            </div>
          </a>
        </div>
      </div>
        )
    }
}
