import React, { useEffect, useState } from 'react';

const Pagination = ({  resultInfo, pageOffset, currentPage, postsPerPage, totalPosts, paginate, resultperPage }) => {
  
  console.log("postss",totalPosts)
  const [currPage, setcurrPage] = useState(currentPage);
  let limit =sessionStorage.getItem("res_per_page");
  if(limit ==null){
    limit = postsPerPage;
  }else{
    limit = parseInt(limit);
  }
  //console.log("getlimit",limit)
   //const [itemsPerPage, setitemsPerPage] = useState(limit);
  
  
  // let itemsPerPage = limit;
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  // const [myResultPerPage,  setResultPerPage] = useState(itemsPerPage)

  const handleClick = (number) => {
   
    setcurrPage(Number(number));
    paginate(number);
  };

  const pages = [];

  
  for (let i = 1; i <= Math.ceil(totalPosts / [limit]); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currPage * limit;
  const indexOfFirstItem = indexOfLastItem - limit;
  //const currentItems = totalPosts.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number, i) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      //console.log("hello",number)

             return (
       
        <li
          key={i}
          id={number}
         
           className={currPage == number ? "ph-results-pagination-item current" : "ph-results-pagination-item"}
        >
          <a  id={number} onClick={() => handleClick(number)}>{number}</a>
          
        </li>
        
        
      )
     
    }
  });
  
  
  const handleNextbtn = (e) => {

    if(e.currentTarget.id == "pageinreamentbtn"){
      setcurrPage("...inc");
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }else{
      
      
      if (currPage + 1 > maxPageNumberLimit) {
          
        setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);

      }
      paginate(currPage + 1);
      setcurrPage(currPage + 1);

    }
      
  };

  const handlePrevbtn = (e) => {
    
   
    if(e.target.id == "pagedecreamentbtn"){
      setcurrPage("...dec");
      //if ((currPage - 1) % pageNumberLimit == 0) {
        setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      //}
    } else {
      
      //let currentPage = currPage - 1;
     
      
      if ((currPage - 1) % pageNumberLimit == 0) {
        setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
        setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
      }
      paginate(currPage - 1); 
      setcurrPage(currPage-1);
    }
    
         
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li className="ph-results-pagination-item"   onClick={(e) => handleNextbtn(e)} id="pageinreamentbtn"> &hellip; </li>;
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li className="ph-results-pagination-item" onClick={(e) => handlePrevbtn(e)} id="pagedecreamentbtn"> &hellip; </li>;
  }

  
  // function getResultPerPage(){
  //   //return pages.slice(minPageNumberLimit - 1, maxPageNumberLimit);
  //   const urlParams = new URLSearchParams(window.location.search);
  //   let 5resPerpage = urlParams.get("res_per_page");
  //   if(resPerpage == "10")
  //     return "selected";
  //   if(resPerpage == "20")
  //     return "selected";
  //   if(resPerpage == "40")
  //       return "selected";
  //   if(resPerpage == "60")
  //     return "selected";
  //   //setResultPerPage(resPerpage);
  // }
 

  return (
    <div>
              <div className="row">
                <div className='colo-lg-3'></div>
           <div className="col-md-12 col-lg-9 offset-xl-3 offset-lg-3 search-pagination-main">
    <div className="search-pagination col d-md-block">
    <ul className=" ph-results-pagination">
        <li className="">
          <a
            onClick={handlePrevbtn}
           
          >
           
            {currPage == pages[0] ? "" : "Prev"}
            
            {currPage == "...dec" && currPage == pages[0] && "Prev"}
            
          </a>
        </li>
        {pageDecrementBtn}
        {renderPageNumbers}
        {pageIncrementBtn}

        <li className="">
          <a
            onClick={(e) => handleNextbtn(e)}
            disabled={currPage == pages[pages.length - 1] ? true : false}
          >
            {currPage == pages[pages.length - 1] ? "" : "Next"}
            {currPage == "...inc" && currPage == pages[pages.length - 1] && "Next"}
            
          </a>
        </li>
      </ul>
  </div>
  <div className="pres-per-page col d-md-block">
              <div className="ph-results-per-page">
                 <span>Results per page:</span>
                 <select  value={limit} name="name-per-page" onChange={(e) => resultperPage(e.target.value)}>
                    <option key="10" value="10" >10</option>
                    <option key="20" value="20">20</option>
                    <option key="40" value="40">40</option>
                    <option key="60" value="60">60</option>
                 </select>
                
              </div>
           </div>
  </div>
  </div>
  </div>
  );
};

export default Pagination;