import React, { Component } from 'react'

export default class SearchHeaderComponent extends Component {
    render() {
        return (
            <div className="search-results-header ng-scope">
        <div className="search-header ng-scope">
          <div className="search-header-facet-breadcrumbs">
            <div className="container">
              {/* ngIf: suggestion != '' */}
              <div className="searchResultTag">
                <div className="context"><span className="context-header ng-binding">Searching:</span></div>{/* col-sm-3 */}
                <div className="reflection closed">
                  <div className="facet-wrapper-desktop">
                    {/* ngIf: !isSupportL1 */}
                    {/* ngIf: contentTypeCad */}
                    {/* ngIf: contentTypeConfig */}
                    {/* ngRepeat: section in getFacetsFilters.filter */}
                    <a href="javascript:void(0)" className="closeAll-btn ng-binding ng-hide">Clear All [X]</a>
                  </div>
                  <a className="facet-accordion-desktop" style={{display: 'none'}}>(<span className="plus-minus">+</span>)</a>
                </div>
              </div>{/* searchResultTag */}
              {/* col-sm-9 */}
            </div>{/* container */}
            {/* <div ng-controller="SidebarFacetsController"></div> */}
          </div>{/* search-header-facet-breadcrumbs */}
          <div className="mobile-search-edit-filters container no - filters">
            <a className="button edit-filters ng-binding" href>Edit Filters<i className="fa fa-caret-right" aria-hidden="true" /></a>
          </div>{/* mobile-search-edit-filters */}
          <div className="search-content-types">
            <div className="container">
              <div className="btn-group">
                {/* ngRepeat: tab in facet_tabs */}<label className="btn btn-primary ng-pristine ng-untouched ng-valid ng-scope active ng-not-empty" uib-btn-radio="'All Results'"><b className="ng-binding">All Results<small className="ng-binding">15,687</small></b></label>{/* end ngRepeat: tab in facet_tabs */}<label className="btn btn-primary ng-pristine ng-untouched ng-valid ng-scope ng-not-empty" uib-btn-radio="''"><b className="ng-binding"><small className="ng-binding" /></b></label>{/* end ngRepeat: tab in facet_tabs */}
              </div>{/* search-content-types */}
            </div>{/* container */}
          </div>{/* search-content-types */}
        </div>{/* search-header */}</div>
        )
    }
}
