import axios from 'axios'
import * as apis from '../common-utils/api_urls'
import * as translationUtils from '../common-utils/translation_utils'
import { getAPIAuthKey } from '../apiconfig';


/**
 * Get Token Data Service
 * @param {MainWrapper} callingClass 
 * @param {string} apiUrl 
 * @param {string} authToken 
 */
export default function getUserTokenList(callingClass,sessionId) {

    const headers = {
        "Ocp-Apim-Subscription-Key": getAPIAuthKey(),
        "Cache-Control": "no-cache",
        "Accept": 'application/json'
    };

    axios(apis.getUserTokenAPIURL('list'), {
        method: 'POST',
        headers: headers,
        data: {
            'sessionId': sessionId
        }
    }).then(
        function (result) {
            if (result.data) {
                result.data.sort(function(a, b) {
                    var c = new Date(a.TokenCreationDateTime);
                    var d = new Date(b.TokenCreationDateTime);
                    return d-c;
                });
                callingClass.setUserTokenList(result.data)
                callingClass.setTokenState(result.data.length)
                callingClass.props.getLoader(false);
            }
        }
    ).catch(
        function (error) {
            
        }
    )
}


export function createUserToken(callingClass,sessionId,tokenName) {
    const headers = {
        "Ocp-Apim-Subscription-Key": getAPIAuthKey(),
        "Cache-Control": "no-cache",
        "Accept": 'application/json'
    };

    axios(apis.getUserTokenAPIURL('create'), {
        method: 'POST',
        headers: headers,
        data: {
            'sessionId': sessionId,
            'tokenName':tokenName
        }
    }).then(
        function (result) {
            getUserTokenList(callingClass,sessionId)
            //callingClass.props.getLoader(false);
            callingClass.props.setStatusMessage('Success','Token created successfully.')
        }
    ).catch(
        function (error) {
            
        }
    )
}

export function refreshUserToken(callingClass,sessionId,token) {
    const headers = {
        "Ocp-Apim-Subscription-Key": getAPIAuthKey(),
        "Cache-Control": "no-cache",
        "Accept": 'application/json'
    };

    axios(apis.getUserTokenAPIURL('refresh'), {
        method: 'POST',
        headers: headers,
        data: {
            'sessionId': sessionId,
            'token':token
        }
    }).then(
        function (result) {
            getUserTokenList(callingClass,sessionId)
            //callingClass.props.getLoader(false);
        }
    ).catch(
        function (error) {
            
        }
    )
}

export function deactivateUserToken(callingClass,sessionId,token) {
    const headers = {
        "Ocp-Apim-Subscription-Key": getAPIAuthKey(),
        "Cache-Control": "no-cache",
        "Accept": 'application/json'
    };

    axios(apis.getUserTokenAPIURL('deactivate'), {
        method: 'POST',
        headers: headers,
        data: {
            'sessionId': sessionId,
            'token':token
        }
    }).then(
        function (result) {
            getUserTokenList(callingClass,sessionId)
            //callingClass.props.getLoader(false);
        }
    ).catch(
        function (error) {
            
        }
    )
}
