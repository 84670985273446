import React, { Component } from 'react';
import * as translationUtils from '../../../common-utils/translation_utils';

class CreateToken extends Component {
  render() {
    return (
      <div>
        <div
          className="ph-modal fade"
          id="createTokenModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="Create Token Modal"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="ph-modal__content">
              <div className="d-flex justify-content-end m-0 p-0">
                <span
                  data-dismiss="modal"
                  aria-label="Close"
                  className="ph-modal__header--close ph-modal-header-close-font-size"
                  aria-hidden="true"
                >
                  <i className="fas fa-times"></i>
                </span>
              </div>
              <div className="ph-modal__header">
                <h2 className="ph-modal__title" id="importuserModalLabel">
                  {`${translationUtils.getTranslation(
                    1350,
                    'Create'
                  )} ${translationUtils.getTranslation(3342, 'Token')}`}
                </h2>
              </div>
              <div className="ph-modal__body">
                <div className="ph-form-import-row">
                  <label className="ph-form-row__label" htmlFor="quantity">
                    {'What do you want your token to be named?'}
                  </label>
                  <input
                    name="token-name"
                    className="ph-form-row__input"
                    type="text"
                    placeholder="Enter a name for this token..."
                    onChange={this.props.handleTokenName}
                  />
                  {this.props.tokenNameError ? (
                    <p style={{ color: 'red' }}>
                      Please enter a name for this token
                    </p>
                  ) : (
                    ''
                  )}
                </div>
                <div>{'The token name is used for tracking API calls'}</div>
              </div>
              <div className="ph-modal__footer">
                <button
                  type="button"
                  className="button accent-button round-button medium-button small-button"
                  data-dismiss="modal"
                >
                  {translationUtils.getTranslation(676, 'Close')}
                </button>
                {this.props.tokenName ? (
                  <button
                    type="button"
                    className="button primary-button round-button medium-button ml-1"
                    data-dismiss="modal"
                    onClick={this.props.handleCreateToken}
                  >
                    {`${translationUtils.getTranslation(
                      1350,
                      'Create'
                    )} ${translationUtils.getTranslation(3342, 'Token')}`}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button primary-button round-button medium-button ml-1"
                    onClick={this.props.handleCreateToken}
                  >
                    {`${translationUtils.getTranslation(
                      1350,
                      'Create'
                    )} ${translationUtils.getTranslation(3342, 'Token')}`}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateToken;
