import React, {useState, useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Col from '../../../../node_modules/react-bootstrap/esm/Col';
// import { Nav, NavDropdown} from '../../../../node_modules/react-bootstrap/esm/index';
// import NavItem from '../../../../node_modules/react-bootstrap/esm/NavItem';
// import Row from '../../../../node_modules/react-bootstrap/esm/Row';
// import MenuItem from '@mui/material/MenuItem';
import Col  from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Nav, NavDropdown }  from 'react-bootstrap';
import NavItem from 'react-bootstrap';
import Results from './Results';
import Pagination from './Pagination';
import { post } from 'jquery';
import { Redirect } from 'react-router-dom';

export default function ResultTypeTabs (){

  const [activeKey, setActiveKey] = useState('support');
  // const [key, setKey] = useState('home');

  const [results, setResults] = useState([]);
  const [numCount, setNumCount] = useState(null);
  const [category, setCategory] = useState('support');

   const [authId, setAuthId] = useState(`1-9R05F|1-9R05G|1-9R05J|1-9R05L|1-9R06A`);

   const [sortByValue, setSortByValue] = useState(['']);
  // const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
   const [postsPerPage, setPostPerPage] = useState(20);
   const [offSet, setOffSet] = useState(0);
   const urlParams = new URLSearchParams(window.location.search);
   const [allFacets, setAllFacets] = useState([]);
   const [allFacetsAPIData, setAllFacetsAPIData] = useState([]);
   let pid;
   let fq_tag;
   const cid = urlParams.getAll('cid');
  const [custmerId, setCustomerId] = useState('000914');

   const [prevCheckedItem, setPrevCheckedItem] = useState([]);
   let response1;

   let authId1;
   let custId1;

   let limit = urlParams.getAll('limit');
   pid = urlParams.getAll('siteSectionEN_fq');
   let totalItems = getCounts(`p-190113873$$$Support`)
   if(pid == 'p-190113873'){
    totalItems = getCounts(`p-190113873$$$Support`)
   } 
   if(pid == 'p-939117180'){
    totalItems = getCounts(`p-939117180$$$Products`)
   } 
   if(pid == 'p-1526365397'){
    totalItems = getCounts(`p-1526365397$$$Cross Reference`);
    console.log("totalitems",totalItems)
   }

   const totalPages = Math.ceil(totalItems/postsPerPage);
   let catField = 'support';
    // Change page
    const paginate = (pageNumber) => {

      //let pageNumber = Number(event.target.id)
     
       setCurrentPage(pageNumber);
 
       const searchbox = urlParams.getAll('searchbox');
             
       pid = urlParams.getAll('siteSectionEN_fq');
       catField = urlParams.getAll('selectCategory');
       let limit = urlParams.getAll('limit');
       if(limit.length == 0){
          limit = postsPerPage;
       } else {
          limit = Number(limit);
       }
      let pageOffset =  (pageNumber - 1) * limit;
      //setOffSet(pageOffset);
      if(catField.length > 0){
        fq_tag = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;
       fetchData(limit, pageOffset, fq_tag, catField, searchbox);
      }else{
        fq_tag = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:p-190113873';
       fetchData(limit, pageOffset, fq_tag, "support", searchbox);
      }
  
    }

     const resultPerPage = (param) =>{
      
    // alert(param)
      setPostPerPage(param);
      
      sessionStorage.setItem("res_per_page", param);
         //const limit = urlParams.getAll('limit');
    urlParams.set("limit",param);
    
    var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
  //window.history.replaceState(null, null, `${window.location.origin}?${queryParams.toString()}`);
    window.history.pushState({ path: refresh }, '', refresh);

  // return <Redirect to={window.location.pathname} />
      window.location.reload();
      //setOffSet(0);
      //fetchData(postsPerPage, offSet);
     }

    const changeSortBy = (sortbyvalue) => {
      setSortByValue(sortbyvalue)
    }

    const getAuthId = async () => {
      try {

      
        response1 = await fetch("https://apidev.parker.com/test/SolrApi/DistributorService/auth?ContractId=" + cid, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'ocp-apim-subscription-key': '9a7b6552198d41fb9b000548826f59fb'  
                 }
        }
      )
      .then((response) => response.json())

      return response1;
    
      } catch (error) {
        
        console.error("Error fetching data:", error);
        setAuthId(`1-9R05F|1-9R05G|1-9R05J|1-9R05L|1-9R06A`);
        setCustomerId(`000914`);
      } 
    }

    const getIncludesCountry = async (custId) => {

      try {

      
        let response2 = await fetch("https://apidev.parker.com/test/SolrApi/myparker/getCustomerCountry?customerId=" + custId, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'ocp-apim-subscription-key': '9a7b6552198d41fb9b000548826f59fb'  
                 }
        }

    

      )
      .then((response) => response.json())

      return response2;
    
      } catch (error) {
        
        console.error("Error fetching data:", error);
        
      } 


    }



 
  const removeCall = (fq_tag, tab) =>{
    
    if(prevCheckedItem?.length > 0){
      if(tab !== "all" && prevCheckedItem.includes(fq_tag)){
        const ind = prevCheckedItem.indexOf(fq_tag)
        if(ind !== -1){
          const a = prevCheckedItem
          a.splice(ind, 1)
          console.info("aaa",a)
          return {values: a, isDeleted: true}
        }else {
          return {values: prevCheckedItem, isDeleted: false}
        }
      } else {
        return {values: [], isDeleted: false}
      }
    } else {
      // let pid = ""
      // let fq_tag2 = ''
      // if(tab == "products"){
      //     pid = "p-939117180"
      //     fq_tag2 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;
          
      // }else if(tab == "support"){
      //     pid = "p-190113873"
      //     fq_tag2 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;
          
      // }else{
      //     pid = "p-1526365397"
      //     fq_tag2 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;
          
      // }
      // setPrevCheckedItem([...prevCheckedItem, fq_tag2])
      return {values: [],isDeleted: false}
    }
  }

const fetchData = async (limit, offSet, fq_tag, category, searchbox, isChecked = "", dynamicPID) => {
  console.info(limit, offSet, fq_tag, category, searchbox, isChecked, dynamicPID, "fffffff")

 let authid;
  let custid;
  let includeCountryId;
 
    let getnewauthresp = await getAuthId();
    authid = getnewauthresp !== undefined && getnewauthresp.length > 0 && getnewauthresp[0].AuthId != undefined ? getnewauthresp[0].AuthId : '';
    custid = getnewauthresp !== undefined && getnewauthresp.length > 0 ? getnewauthresp[0].CustomerId : custmerId;
    let getinccountry = await getIncludesCountry(custid);
    includeCountryId = getinccountry.countryCode.length > 0 && getinccountry.countryCode[0] != undefined ? getinccountry.countryCode[0] : 'NLD';
  const remCall = removeCall(fq_tag, category)
    
  
  const baseUrl = 'https://apidev.parker.com/test/fusionsearch/apollo/collections/ParkerDotComMain/query-profiles/myparkerdotcom-en/select?';
  const params = new URLSearchParams({
    _cookie: false,
    authID: authid,
    country: '687PDC',
    customerID: custid,
    echoParams: 'all',
    facetMode: 'new',
    fq: 'countryId_ss:687PDC',

    fq: fq_tag,
    includeCountries: includeCountryId,
 
    echoParams: 'all',
    'json.nl': 'arrarr',
    myresults: 0,
    q: searchbox,
    rows: limit,
    sessionid: 'N/A',
    sort: sortByValue,
    start: offSet,
    tab: category,
    wt: 'json'
  });

  // console.info("removeCall(fq_tag).isDeleted",removeCall(fq_tag).isDeleted, prevCheckedItem.length)
  console.info(remCall,"remCall")
  if(isChecked && prevCheckedItem?.length > 0){
    // console.info("isDeleted !== true", checkItem, isDeleted)
    remCall.values.map((i) => params.append("fq", i))
    remCall.isDeleted !== true && params.append("fq",fq_tag)
  } else {
    params.append("fq", fq_tag)
  }

  const url = `${baseUrl}?${params.toString()}`;
 
  try {
    const response = await fetch(url, {
      method: 'GET',      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': '9a7b6552198d41fb9b000548826f59fb'
      }
    }
      );
    const jsonData = await response.json();
    console.log("jsondata",jsonData.response.docs)
    if(jsonData.response.docs.length  > 0){
      
      setResults(jsonData.response.docs);
      setAllFacetsAPIData(jsonData.facet_counts.facet_fields)
      if(fq_tag === ""){
        setPrevCheckedItem([])
      } else {
        if(true){
          const index = prevCheckedItem.indexOf(fq_tag)
          if(index !== -1){
            setPrevCheckedItem((e) => {
             e.splice(index, 1)
             return e
            })
          } else if(remCall.isDeleted !== true && index === -1){
              console.log('p-939117180AAA', prevCheckedItem)
              if(catField !== "all"){

                setPrevCheckedItem([...prevCheckedItem, fq_tag])
              }
            // }
            
            
          }
        }
      }
    }else{
      
      if(category != "all"){
        setResults([]);
      }
      if( category == "xref"){
        setAllFacets(jsonData.facet_counts.facet_fields.xrefType_facet
          )
      }else{
        setAllFacets(jsonData.facet_counts.facet_fields.siteSectionEN_facet)
      }

    }

    // if(jsonData.response.docs.length == 0){
    //   setAllFacets(jsonData.facet_counts.facet_fields.siteSectionEN_facet)
    // }
   
    // console.log("resultsMy",jsonData.facet_counts.facet_fields.siteSectionEN_facet)
    // console.log("allfacets",results);
    //if(jsonData.response.docs.length == 0){
      
      //setNumCount(0)
      //setResults([]);

    //} else{
      setNumCount(jsonData.response.numFound)
    //}
    // setPosts(jsonData.response.docs);
    //   setLoading(false);
     console.log("posts",numCount)
    // console.log("results",jsonData)
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};


  useEffect(()=>{
     
    sessionStorage.setItem("distributorId", custmerId);
    
    var limit =urlParams.getAll('limit'); 
    if(limit.length == 0){
      sessionStorage.setItem('res_per_page', postsPerPage);
    } 
   
    var catField = 'selectCategory';
  
    const searchbox = urlParams.getAll('searchbox');
    pid = urlParams.getAll('siteSectionEN_fq');
    catField = urlParams.getAll('selectCategory');

    if (!urlParams.has('selectCategory') ||  urlParams.getAll('selectCategory') == "support") {

      // urlParams.set("siteSectionEN_fq",pid);
      // urlParams.set("selectCategory","support");
      limit = sessionStorage.getItem("res_per_page");
      if(limit == null){
     
        limit = postsPerPage;
        urlParams.set("limit", limit);
        var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
        //window.history.replaceState(null, null, `${window.location.origin}?${queryParams.toString()}`);
        window.history.pushState({ path: refresh }, '', refresh);
      }else{

       limit = limit;
        
      }
      
      
      let fq_tag1 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:*';
      fetchData(limit, offSet, fq_tag1, 'all', searchbox);
      
         pid = "p-190113873";
       
      //   catField = "support";
        let fq_tag2 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;
        
        fetchData(limit, offSet, fq_tag2, "support", searchbox);
        setActiveKey("support")
        
    } 
    else {

      limit = sessionStorage.getItem("res_per_page");
      let fq_tag1 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:*';
      fetchData(limit, offSet, fq_tag1, 'all', searchbox);
      //  pid = urlParams.getAll('siteSectionEN_fq');
      //  catField = urlParams.getAll('selectCategory');
      fq_tag = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;
      fetchData(limit, offSet, fq_tag, catField, searchbox);
      setActiveKey(catField);
    }

  }, [postsPerPage, offSet, sortByValue]);

  const clearFilter = () => {
    setPrevCheckedItem([])
  }

 async function handleSearch(k) {
   
  let limit = sessionStorage.getItem("res_per_page");
  if(limit == null){
    
     limit = postsPerPage;
  } else{
    limit = limit;
  }
  
  if(k == "support"){

    urlParams.set("selectCategory",k);
     pid = "p-190113873";
     urlParams.set("siteSectionEN_fq",pid);
    //  if(results.length == 0){
    //   setResults([])
    //  }
  }
  if(k == "products"){
    urlParams.set("selectCategory",k);
     pid = "p-939117180";
     urlParams.set("siteSectionEN_fq",pid);
    //  if(results.length == 0){
    //   setResults([])
    //  }
  }

  if(k == "xref"){
    urlParams.set("selectCategory",k);
    pid = "p-1526365397";
    urlParams.set("siteSectionEN_fq",pid);
    // if(results.length == 0){
    //   setResults([])
    //  }
 }
 let searchbox = urlParams.getAll('searchbox');
 fq_tag = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;

 setActiveKey(k);
  // window.location.href = `${window.location.origin}?${queryParams.toString()}`;
  var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
  //window.history.replaceState(null, null, `${window.location.origin}?${queryParams.toString()}`);
  window.history.pushState({ path: refresh }, '', refresh);

  console.log('qwqq',fq_tag);
 await fetchData(limit, offSet, fq_tag, k, searchbox, false);
    }

console.info("Precheck", prevCheckedItem)
    function getCounts(facet){

      // allFacets.map((item, index) => {
      //   let num;
      //   if(item[0] === facet){
         
      //      num = item[1];
      //      return num;
      //   }
      // })
     
      // console.log("countsm",counts)
      // return counts;
      let counts = 0;
      if(allFacets)
      counts = allFacets.filter(item => item[0] == facet).map((item, i) => {
        if(item[1] > 0){
          return item[1];
        } else {
          return "0";

        }
      
      })
      return counts;
     
    }

    return(
      <>

<div className="ph-search-wrapper">
  <div className="d-none d-sm-block container-fluid">

    <Tabs activeKey={activeKey} onSelect={(k) => handleSearch(k)} className="mb-5 justify-content-center ph-search-nav-tabs myparkersearch-tabs">
    
      <Tab eventKey="support" title={`Support (${getCounts(`p-190113873$$$Support`) > 0 ? getCounts(`p-190113873$$$Support`) : '0'})`} disabled={getCounts(`p-190113873$$$Support`) > 0 ? false : true}>

        <Results tabResult="support" resultInfo={results} sortBy={changeSortBy} onfetchData = {fetchData} allFacetsData = {allFacetsAPIData} prevCheckedItem = {prevCheckedItem} clearFilter = {clearFilter}/>
        {results.length > 0 && (
 <div>
 <Pagination resultInfo={results} pageOffSet={offSet} currentPage={currentPage} totalPosts={getCounts(`p-190113873$$$Support`)} postsPerPage={postsPerPage} paginate={paginate} resultperPage={resultPerPage}/>
 </div>
        )}

      </Tab>
      <Tab eventKey="products" title={`Products (${getCounts(`p-939117180$$$Products`) > 0 ? getCounts(`p-939117180$$$Products`) : '0'})`} disabled={getCounts(`p-939117180$$$Products`) > 0 ? false : true}>
        {/* <Results tabResult="products" posts={currentPosts} loading={loading}/> */}
        <Results tabResult="products" resultInfo={results} 
        sortBy={changeSortBy} 
        onfetchData = {fetchData} 
        allFacetsData = {allFacetsAPIData} 
        prevCheckedItem = {prevCheckedItem}
        clearFilter = {clearFilter}
       />
        {results.length > 0 && (
          <div>
        <Pagination resultInfo={results} currentPage={currentPage} totalPosts={getCounts(`p-939117180$$$Products`)} postsPerPage={postsPerPage} paginate={paginate} resultperPage={resultPerPage}/>
        </div>
        )}
      </Tab>
      <Tab eventKey="xref" title={`Cross Reference (${getCounts(`p-1526365397$$$Cross Reference`) > 0 ? getCounts(`p-1526365397$$$Cross Reference`) : '0'})`} disabled={getCounts(`p-1526365397$$$Cross Reference`) > 0 ? false : true}>
      <Results tabResult="xref" resultInfo={results} 
        sortBy={changeSortBy} 
        onfetchData = {fetchData} 
        allFacetsData = {allFacetsAPIData} 
        prevCheckedItem = {prevCheckedItem}
        clearFilter = {clearFilter}/>
        {/* <Results tabResult="xref" posts={currentPosts} loading={loading}/> */}
        {results.length > 0 && (
 <div>
 <Pagination resultInfo={results} currentPage={currentPage} totalPosts={getCounts(`p-1526365397$$$Cross Reference`)} postsPerPage={postsPerPage} paginate={paginate} resultperPage={resultPerPage}/>
 </div>        )}
      </Tab>
    </Tabs>
   
    </div>
    <div className="tabbable hide-smallscreen">
   
    <div className="row">
      
    <div className="mob-tab col-sx-12">
    <select className="mb10 form-control visible-xs mobile-tabs" value={activeKey} onChange={(e) => setActiveKey(e.target.value)}>
    <option value="support" disabled={getCounts(`p-190113873$$$Support`) > 0 ? false : true}>{`Support (${getCounts(`p-190113873$$$Support`) > 0 ? getCounts(`p-190113873$$$Support`) : '0'})`}</option>
        <option value="products" disabled={getCounts(`p-939117180$$$Products`) > 0 ? false : true}>{`Products (${getCounts(`p-939117180$$$Products`) > 0 ? getCounts(`p-939117180$$$Products`) : '0'})`}</option>
        <option value="xref" disabled={getCounts(`p-1526365397$$$Cross Reference`) > 0 ? false : true}>{`Cross Reference (${getCounts(`p-1526365397$$$Cross Reference`) > 0 ? getCounts(`p-1526365397$$$Cross Reference`) : '0'})`}</option>
        
    </select>
    
        {activeKey === 'support' && (
          <div>
            <Results tabResult="support" resultInfo={results} sortBy={changeSortBy} onfetchData = {fetchData} allFacetsData = {allFacetsAPIData} prevCheckedItem = {prevCheckedItem}
        clearFilter = {clearFilter}/>
            {results.length > 0 && (
          <div>
       <Pagination resultInfo={results} pageOffSet={offSet} currentPage={currentPage} totalPosts={getCounts(`p-190113873$$$Support`)} postsPerPage={postsPerPage} paginate={paginate} resultperPage={resultPerPage}/>
        </div>
        )}
          </div>
        )}
        {activeKey === 'products' && (
          <div>
           <Results tabResult="products" resultInfo={results} sortBy={changeSortBy} onfetchData = {fetchData} allFacetsData = {allFacetsAPIData} prevCheckedItem = {prevCheckedItem}
        clearFilter = {clearFilter}/>
           {results.length > 0 && (
          <div>
         <Pagination resultInfo={results} currentPage={currentPage} totalPosts={getCounts(`p-939117180$$$Products`)} postsPerPage={postsPerPage} paginate={paginate} resultperPage={resultPerPage}/>
        </div>
        )}
          </div>
        )}
        {activeKey === 'xref' && (
          <div>
            <Results tabResult="xref" resultInfo={results} sortBy={changeSortBy} onfetchData = {fetchData} allFacetsData = {allFacetsAPIData} prevCheckedItem = {prevCheckedItem}
        clearFilter = {clearFilter}/>
            {results.length > 0 && (
          <div>
        <Pagination resultInfo={results} currentPage={currentPage} totalPosts={getCounts(`p-1526365397$$$Cross Reference`)} postsPerPage={postsPerPage} paginate={paginate} resultperPage={resultPerPage}/>
        </div>
        )}
          </div>
        )}

     </div>
    </div>
  
    </div>
    
</div>


 

        </>
    )
}