import React, { Component } from 'react'

export default class HomeBannerComponent extends Component {
    render() {
        return (
            <div id="mainDiv" style={{width: '100%'}}>
             <link href={process.env.PUBLIC_URL+"/assets/css/jquery.bxslider.css"} rel="stylesheet" type="text/css" />  

<div className="bx-wrapper" style={{maxWidth: '1349px', border: 'none', marginBottom: '0px'}}><div className="bx-viewport" aria-live="polite" style={{width: '100%', overflow: 'hidden', position: 'relative', height: '521px'}}><div className="mainSlider" style={{width: '4215%', position: 'relative', transitionDuration: '0s', transform: 'translate3d(-1349px, 0px, 0px)'}}><div className="slide bx-clone" style={{float: 'left', listStyle: 'none', position: 'relative', width: '1349px'}} aria-hidden="true">
        <img data-url="https://phstage.parker.com/myparker/en/products?cid=4000000000000025004" data-newwindow="true" alt="Hero Image " title="Building a better world" src="/parkerimages/MyParker/Home/my parker image/1366 x 526 1.jpg" className="mainSlider-image callToActionLink" style={{cursor: 'pointer', width: '100%'}} />
        <div className="slider-heading-div hidden-lg hidden-xs ipad-hidden">
          <p className="slider-heading">Building a better world</p>
          <p className="slider-text">By integrating an electronically controlled variable speed motor with a hydraulic pump, Parker’s Drive Controlled Pump (DCP) technology can increase productivity and reduce energy use in many applications.</p>
        </div>
        <div className="slider-heading-div ">
          <p className="slider-heading">Building a better world</p>
          <p className="slider-text hidden-xs">By integrating an electronically controlled variable speed motor with a hydraulic pump, Parker’s Drive Controlled Pump (DCP) technology can increase productivity and reduce energy use in many applications.</p>
        </div>
        <div className="welcome-div">
          <div className="welcome-slider-text"><span>Welcome</span> My Parker for ACME</div>
          <div className="welcome-message-div">
            <div className="welcome-message margin-bottom20">
              <p className="welcome-salutation">Hi James</p>
              <p>Here you will find a specialized selection of parker Technologies, educational resources,  and information created specifically to support ACME employees like you.</p>
            </div>
          </div>
        </div>
        <div className="acme-utilities-slider hidden-xs hidden-lg ipad-hidden">
          <div className="row acme-utilities">
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/1 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/3 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/2 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/4 (2).png" alt="image" title="image" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slide" style={{float: 'left', listStyle: 'none', position: 'relative', width: '1349px'}} aria-hidden="false">
        <img data-url="http://phstage.parker.com/myparker/en/products?cid=4000000000000025004" data-newwindow="true" alt="Hero Image" title="Driving energy efficiency" src="https://www.parker.com/parkerimages/MyParker/hero-image2.jpg" className="mainSlider-image callToActionLink" style={{cursor: 'pointer', width: '100%'}} />
        <div className="slider-heading-div hidden-lg hidden-xs ipad-hidden">
          <p className="slider-heading">Driving energy efficiency</p>
          <p className="slider-text">By integrating an electronically controlled variable speed motor with a hydraulic pump, Parker’s Drive Controlled Pump (DCP) technology can increase productivity and reduce energy use in many applications.</p>
        </div>
        <div className="slider-heading-div ">
          <p className="slider-heading">Driving energy efficiency</p>
          <p className="slider-text hidden-xs">By integrating an electronically controlled variable speed motor with a hydraulic pump, Parker’s Drive Controlled Pump (DCP) technology can increase productivity and reduce energy use in many applications.</p>
        </div>
        <div className="welcome-div">
          <div className="welcome-slider-text"><span>Welcome</span> My Parker for ACME</div>
          <div className="welcome-message-div">
            <div className="welcome-message margin-bottom20">
              <p className="welcome-salutation">Hi James</p>
              <p>Here you will find a specialized selection of parker Technologies, educational resources,  and information created specifically to support ACME employees like you.</p>
            </div>
          </div>
        </div>
        <div className="acme-utilities-slider hidden-xs hidden-lg ipad-hidden">
          <div className="row acme-utilities">
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="https://www.parker.com/parker/myparker/assets/images/1 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="https://www.parker.com/parker/myparker/assets/images/3 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="https://www.parker.com/parker/myparker/assets/images/2 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="https://www.parker.com/parker/myparker/assets/images/4 (2).png" alt="image" title="image" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slide" aria-hidden="true" style={{float: 'left', listStyle: 'none', position: 'relative', width: '1349px'}}>
        <img data-url="http://88539942ad8bf510VgnVCM10000025651dacRCRD" data-newwindow="false" alt="Hero Image" title="Partnering for safer, more eco-friendly air travel" src="/parkerimages/MyParker/hero-image2.jpg" className="mainSlider-image callToActionLink" style={{cursor: 'pointer', width: '100%'}} />
        <div className="slider-heading-div hidden-lg hidden-xs ipad-hidden">
          <p className="slider-heading">Partnering for safer, more eco-friendly air travel</p>
          <p className="slider-text">From the engine and chassis to the cab and implements, Parker’s heavy-duty vehicle technologies help equipment run better and more efficiently, while reducing operator fatigue and boosting productivity.</p>
        </div>
        <div className="slider-heading-div ">
          <p className="slider-heading">Partnering for safer, more eco-friendly air travel</p>
          <p className="slider-text hidden-xs">From the engine and chassis to the cab and implements, Parker’s heavy-duty vehicle technologies help equipment run better and more efficiently, while reducing operator fatigue and boosting productivity.</p>
        </div>
        <div className="welcome-div">
          <div className="welcome-slider-text"><span>Welcome</span> My Parker for ACME</div>
          <div className="welcome-message-div">
            <div className="welcome-message margin-bottom20">
              <p className="welcome-salutation">Hi James</p>
              <p>Here you will find a specialized selection of parker Technologies, educational resources,  and information created specifically to support ACME employees like you.</p>
            </div>
          </div>
        </div>
        <div className="acme-utilities-slider hidden-xs hidden-lg ipad-hidden">
          <div className="row acme-utilities">
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="https://www.parker.com/parker/myparker/assets/images/1 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="https://www.parker.com/parker/myparker/assets/images/3 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="https://www.parker.com/parker/myparker/assets/images/2 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="https://www.parker.com/parker/myparker/assets/images/4 (2).png" alt="image" title="image" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slide" aria-hidden="true" style={{float: 'left', listStyle: 'none', position: 'relative', width: '1349px'}}>
        <img data-url="http://88539942ad8bf510VgnVCM10000025651dacRCRD" data-newwindow="false" alt="Hero Images" title="Engineering Your Success" src="/parkerimages/MyParker/hero-image1.jpg" className="mainSlider-image callToActionLink" style={{cursor: 'pointer', width: '100%'}} />
        <div className="slider-heading-div hidden-lg hidden-xs ipad-hidden">
          <p className="slider-heading">Engineering Your Success</p>
          <p className="slider-text">For decades Parker has partnered with world-class aerospace manufacturers, offering a comprehensive range of components that can be found on virtually everything that flies.
            Leverage Parker's aerospace industry experience.</p>
        </div>
        <div className="slider-heading-div ">
          <p className="slider-heading">Engineering Your Success</p>
          <p className="slider-text hidden-xs">For decades Parker has partnered with world-class aerospace manufacturers, offering a comprehensive range of components that can be found on virtually everything that flies.
            Leverage Parker's aerospace industry experience.</p>
        </div>
        <div className="welcome-div">
          <div className="welcome-slider-text"><span>Welcome</span> My Parker for ACME</div>
          <div className="welcome-message-div">
            <div className="welcome-message margin-bottom20">
              <p className="welcome-salutation">Hi James</p>
              <p>Here you will find a specialized selection of parker Technologies, educational resources,  and information created specifically to support ACME employees like you.</p>
            </div>
          </div>
        </div>
        <div className="acme-utilities-slider hidden-xs hidden-lg ipad-hidden">
          <div className="row acme-utilities">
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/1 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/3 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/2 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/4 (2).png" alt="image" title="image" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slide" style={{float: 'left', listStyle: 'none', position: 'relative', width: '1349px'}} aria-hidden="true">
        <img data-url="https://phstage.parker.com/myparker/en/products?cid=4000000000000025004" data-newwindow="true" alt="Hero Image " title="Building a better world" src="/parkerimages/MyParker/Home/my parker image/1366 x 526 1.jpg" className="mainSlider-image callToActionLink" style={{cursor: 'pointer', width: '100%'}} />
        <div className="slider-heading-div hidden-lg hidden-xs ipad-hidden">
          <p className="slider-heading">Building a better world</p>
          <p className="slider-text">By integrating an electronically controlled variable speed motor with a hydraulic pump, Parker’s Drive Controlled Pump (DCP) technology can increase productivity and reduce energy use in many applications.</p>
        </div>
        <div className="slider-heading-div ">
          <p className="slider-heading">Building a better world</p>
          <p className="slider-text hidden-xs">By integrating an electronically controlled variable speed motor with a hydraulic pump, Parker’s Drive Controlled Pump (DCP) technology can increase productivity and reduce energy use in many applications.</p>
        </div>
        <div className="welcome-div">
          <div className="welcome-slider-text"><span>Welcome</span> My Parker for ACME</div>
          <div className="welcome-message-div">
            <div className="welcome-message margin-bottom20">
              <p className="welcome-salutation">Hi James</p>
              <p>Here you will find a specialized selection of parker Technologies, educational resources,  and information created specifically to support ACME employees like you.</p>
            </div>
          </div>
        </div>
        <div className="acme-utilities-slider hidden-xs hidden-lg ipad-hidden">
          <div className="row acme-utilities">
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/1 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/3 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/2 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/4 (2).png" alt="image" title="image" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="slide bx-clone" style={{float: 'left', listStyle: 'none', position: 'relative', width: '1349px'}} aria-hidden="true">
        <img data-url="http://phstage.parker.com/myparker/en/products?cid=4000000000000025004" data-newwindow="true" alt="Hero Image" title="Driving energy efficiency" src="/parkerimages/MyParker/hero-image2.jpg" className="mainSlider-image callToActionLink" style={{cursor: 'pointer', width: '100%'}} />
        <div className="slider-heading-div hidden-lg hidden-xs ipad-hidden">
          <p className="slider-heading">Driving energy efficiency</p>
          <p className="slider-text">By integrating an electronically controlled variable speed motor with a hydraulic pump, Parker’s Drive Controlled Pump (DCP) technology can increase productivity and reduce energy use in many applications.</p>
        </div>
        <div className="slider-heading-div ">
          <p className="slider-heading">Driving energy efficiency</p>
          <p className="slider-text hidden-xs">By integrating an electronically controlled variable speed motor with a hydraulic pump, Parker’s Drive Controlled Pump (DCP) technology can increase productivity and reduce energy use in many applications.</p>
        </div>
        <div className="welcome-div">
          <div className="welcome-slider-text"><span>Welcome</span> My Parker for ACME</div>
          <div className="welcome-message-div">
            <div className="welcome-message margin-bottom20">
              <p className="welcome-salutation">Hi James</p>
              <p>Here you will find a specialized selection of parker Technologies, educational resources,  and information created specifically to support ACME employees like you.</p>
            </div>
          </div>
        </div>
        <div className="acme-utilities-slider hidden-xs hidden-lg ipad-hidden">
          <div className="row acme-utilities">
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/1 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/3 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/2 (2).png" alt="images214" title="images214" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-3 col-lg-3 col-xs-2 acme-utilities-div">
              <a className="acme-utilities-image"><img id="utilities-image" src="/parker/myparker/assets/images/4 (2).png" alt="image" title="image" style={{width: '100%', height: '50px'}} /></a>
              <div className="acme-utilities-text">
                <a className="utilities-number">125</a>
                <p className="utilities-text">Loremips Ipsom</p>
              </div>
            </div>
          </div>
        </div>
      </div></div></div><div className="bx-controls bx-has-pager"><div className="bx-pager bx-default-pager"><div className="bx-pager-item"><a href="#" data-slide-index={0} className="bx-pager-link active">1</a></div><div className="bx-pager-item"><a href="#" data-slide-index={1} className="bx-pager-link">2</a></div><div className="bx-pager-item"><a href="#" data-slide-index={2} className="bx-pager-link">3</a></div><div className="bx-pager-item"><a href="#" data-slide-index={3} className="bx-pager-link">4</a></div></div></div></div>
  
            </div>
        )
    }
}
