import React, { Component } from 'react';
import moment from 'moment';
import * as translationUtils from '../../../common-utils/translation_utils';

class TokenListItem extends Component {
  $ = window.jQuery;

  constructor(props) {
    super(props);
  }
  render() {
    return this.props.tokenItem.TokenActiveFlag ? (
      <>
        <tr
          className={
            'ph-flex-table__row row-first-part d-flex flex-column flex-sm-row justify-content-around'
          }
        >
          <td>
            <b>{this.props.tokenItem.TokenName}</b>
          </td>
          <td>
            {moment(this.props.tokenItem.TokenCreationDateTime).format(
              'MMM DD, YYYY hh:mm A'
            )}
          </td>
          <td>{'-'}</td>
          <td>
            {this.props.tokenItem.TokenModifiedDateTime
              ? moment(this.props.tokenItem.TokenModifiedDateTime).format(
                  'MMM DD, YYYY hh:mm A'
                )
              : '-'}
          </td>
          <td rowSpan="2">
            <i
              className="fa fa3 fa-redo fa-rotate-270"
              data-toggle="modal"
              data-target="#confirmationModal"
              title={`${translationUtils.getTranslation(
                3350,
                'Refresh'
              )} ${translationUtils.getTranslation(3342, 'Token')}`}
              onClick={() => {
                this.props.handleTokenAction(this.props.tokenItem, 'refresh');
              }}
            />
            <i
              className="fa fa3 fa-trash"
              data-toggle="modal"
              data-target="#confirmationModal"
              title={`${translationUtils.getTranslation(
                264,
                'Deactivate'
              )} ${translationUtils.getTranslation(3351, 'Token')}`}
              onClick={() => {
                this.props.handleTokenAction(
                  this.props.tokenItem,
                  'deactivate'
                );
              }}
            />
          </td>
        </tr>
        <tr
          className={
            'ph-flex-table__row row-second-part d-flex flex-column flex-sm-row justify-content-around'
          }
        >
          <div className="token-display">
            <span>
              {translationUtils.getTranslation(3342, 'Token')}&nbsp;
              {translationUtils.getTranslation(2021, 'Id')}:&nbsp;
            </span>
            <input
              type={
                this.props.showToken[this.props.index] ? 'text' : 'password'
              }
              className="ph_token_input"
              value={this.props.tokenItem.Token}
              readOnly
            />
            <i
              className="fa fa2 fa-eye"
              title={`${translationUtils.getTranslation(
                264,
                'View'
              )} ${translationUtils.getTranslation(3342, 'Token')}`}
              onClick={() => {
                this.props.handleTokenView(this.props.index);
              }}
            ></i>
            {this.props.copyToken[this.props.index] ? (
              <i
                className="fa fa2 fa-check"
                title={translationUtils.getTranslation(3355, 'Copied')}
              ></i>
            ) : (
              <i
                className="fa fa2 fa-copy"
                title={`${translationUtils.getTranslation(
                  2241,
                  'Copy'
                )} ${translationUtils.getTranslation(3342, 'Token')}`}
                onClick={() => {
                  this.props.handleTokenCopy(
                    this.props.index,
                    this.props.tokenItem.Token
                  );
                }}
              ></i>
            )}
          </div>
        </tr>
      </>
    ) : (
      ''
    );
  }
}

export default TokenListItem;
