import React, { Component } from 'react';
import {
  changeUserStatusApi,
  getEditUserDetails,
  editRolesApiUrl,
  getLoginHistoryURL,
  getLocationByLogonId,
  getAssignRolesApi,
  changeUserStatusApiEdit,
} from '../../common-utils/api_urls';
import * as translationUtils from '../../common-utils/translation_utils';
import getUserDetail, {
  ActivateUser,
  saveRemoveLocation,
  getOktaLoginHistory,
  AssignRolesData,
  AssignApplicationBasedAccess,
  saveApplicationBasedAccess,
} from '../../services/getUserDetail';
import './assets/css/custom-styles.css';
import moment from 'moment';
import $ from 'jquery';
import ResetPassword from './ResetPassword';
import { getListOfLocation } from '../../services/getDeletegatedAdminData';
import EditMultipleLocation from './EditMultipleLocation';
import { Link } from 'react-router-dom';
import PopUpComponent from './PopUpComponent';
import RemoveLocationModel from './RemoveLocationModel';
import { useEffect } from 'react/cjs/react.production.min';
import RemoveMultipleLocationModel from './RemoveMultipleLocationModel';
import ConfirmApplicationRole from './ConfirmApplicationRole';
{
  /*import './assets/css/style.min.css';
  import './assets/css/custom-styles.css'; */
}
const editableRoles = [];
class edituser extends Component {
  $ = window.jQuery;
  constructor(props) {
    super(props);
    this.state = {
      setPasswordModel1: false,
      id: null,
      legacyParkerUsername: null,
      isSetData: true,
      lastName: null,
      lastLogin: [],
      email: null,
      login: null,
      firstName: null,
      userType: null,
      userStatus_value: JSON.parse(localStorage.getItem('userDetails'))
        .isDeactivated
        ? 'deactivate'
        : 'activate',
      userStatus: JSON.parse(localStorage.getItem('userDetails')).isDeactivated,
      isPending: JSON.parse(localStorage.getItem('userDetails')).isPending,
      orgList: this.props.orgList,
      changeClass: 'edit',
      locationData: [],
      editFlag: null,
      editedRoles: [],
      userRoles: [],
      selectedRoleForAll: { displayName: null, roleId: null, roleName: null },
      editRoleFlage: false,
      dropdownDisabled: true,
      commonRolesFlag: false,
      selectedValue: null,
      editRow: null,
      checkedLocationList: [],
      countOfAdminLoc: null,
      isLoading: false,
      isAllSelected: false,
      sorting: 'DESC',
      open: false,
      removeMultipleOpen: false,
      accessApplicationRoleFlag: false,
      elementId: '',
      applicationbasedlocationData: [], //Used to Setup the Application Based Roles Data to be setuped for state setup
      selectedRecordsApp: [], //Used to Saved the Selected Application Roles which will be checked by Used and will be Passed to Popup Component
      recordStatusCodeArr: [],
      isChecked: false,
    };
    this.saveUserStatus = this.saveUserStatus.bind(this);
    this.chnageClass = this.chnageClass.bind(this);
    this.editMultiplePopUp = this.editMultiplePopUp.bind(this);
    this.getLocation = this.getLocation.bind(this);

    //this.changeAllRoles=this.changeAllRoles(this);
  }
  handleOnChange1 = (e) => {
    //console.log('OnChange Checkbox');
  };
  getLocation(logonId) {
    this.setState({ isLoading: true });
    let apiUrl = getLocationByLogonId(logonId, this.state.locId);
    if (this.state.locId != undefined)
      getListOfLocation(this, apiUrl, this.state.locId, logonId);
    else {
      let arr = [];
      this.props.orgList.map((item) =>
        item.isAdmin ? arr.push(item.orgId) : []
      );
      getListOfLocation(this, apiUrl, arr, logonId);
    }
  }
  getLocationCallBack(data) {
    this.setState({
      locationData: data.rolesForCustomers,
      userStatus: data.isDeactivated,
      isPending: data.isPending,
    });
    let userDetails = JSON.parse(localStorage.getItem('userDetails'));
    userDetails.isDeactivated = data.isDeactivated;
    userDetails.isPending = data.isPending;
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
  }

  //Setting the Application Based Roles Access to Set the Data in State Variable and use in Rendering the Data
  getApplicationBasedRolesCallBack(data) {
    let arr =
      data != null &&
      data.map(
        (orgItem, index) =>
          orgItem.WebResourceID + '-' + orgItem.RecordStatusCode
      );
    let evntArr = arr[0].split('-');
    let WebResourceIDVal = evntArr[0];
    let RecordStatusCode = evntArr[1];
    this.setState({
      applicationbasedlocationData: data,
      recordStatusCodeArr: arr,
      isChecked: RecordStatusCode === 'A' ? true : false,
    });
  }

  //Calling the SaveApplicationRole API Method to Initiate the API to Update the Status
  callToHandleApplicationRole = async (e) => {
    //console.log('Getting the Id ', this.state.id);
    const saveApplicationBasedAccessResult = await saveApplicationBasedAccess(
      this,
      e,
      this.state.id
    );
    if (saveApplicationBasedAccessResult === 'success') {
      this.props.getLoader(false);
      setTimeout(() => {
        this.setState({
          message: 'Data Saved Successfully',
          status: saveApplicationBasedAccessResult,
        });
      }, 1000);
    } else {
      this.props.getLoader(false);
      setTimeout(() => {
        this.setState({
          message: 'Server Error',
          status: 'Failed',
        });
      }, 1000);
    }

    console.log(
      'saveApplicationBasedAccessResult==',
      saveApplicationBasedAccessResult
    );
  };

  //Calling the ChangeClassMethod within the Popup Boxes to handle the  OnClick Event of Buttons in the Modal Window
  chnageClass(e) {
    let apiUrl = '';
    let locationsAndRoles;
    let rowData;
    if (
      !e.target.className.includes('remove') &&
      !e.target.className.includes('multipleLocationInactive')
    ) {
      rowData = e.target.parentElement.parentElement.childNodes;
    }

    if (
      e.target.className === 'edit' &&
      e.target.style.display === 'inline-block'
    ) {
      if (!editableRoles.includes(e.target.id)) {
        editableRoles.push(e.target.id);
        this.setState({ editedRoles: editableRoles });
      }

      this.setState({
        editRoleFlage: true,
        dropdownDisabled: false,
        editRow: e.target.id,
      });

      rowData[4].childNodes[0].style.display = 'none';
      rowData[4].childNodes[1].style.display = 'inline-block';
    } else if (
      e.target.className === 'done' &&
      e.target.style.display === 'inline-block'
    ) {
      if (
        this.state.selectedValue == 'default' ||
        (this.state.selectedValue == null &&
          e.target.id.split(':')[1] == 'undefined')
      ) {
        this.setState({
          message: translationUtils.getTranslation(
            1075,
            'Please select a role'
          ),
          status: 'failed',
        });
      } else {
        window.scrollTo(0, 0);
        this.props.getLoader(true);
        let rolesInOrgPayload = [];

        //console.log("selectedID", e.target.id)
        let rolelocation = this.state.selectedValue
          ? this.state.selectedValue
          : e.target.id;
        this.state.orgList.map((org, index) =>
          org.legalId == rolelocation.split(':')[0]
            ? rolesInOrgPayload.push(
                org.orgId + ':' + rolelocation.split(':')[1]
              )
            : ''
        );

        let orgId = rowData[1].childNodes[0].nodeValue;
        apiUrl = editRolesApiUrl(this.state.login, 'edit');
        locationsAndRoles = {
          rolesInOrg: rolesInOrgPayload,
          adminId: window.sessionStorage.oktaUserEmail
            ? window.sessionStorage.oktaUserEmail
            : '',
          logonId: JSON.parse(
            localStorage.getItem('userDetails')
          ).logonId.toLowerCase(),
        };
        saveRemoveLocation(this, apiUrl, locationsAndRoles);
        rowData[4].childNodes[0].style.display = 'inline-block';
        rowData[4].childNodes[1].style.display = 'none';
        this.setState({ dropdownDisabled: true });

        //To refresh location data
        setTimeout(() => {
          this.getLocation(
            JSON.parse(localStorage.getItem('userDetails')).logonId
          );
          this.props.getLoader(false);
          this.setState({ message: '' });
        }, 5000);
      }
    }

    if (e.target.className.includes('remove')) {
      window.scrollTo(0, 0);
      this.props.getLoader(true);
      let rolesInOrgPayload = [];
      // console.log(this.state.selectedValue.split(":")[0])
      let rolelocation = this.state.selectedValue
        ? this.state.selectedValue
        : e.target.id;
      this.state.orgList.map((org, index) =>
        org.legalId == rolelocation.split(':')[0]
          ? rolesInOrgPayload.push(org.orgId + ':' + rolelocation.split(':')[1])
          : ''
      );
      //console.log("remove", e.target.id);
      //apiUrl = editRolesApiUrl(this.state.login, "remove");
      locationsAndRoles = {
        orgList: rolesInOrgPayload.toString().split(':')[0],
        adminId: window.sessionStorage.oktaUserEmail
          ? window.sessionStorage.oktaUserEmail.toLowerCase()
          : '',
        logonId: JSON.parse(
          localStorage.getItem('userDetails')
        ).logonId.toLowerCase(),
      };
      // saveRemoveLocation(this, apiUrl, locationsAndRoles);
      apiUrl = changeUserStatusApi(true);
      ActivateUser(this, apiUrl, locationsAndRoles);
      //To refresh location data
      setTimeout(() => {
        this.getLocation(
          JSON.parse(localStorage.getItem('userDetails')).logonId
        );
        this.props.getLoader(false);
        this.setState({ message: '', dropdownDisabled: true });
      }, 5000);
    }

    //Changes made by Ayushya to handle the double Alert
    if (e.target.className.includes('multipleLocationInactive')) {
      window.scrollTo(0, 0);
      this.props.getLoader(true);
      console.log('removeMultiple APi hit');
      console.log(
        'this.state.checkedLocationList',
        this.state.checkedLocationList
      );
      let removeOrgList = [];
      this.props.orgList.map((org, index) =>
        this.state.isAllSelected
          ? this.state.locationData.map((loc, idx) =>
              org.legalId == loc.customerID ? removeOrgList.push(org.orgId) : ''
            )
          : this.state.checkedLocationList.map((locItem, idx) =>
              org.legalId == locItem.value.split('$$$')[0]
                ? removeOrgList.push(org.orgId)
                : ''
            )
      );
      console.log('removeOrgList.join(",")', removeOrgList.join(','));
      locationsAndRoles = {
        orgList: removeOrgList.join(','),
        adminId: window.sessionStorage.oktaUserEmail
          ? window.sessionStorage.oktaUserEmail.toLowerCase()
          : '',
        logonId: JSON.parse(
          localStorage.getItem('userDetails')
        ).logonId.toLowerCase(),
      };
      // saveRemoveLocation(this, apiUrl, locationsAndRoles);
      apiUrl = changeUserStatusApi(true);
      ActivateUser(this, apiUrl, locationsAndRoles);
      //To refresh location data
      this.getLocation(JSON.parse(localStorage.getItem('userDetails')).logonId);
      setTimeout(() => {
        this.setState({ message: '', dropdownDisabled: true });
      }, 7000);
    }
  }

  setUserDataInfo(data) {
    this.setState({
      id: data.groupMap.id,
      legacyParkerUsername: data.groupMap.legacyParkerUsername,
      lastName: data.groupMap.lastName,
      //lastLogin: data.groupMap.lastLogin,
      email: data.groupMap.email,
      login: data.groupMap.login,
      firstName: data.groupMap.firstName,
      userType: data.groupMap.userType,
    });
  }

  setLoginHistory(data) {
    //  console.log("loginh history" + data);
    this.setState({
      lastLogin: data,
    });
    setTimeout(() => {
      this.props.getLoader(false);
    }, 5000);
  }

  setLocations(data) {
    this.setState({
      orgList: data.header.orgList,
    });
  }
  componentWillUpdate() {
    console.log('Will Update', this.state.isSetData);
    // if (!this.state.isSetData) {
    //   window.addEventListener('beforeunload', (e) => {
    //     e.preventDefault();
    //     e.returnValue = '';
    //   });
    // }
    if (this.state.isLoading === true) {
      //  console.log(this.state.isLoading)
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    /** Prevent page to leave if userdata not save **/

    window.addEventListener('beforeunload', this.beforeunload.bind(this));
    // if (!this.state.isSetData) {
    //   window.addEventListener('beforeunload', (e) => {
    //     e.preventDefault();
    //     e.returnValue = '';
    //   });
    // }

    this.props.getLoader(true);
    window.scrollTo(0, 0);
    let apiUrl = getEditUserDetails(
      JSON.parse(localStorage.getItem('userDetails')).email
    );
    // let apiLocation= getHeaderFooterUrl("EN",JSON.parse(localStorage.getItem("userDetails")).email);
    let loginHistoryURL = getLoginHistoryURL(
      JSON.parse(localStorage.getItem('userDetails')).email
    );
    getUserDetail(this, apiUrl);
    // getAssignLocationsAPI(this,apiLocation);
    getOktaLoginHistory(this, loginHistoryURL);
    this.getLocation(JSON.parse(localStorage.getItem('userDetails')).logonId);

    //User roles Api call
    let rolesApi = getAssignRolesApi();
    AssignRolesData(this, rolesApi);

    //Calling the AssignRolesAPI Calls
    AssignApplicationBasedAccess(this);

    //count admin locations
    let countOfAdminLoc = 0;
    this.props.orgList.map((org, index) =>
      org.isAdmin ? (countOfAdminLoc += 1) : (countOfAdminLoc += 0)
    );
    this.setState({ countOfAdminLoc: countOfAdminLoc });
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
  }

  beforeunload(e) {
    if (!this.state.isSetData) {
      e.preventDefault();
      e.returnValue = true;
    }
  }
  setUserData(data) {
    this.setState({ userRoles: data.roleList });
  }

  updateFirstName(e) {
    // console.log('FirstName', e.target.value);
    this.setState({ firstName: e.target.value });
    this.setState({ isSetData: false });
    // console.log(
    //   'Post Firtsname Updation Current Status is: ',
    //   this.state.isSetData
    // );
  }
  updateLastName(e) {
    // console.log('LastName', e.target.value);
    this.setState({ lastName: e.target.value });
    this.setState({ isSetData: false });
  }
  changeStatus(e) {
    if (e.target.value == 'Active') {
      //this.setState({ userStatus: false, isPending: false});
      this.setState({ userStatus: false });
      this.setState({ userStatus_value: 'activate' });
      this.setState({ isSetData: false });
    } else if (e.target.value == 'Disabled') {
      //this.setState({ userStatus: true, isPending: false });
      this.setState({ userStatus: true });
      this.setState({ userStatus_value: 'deactivate' });
      this.setState({ isSetData: false });
    }
    // if (!this.state.userStatus) {
    //     this.setState({ userStatus: true });
    // } else {
    //     this.setState({ userStatus: false });
    // }
  }
  saveUserStatus() {
    // console.log('Before IssetData St', this.state.isSetData);
    // this.setState({ isSetData: true });

    window.scrollTo(0, 0);
    this.props.getLoader(true);
    let locLegalIds = [];
    this.state.locationData.map((loc, idx) =>
      this.props.orgList.map((org, index) =>
        org.legalId == loc.customerID ? locLegalIds.push(org.orgId) : ''
      )
    );
    //console.log('locLegalIds', locLegalIds.toString());
    let payLoad = {
      orgList: locLegalIds.toString(),
      adminId: sessionStorage.getItem('oktaUserEmail'),
      logonId: JSON.parse(localStorage.getItem('userDetails')).logonId,
      // firstName: JSON.parse(localStorage.getItem('userDetails')).firstName,
      // lastName: JSON.parse(localStorage.getItem('userDetails')).lastName,
      firstName:
        this.state.firstName != null
          ? this.state.firstName
          : JSON.parse(localStorage.getItem('userDetails')).firstName,
      lastName:
        this.state.lastName != null
          ? this.state.lastName
          : JSON.parse(localStorage.getItem('userDetails')).lastName,
      access: this.state.userStatus_value,
    };
    let apiUrl = changeUserStatusApiEdit(this.state.userStatus);
    ActivateUser(this, apiUrl, payLoad);
    setTimeout(() => {
      this.getLocation(JSON.parse(localStorage.getItem('userDetails')).logonId);
      this.props.getLoader(false);
      this.setState({ message: '' });
    }, 5000);
    ///console.log("oktaUserInfo",this.props.oktaUserInfo);
  }
  setAPIResponose(status, message, profileUpdated) {
    this.props.getLoader(false);
    //console.log('Prolfile Status', profileUpdated);
    if (status == 200) {
      status = 'Success';
      if (profileUpdated == false) {
        let userDetails = JSON.parse(localStorage.getItem('userDetails'));
        userDetails.firstName = JSON.parse(
          localStorage.getItem('userDetails')
        ).firstName;
        userDetails.lastName = JSON.parse(
          localStorage.getItem('userDetails')
        ).lastName;
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
        status = 'P_Success';
      } else {
        let userDetails = JSON.parse(localStorage.getItem('userDetails'));
        userDetails.firstName =
          this.state.firstName != null
            ? this.state.firstName
            : JSON.parse(localStorage.getItem('userDetails')).firstName;
        userDetails.lastName =
          this.state.lastName != null
            ? this.state.lastName
            : JSON.parse(localStorage.getItem('userDetails')).lastName;
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
      }
    } else {
      message = translationUtils.getTranslation(
        2204,
        'Sorry, something went wrong'
      );
    }
    this.setState({
      status: status,
      message: message,
      selectedValue: null,
      isSetData: true,
    });
    window.removeEventListener('beforeunload', (e) => {
      console.log('Remove Event Listner is Called');
      e.preventDefault();
      e.returnValue = '';
    });
    console.log('After IssetData Status', this.state.isSetData);
  }

  editMultiplePopUp(e) {
    let targetClass = e.target.className;
    let modal = $('#myModal')[0];
    if (modal != undefined && modal.style.display != 'block') {
      modal.style.display = 'block';
    }
    // console.log(targetClass);
    if (modal != undefined && targetClass === 'popup-close') {
      modal.style.display = 'none';
    }
  }
  changeAllRoles = (role) => {
    this.setState({
      selectedRoleForAll: role,
      commonRolesFlag: true,
      editRoleFlage: false,
    });
  };
  selectRoles(e) {
    // console.log("Roles and Org",e.target.value)
    this.setState({ selectedValue: e.target.value });
  }

  onCheckedLocations(event) {
    // console.log("onCheckedLocations", event.target.value);
    if (event.target.value == 'all' && this.state.isAllSelected == false) {
      this.setState({ isAllSelected: true });
    } else {
      this.setState({ isAllSelected: false });
    }

    var array = [];
    this.state.checkedLocationList.map(
      (loc, index) => (array[index] = loc.value)
    );

    var index = array.indexOf(event.target.value);
    if (index > -1) {
      this.unCheckLocationItem(event.target.value);
    } else {
      this.onAddLocationItem(event.target.value);
    }
    if (event.target.value == 'all' && this.state.isAllSelected == true) {
      this.setState({ checkedLocationList: [] });
    }
  }
  onAddLocationItem(value) {
    const { checkedLocationList } = this.state;
    if (value == 'all') {
      let allLoc = [];
      this.state.locationData.map((val, key) =>
        allLoc.push({ value: val.customerID + '$$$' + val.customerName })
      );
      this.setState({ checkedLocationList: allLoc });
    } else {
      checkedLocationList.push({ value });
      this.setState({ checkedLocationList });
    }
  }
  unCheckLocationItem(value) {
    let filteredArray = this.state.checkedLocationList.filter(
      (loc) => loc.value !== value
    );
    this.setState({ checkedLocationList: filteredArray });
  }
  locationSorting(type) {
    let sortedLocations = [];
    sortedLocations = this.state.locationData;
    if (this.state.sorting == 'DESC') {
      sortedLocations.sort((a, b) => b[type].localeCompare(a[type]));
    }
    if (this.state.sorting == 'ASC') {
      sortedLocations.sort((a, b) => a[type].localeCompare(b[type]));
    }

    this.setState({
      locationData: sortedLocations,
      sorting: this.state.sorting == 'DESC' ? 'ASC' : 'DESC',
    });
  }
  saveMultiLocationRoles = (apiUrl, locationsAndRoles) => {
    debugger;
    window.scrollTo(0, 0);
    this.props.getLoader(true);
    saveRemoveLocation(this, apiUrl, locationsAndRoles);
    setTimeout(() => {
      this.getLocation(JSON.parse(localStorage.getItem('userDetails')).logonId);
      this.props.getLoader(false);
      this.setState({ message: '', dropdownDisabled: true });
    }, 5000);
  };

  //Setting the Modal Window to Open on Click to the Buttons
  assignModal = (flag, elementId) => {
    this.setState({ open: flag, elementId: elementId });
  };
  removeModalClose = (flag) => {
    // console.log('flag', flag);
    this.setState({ removeMultipleOpen: flag });
    this.setState({ accessApplicationRoleFlag: flag });
  };

  //Handing the OnClick Button  Events for Assign roles for Application when Clicked on Save Role

  accessApplicationRoleModal = (flag) => {
    // console.log('flag', flag);
    this.setState({ accessApplicationRoleFlag: flag });
  };

  onCheckedApplicationRoleBox(event) {
    const value = event.target.value;
    let selectedRecordsApp = [...this.state.recordStatusCodeArr];
    if (event.target.value === 'all' && this.state.isAllSelected === false) {
      this.setState({ isAllSelected: true });
    } else {
      this.setState({ isAllSelected: false });
    }
    if (event.target.checked) {
      selectedRecordsApp.pop(value + '-D');
      selectedRecordsApp.push(value + '-A');
    } else {
      selectedRecordsApp.pop(value + '-A');
      selectedRecordsApp.push(value + '-D');
    }
    let unique = [...new Set(selectedRecordsApp)];
    this.setState({
      recordStatusCodeArr: unique,
      isChecked: !this.state.isChecked,
    });
  }

  render() {
    let editRows = this.state.editedRoles;
    //console.log('selectedRecordsApp==', this.state.recordStatusCodeArr, this.state.selectedRecordsApp);
    return (
      <div>
        <div className="container-fluid">
          <div className="ph-header-main__title row px-md-4 px-lg-3">
            <div className="col">
              <h1 className="p-title">
                {translationUtils.getTranslation(1167, 'Edit') +
                  ' ' +
                  translationUtils.getTranslation(2185, 'User')}
              </h1>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="ph-header-main__breadcrumbs row px-md-4 px-lg-3 py-2 ">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item" aria-current="page">
                    <a
                      href={
                        window.location.origin +
                        window.location.pathname.split('editUser')[0] +
                        'all' +
                        window.location.search
                      }
                    >
                      {translationUtils.getTranslation(791, 'Home')}
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <a
                      href={
                        '/' +
                        this.props.lang +
                        '/myparker/authenticatedUser?cid=' +
                        this.props.cid
                      }
                    >
                      {translationUtils.getTranslation(
                        2167,
                        'Authorized Users'
                      )}
                    </a>
                    {/* <Link
                      to={
                        '/' +
                        this.props.lang +
                        '/myparker/authenticatedUser?cid=' +
                        this.props.cid
                      }
                    >
                      {translationUtils.getTranslation(
                        2167,
                        'Authorized Users'
                      )}{' '}
                    </Link> */}
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {translationUtils.getTranslation(1167, 'Edit') +
                      ' ' +
                      translationUtils.getTranslation(2185, 'User')}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="container px-5 apply-overflow-hidden apply-auto-margin">
          <div className="header-details pt-4">
            {translationUtils.getTranslation(2178, 'User Details')}

            <button
              style={{ float: 'right' }}
              type="button"
              className="button primary-button round-button medium-button ml-1"
              id={this.state.isPending ? 'disabled-button' : ''}
              disabled={this.state.isPending}
              onClick={this.saveUserStatus}
            >
              {translationUtils.getTranslation(1075, 'Save')}
            </button>

            <div className="line"></div>
          </div>

          <div className="user-details-container">
            <div className="profile-item">
              <div className="rectangle-header">
                <div className="sub-headers">
                  {translationUtils.getTranslation(2179, 'Profile')}{' '}
                </div>
              </div>
              <div className="profile-container">
                <form>
                  <div className="ph-form-row">
                    <label className="ph-form-row__label" htmlFor="email-id">
                      {translationUtils.getTranslation(2182, 'User ID')} /{' '}
                      {translationUtils.getTranslation(408, 'Email')}
                    </label>
                    <input
                      className="ph-form-row__input"
                      type="text"
                      id="email-id"
                      value={
                        JSON.parse(localStorage.getItem('userDetails')).email
                      }
                      readOnly
                    />
                  </div>
                  <div className="ph-form-row">
                    <label className="ph-form-row__label" htmlFor="fname">
                      {translationUtils.getTranslation(61, 'First Name')}
                    </label>
                    {JSON.parse(localStorage.getItem('userDetails')).email &&
                    !JSON.parse(
                      localStorage.getItem('userDetails')
                    ).email.includes('@parker.com') ? (
                      <input
                        className="ph-form-row__input drk_colo"
                        type="text"
                        id="fname"
                        onChange={(e) => this.updateFirstName(e)}
                        defaultValue={
                          JSON.parse(localStorage.getItem('userDetails'))
                            .firstName
                        }
                      />
                    ) : (
                      <input
                        className="ph-form-row__input"
                        type="text"
                        id="fname"
                        value={
                          JSON.parse(localStorage.getItem('userDetails'))
                            .firstName
                        }
                      />
                    )}
                  </div>
                  <div className="ph-form-row">
                    <label className="ph-form-row__label" htmlFor="lname">
                      {translationUtils.getTranslation(62, 'Last Name')}
                    </label>
                    {JSON.parse(localStorage.getItem('userDetails')).email &&
                    !JSON.parse(
                      localStorage.getItem('userDetails')
                    ).email.includes('@parker.com') ? (
                      <input
                        className="ph-form-row__input drk_colo"
                        type="text"
                        id="lname"
                        onChange={(e) => this.updateLastName(e)}
                        defaultValue={
                          JSON.parse(localStorage.getItem('userDetails'))
                            .lastName
                        }
                      />
                    ) : (
                      <input
                        className="ph-form-row__input"
                        type="text"
                        id="lname"
                        value={
                          JSON.parse(localStorage.getItem('userDetails'))
                            .lastName
                        }
                        readOnly
                      />
                    )}
                  </div>
                  {/*<div className="btn-right-align pt-4">
                                            <button type="button" className="button primary-button round-button medium-button muted-button ml-1" disabled="true">Save</button>
    </div> */}
                </form>
              </div>
            </div>

            <div className="mid-item">
              {this.state.email &&
              !this.state.email.includes('@parker.com') &&
              !JSON.parse(localStorage.getItem('userDetails')).isPending &&
              !JSON.parse(localStorage.getItem('userDetails')).isPending ? (
                <div className="mid-item-a">
                  <div className="rectangle-header">
                    <div className="sub-headers">
                      {' '}
                      {translationUtils.getTranslation(1274, 'Password')}{' '}
                    </div>
                  </div>
                  <div className="btn-reset-div">
                    <button
                      type="button"
                      className="button primary-button round-button medium-button ml-1"
                      data-toggle="modal"
                      data-target="#resetpasswordModal"
                    >
                      {translationUtils.getTranslation(58, 'Reset') +
                        ' ' +
                        translationUtils.getTranslation(1274, 'Password')}
                    </button>
                  </div>
                  <ResetPassword
                    userUID={
                      JSON.parse(localStorage.getItem('userDetails')).logonId
                    }
                    userGUID={this.state.id}
                  />
                  <div className="send-a-reset-password">
                    {translationUtils.getTranslation(
                      2209,
                      'Send a reset password link or send a temporary one.'
                    )}
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="mid-item-b">
                <div className="rectangle-header">
                  <div className="sub-headers">
                    {translationUtils.getTranslation(2180, 'Account Status')}
                  </div>
                  {this.state.message != '' &&
                    this.state.message != undefined && (
                      <PopUpComponent
                        status={this.state.status}
                        message={this.state.message}
                      />
                    )}
                </div>
                <div className="p-3">
                  <div className="ph-form-row__group--inline pt-3 pl-2">
                    <div className="ph-input__inline">
                      <input
                        className="ph-input__radio"
                        type="radio"
                        name="inlineRadioOptions"
                        value="Active"
                        // checked={this.state.userStatus==false && this.state.isPending==false}
                        defaultChecked={this.state.userStatus == false}
                        onChange={(e) => this.changeStatus(e)}
                      />
                      <label
                        className="ph-input__label--inline"
                        htmlFor="inlineRadio1"
                      >
                        {translationUtils.getTranslation(2169, 'Active')}
                      </label>
                    </div>
                    <div className="ph-input__inline">
                      <input
                        className="ph-input__radio"
                        type="radio"
                        name="inlineRadioOptions"
                        value="Disabled"
                        // checked={this.state.userStatus==true && this.state.isPending==false}
                        defaultChecked={this.state.userStatus == true}
                        onChange={(e) => this.changeStatus(e)}
                      />
                      <label
                        className="ph-input__label--inline"
                        htmlFor="inlineRadio2"
                      >
                        {translationUtils.getTranslation(2069, 'Inactive')}
                      </label>
                    </div>
                    <div className="ph-input__inline">
                      <input
                        className="ph-input__radio"
                        type="radio"
                        name="inlineRadioOptions"
                        value="Panding"
                        disabled={true}
                        defaultChecked={this.state.isPending}
                        onChange={(e) => this.changeStatus(e)}
                      />
                      <label
                        className="ph-input__label--inline"
                        htmlFor="inlineRadio1"
                      >
                        {translationUtils.getTranslation(2170, 'Pending')}
                      </label>
                    </div>
                  </div>

                  {/* <div className="btn-right-align pr-4 pt-5">
                    <button
                      type="button"
                      className="button primary-button round-button medium-button ml-1"
                      id={this.state.isPending ? 'disabled-button' : ''}
                      disabled={this.state.isPending}
                      onClick={this.saveUserStatus}
                    >
                      {translationUtils.getTranslation(1075, 'Save')}
                    </button>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="login-history-item">
              <div className="rectangle-header">
                <div className="sub-headers">
                  {translationUtils.getTranslation(2181, 'Login History')}
                </div>
              </div>
              <div className="login-history-container">
                <div className="rowpt">
                  {this.state.lastLogin.groupMap &&
                  this.state.lastLogin.groupMap.length > 0
                    ? this.state.lastLogin.groupMap.map((map, index) => (
                        <>
                          <div style={{ paddingLeft: '6px' }}>
                            <div className="login-history">
                              {' '}
                              {map.published
                                ? moment(map.published).format(
                                    'MM/DD/YYYY - hh:mm A'
                                  )
                                : 'N/A'}
                            </div>
                            <div>{map.displayMessage}</div>
                          </div>
                        </>
                      ))
                    : translationUtils.getTranslation(2090, 'No Record Found')}
                </div>
              </div>
            </div>
          </div>

          <div className="header-details pt-4">
            {translationUtils.getTranslation(2183, 'Assigned Locations')}
            <div className="line"></div>
          </div>
          <div className="flex-container-assigned-loc">
            <div className="flex-child-buttons">
              {/*<button type="button" onClick={this.editMultiplePopUp} className="button primary-button round-button medium-button muted-button">Edit Multiple Location Roles</button> */}

              <div /*className="btn-reset-div" */>
                <button
                  type="button"
                  className="mr-auto button accent-button round-button medium-button cround ml-1"
                  data-toggle="modal"
                  disabled={
                    this.state.checkedLocationList.length < 2 &&
                    this.state.isAllSelected == false
                  }
                  id={
                    this.state.checkedLocationList.length < 2 &&
                    this.state.isAllSelected == false
                      ? 'disabled-button'
                      : ''
                  }
                  style={{
                    opacity:
                      this.state.checkedLocationList.length > 1 ||
                      this.state.isAllSelected == true
                        ? 1
                        : 0.6,
                  }}
                  data-target="#editMultipleLocation"
                >
                  {/* {translationUtils.getTranslation(
                    2184,
                    'Edit Multiple Location Roles'
                  )} */}
                  {'Edit Multiple Roles'}
                </button>
              </div>

              <EditMultipleLocation
                orgList={this.state.orgList}
                locationData={this.state.locationData}
                userRoles={this.state.userRoles}
                changeAllRoles={this.changeAllRoles}
                userEmail={this.state.login}
                checkedLocationList={this.state.checkedLocationList}
                isAllSelected={this.state.isAllSelected}
                getLoader={this.props.getLoader}
                getLocation={this.getLocation}
                saveMultiLocationRoles={this.saveMultiLocationRoles}
              />
            </div>
            {/* Blow Div is created 2 times */}
            <div className="flex-child-buttons">
              <div>
                <button
                  className={`button primary-button round-button medium-button ml-1 ${
                    this.state.checkedLocationList.length > 1 ||
                    (this.state.isAllSelected == true &&
                      this.state.locationData.length > 1)
                      ? 'btn_remove_location'
                      : ''
                  }`}
                  disabled={
                    this.state.checkedLocationList.length < 2 &&
                    this.state.isAllSelected == false
                  }
                  id={
                    this.state.checkedLocationList.length > 1 ||
                    (this.state.isAllSelected == true &&
                      this.state.locationData.length > 1)
                      ? ''
                      : 'disabled-button'
                  }
                  style={{
                    opacity:
                      this.state.checkedLocationList.length > 1 ||
                      this.state.isAllSelected == true
                        ? 1
                        : 0.6,
                  }}
                  onClick={() => this.removeModalClose(true)}
                >
                  Remove Multiple Location
                </button>
              </div>
            </div>

            <div className="flex-child-buttons">
              {this.state.countOfAdminLoc > this.state.locationData.length &&
              this.state.isPending == false ? (
                <a
                  href={
                    '/' +
                    this.props.lang +
                    '/myparker/addLocations?cid=' +
                    this.props.cid
                  }
                  className="button primary-button round-button medium-button ml-1"
                >
                  {translationUtils.getTranslation(2219, 'Add Location')}
                </a>
              ) : (
                // <Link
                //   to={
                //     '/' +
                //     this.props.lang +
                //     '/myparker/addLocations?cid=' +
                //     this.props.cid
                //   }
                //   type="button"
                //   className="button primary-button round-button medium-button ml-1"
                // >
                //   {translationUtils.getTranslation(2219, 'Add Location')}
                // </Link>
                <button
                  id="disabled-button"
                  type="button"
                  onClick={
                    this.state.countOfAdminLoc <= this.state.locationData.length
                      ? 'return false;'
                      : ''
                  }
                  style={{ opacity: 0.6 }}
                  className="button primary-button round-button medium-button ml-1"
                >
                  {translationUtils.getTranslation(2219, 'Add Location')}
                </button>
              )}
            </div>
          </div>

          <div className="pt-3"></div>
          <div className="dialog-box" hidden="true"></div>
          {this.state.isLoading === true ? (
            <div id="row-loading">
              <img
                src={
                  process.env.PUBLIC_URL +
                  '/assets/img/parker-search-loading.gif'
                }
                style={{ height: '25px' }}
              />{' '}
              <span className="load_ing">
                {translationUtils.getTranslation(521, 'Loading...')}
              </span>
            </div>
          ) : (
            <div className="contain-table">
              <div className="table-wrapper">
                <table className="table table-striped">
                  <thead className="ph-table__header--browse">
                    <tr className="ph-table__row">
                      <th
                        className="ph-table__title"
                        scope="col"
                        style={{ paddingLeft: '1.1rem' }}
                      >
                        <input
                          type="checkbox"
                          checked={this.state.isAllSelected}
                          id="selectAll"
                          value="all"
                          onClick={(e) => this.onCheckedLocations(e)}
                          onChange={this.handleOnChange1}
                        />
                      </th>
                      <th
                        className="ph-table__title"
                        scope="col"
                        onClick={() => this.locationSorting('customerID')}
                      >
                        {translationUtils.getTranslation(
                          2175,
                          'Location Number'
                        )}
                        <i className="fa fa-sort th-margin"></i>
                      </th>
                      <th
                        className="ph-table__title"
                        scope="col"
                        onClick={() => this.locationSorting('customerName')}
                      >
                        {translationUtils.getTranslation(2176, 'Location Name')}{' '}
                        <i className="fa fa-sort th-margin"></i>
                      </th>
                      <th
                        className="ph-table__title"
                        scope="col"
                        style={{ width: '35%' }}
                      >
                        Roles {/* <i className="fa fa-sort th-margin"></i> */}
                      </th>
                      <th className="ph-table__title">
                        {translationUtils.getTranslation(1064, 'Actions')}
                      </th>
                    </tr>
                  </thead>

                  <tbody className="ph-table__body">
                    {
                      ((this.locList = []),
                      this.state.checkedLocationList.map((val, key) =>
                        this.locList.push(val.value)
                      ),
                      // console.log(this.locList),
                      this.state.locationData != null &&
                        this.state.locationData.map((orgItem, index) => (
                          <tr className="ph-table__row">
                            <td className="extra_cursor">
                              <span className="ml-2">
                                <input
                                  type="checkbox"
                                  id={'checkbox' + index}
                                  name="options[]"
                                  value={
                                    orgItem.customerID +
                                    '$$$' +
                                    orgItem.customerName
                                  }
                                  checked={
                                    this.state.isAllSelected ||
                                    this.locList.includes(
                                      orgItem.customerID +
                                        '$$$' +
                                        orgItem.customerName
                                    )
                                  }
                                  onClick={(e) => this.onCheckedLocations(e)}
                                />
                              </span>
                            </td>
                            <td className="extra_cursor_remove">
                              {orgItem.customerID}
                            </td>
                            <td className="extra_cursor_remove">
                              {orgItem.customerName}
                            </td>
                            <td className="extra_cursor_remove">
                              {this.state.dropdownDisabled == false &&
                              this.state.editRow == index.toString() ? (
                                <select
                                  id="roles"
                                  name="role_filter_for"
                                  onChange={(e) => this.selectRoles(e)}
                                >
                                  <option value="default">
                                    {translationUtils.getTranslation(
                                      2218,
                                      'Please select a role'
                                    )}
                                  </option>
                                  {this.state.userRoles.map((role, index) => (
                                    <option
                                      selected={orgItem.roles.includes(
                                        role.roleId.toString()
                                      )}
                                      value={
                                        orgItem.customerID + ':' + role.roleId
                                      }
                                    >
                                      {role.displayName}
                                    </option>
                                  ))}
                                </select>
                              ) : orgItem.roles.includes('-21') ? (
                                translationUtils.getTranslation(
                                  2223,
                                  'Administrator'
                                )
                              ) : orgItem.roles.includes('-31') ? (
                                'User'
                              ) : orgItem.roles.includes('-21') &&
                                orgItem.roles.includes('-31') ? (
                                translationUtils.getTranslation(
                                  2223,
                                  'Administrator'
                                ) +
                                ',' +
                                translationUtils.getTranslation(2185, 'User')
                              ) : (
                                translationUtils.getTranslation(
                                  2069,
                                  'Inactive'
                                )
                              )}
                            </td>
                            <td>
                              <a
                                href="javascript:void(0)"
                                id={index}
                                className="edit"
                                style={{ display: 'inline-block' }}
                                onClick={this.chnageClass}
                              >
                                <i
                                  className="fa fa-pencil user-anchor-style"
                                  aria-hidden="true"
                                ></i>
                                {translationUtils.getTranslation(1167, 'Edit') +
                                  ' ' +
                                  translationUtils.getTranslation(1076, 'Role')}
                              </a>

                              <a
                                href="javascript:void(0)"
                                id={orgItem.customerID + ':' + orgItem.roles[0]}
                                className="done"
                                style={{ display: 'none' }}
                                onClick={this.chnageClass}
                              >
                                <i
                                  className="fa fa-check done-icon user-anchor-style"
                                  aria-hidden="true"
                                ></i>
                                {translationUtils.getTranslation(1075, 'Save')}
                              </a>

                              {!orgItem.isDeactivated ? (
                                <>
                                  {' '}
                                  <a
                                    href="javascript:void(0)"
                                    id={
                                      orgItem.customerID +
                                      ':' +
                                      orgItem.roles[0]
                                    }
                                    className="remove"
                                    // onClick={this.chnageClass}
                                    onClick={() =>
                                      this.assignModal(
                                        true,
                                        orgItem.customerID +
                                          ':' +
                                          orgItem.roles[0]
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-times user-anchor-style"
                                      aria-hidden="true"
                                    ></i>
                                    {translationUtils.getTranslation(
                                      797,
                                      'Remove'
                                    )}
                                  </a>
                                </>
                              ) : (
                                ''
                              )}
                            </td>
                          </tr>
                        )))
                    }
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {/*Adding section for Assign Roles to Application */}
          <div className="header-details pt-4">
            Assigned Application Roles
            <div className="line"></div>
          </div>
          {/* Code to Add the Save Role Button*/}
          <div className="flex-container-assigned-loc">
            <div className="flex-child-buttons">
              <div>
                <button
                  type="button"
                  className="button primary-button round-button medium-button ml-1"
                  data-toggle="modal"
                  // style={{
                  //   opacity:
                  //     this.state.applicationbasedlocationData.length > 1
                  //       ? 1
                  //       : 0.6,
                  // }}
                  onClick={() => this.accessApplicationRoleModal(true)}
                >
                  {'Save Roles'}
                </button>
              </div>
            </div>
          </div>

          {/* Code to Display the API Data listed*/}
          <div className="pt-3"></div>
          <div className="dialog-box" hidden="true"></div>
          {this.state.isLoading === true ? (
            <div id="row-loading">
              <img
                src={
                  process.env.PUBLIC_URL +
                  '/assets/img/parker-search-loading.gif'
                }
                style={{ height: '25px' }}
              />{' '}
              <span className="load_ing">
                {translationUtils.getTranslation(521, 'Loading...')}
              </span>
            </div>
          ) : (
            <div className="contain-table">
              <div className="table-wrapper">
                <table className="table table-striped">
                  <thead className="ph-table__header--browse">
                    <tr className="ph-table__row">
                      <th
                        className="ph-table__title"
                        scope="col"
                        style={{ paddingLeft: '1.1rem', width: '0%' }}
                      >
                        {/* <input
                          type="checkbox"
                          checked={this.state.isAllSelected}
                          id="selectAll"
                          value="all"
                          onClick={(e) => this.onCheckedApplicationRoleBox(e)}
                          onChange={this.handleOnChange1}
                        /> */}
                      </th>
                      <th className="ph-table__title" scope="col">
                        Application Name
                        <i className="fa fa-sort th-margin"></i>
                      </th>
                    </tr>
                  </thead>

                  {
                    <tbody className="ph-table__body">
                      {this.state.applicationbasedlocationData != null &&
                      this.state.applicationbasedlocationData.length > 0
                        ? this.state.applicationbasedlocationData.map(
                            (orgItem, index) => (
                              <tr className="ph-table__row">
                                <td className="extra_cursor">
                                  <span className="ml-2">
                                    <input
                                      type="checkbox"
                                      id={'checkbox' + index}
                                      //name="laptop"
                                      name="laptop[]"
                                      value={orgItem.WebResourceID}
                                      checked={this.state.isChecked}
                                      onClick={(e) =>
                                        this.onCheckedApplicationRoleBox(e)
                                      }
                                    />
                                  </span>
                                </td>
                                <td className="extra_cursor_remove">
                                  {orgItem.WebResourceName}
                                </td>
                              </tr>
                            )
                          )
                        : '<div class="header-details pt-4">No Data Found<div class="line"></div></div>'}
                    </tbody>
                  }
                </table>
              </div>
            </div>
          )}

          {/*Ending section for Assign Roles to Application */}
        </div>
        {this.state.open && (
          <RemoveLocationModel
            open={true}
            assignModal={this.assignModal}
            elementId={this.state.elementId}
            chnageClass={this.chnageClass}
          />
        )}
        {this.state.removeMultipleOpen && (
          <RemoveMultipleLocationModel
            open={true}
            assignModal={this.removeModalClose}
            elementId={this.state.elementId}
            chnageClass={this.chnageClass}
          />
        )}

        {this.state.accessApplicationRoleFlag && (
          <ConfirmApplicationRole
            open={true}
            assignModal={this.removeModalClose}
            selectedRecordsApp={this.state.selectedRecordsApp}
            recordStatusCodeArr={this.state.recordStatusCodeArr}
            callToHandleApplicationRole={this.callToHandleApplicationRole}
            getLoader={this.props.getLoader}
          />
        )}
      </div>
    );
  }
}

export default edituser;
