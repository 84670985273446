import React, { Component } from 'react'
import { getPasswordSetting } from '../../common-utils/api_urls';
import TemporaryPassword from './TemporaryPassword';
import { PasswordResetMail,PasswordResetTemporary } from '../../services/getUserDetail';
import PopUpComponent from './PopUpComponent';
import * as translationUtils from '../../common-utils/translation_utils'
class ResetPassword extends Component {
    constructor(props){
        super(props);
        this.state = {
            setPasswordModel: false,
            temporaryPassword: null,
            message: "",
            status: ""
        }
        this.setTemporaryPassword = this.setTemporaryPassword.bind(this);
        this.onRefreshModel = this.onRefreshModel.bind(this);
    }  

    onResetPassword(){
       
     let passdataUrl = getPasswordSetting(this.props.userUID,"reset");
    //console.log('PasswordSetting',passdataUrl);
     PasswordResetMail(this,passdataUrl);     
    }
    onResetPasswordTemp(){
     this.setState({setPasswordModel:true});
     let passdataUrl1 = getPasswordSetting(this.props.userGUID,"temporary");
     PasswordResetTemporary(this,passdataUrl1);
    }
    setTemporaryPassword(data){
        this.setState({
            temporaryPassword:data.temporaryPassword
        });
    }
    onRefreshModel(){
        //console.log('Inside ResetPass');
        this.setState({setPasswordModel:false})
    }
    setAPIResponose =(status, message) =>{
        //console.log(status+" : "+message)
        
        if (status == 200) {
            status = "Success";
        } else{
            status= "failed"
        }
        this.setState({
            status: status,
            message: message,
        });
       
    }
    reset(){
        this.setState({
            message: "",
            status: ""
        })
    }
    render() {
        //console.log(this.state)
        return (
    <div>
        <div className={this.state.setPasswordModel?"ph-modal fade":"ph-modal fade show in"} id="resetpasswordModal" tabindex="-1" role="dialog" aria-labelledby="Reset Password Modal"
        aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="ph-modal__content ph-modal__content__wider">
                    <div className="d-flex justify-content-end m-0 p-0">
                        <span data-dismiss="modal" aria-label="Close" className="ph-modal__header--close ph-modal-header-close-font-size" aria-hidden="true"><i
                                className="fas fa-times" onClick={()=> this.reset()}></i></span>
                    </div>
                    <div className="ph-modal__header">
                        <h3 className="ph-modal__title" id="resetpasswordModalLabel">{translationUtils.getTranslation(58, "Reset")+' '+ translationUtils.getTranslation(1274, "Password")}</h3>
                    </div>
                    <div className="ph-modal__body">
                                        
                        <div className="row ph-form-import-row pt-3">
                            <div className="col-12 col-sm-8 text-justify">
                            {translationUtils.getTranslation(2232, "A password reset link is sent to the user's email address, the password is not automatically reset. The password reset link will expire in")}      <span className="password-1-day-color bold">1 {translationUtils.getTranslation(2233, "Day")} </span>{translationUtils.getTranslation(2234, "after it is sent.")}
                            </div>
                            <div className="col-6 col-sm-4 pt-2 pl-3">
                                <button type="button" className="button round-button small-button btn-bit-wider" onClick={()=>this.onResetPassword()}>{translationUtils.getTranslation(2235, "Send Password Reset")}</button>
                            </div>     
                            <div className="col-12 col-sm-8 pt-3 text-justify">
                            {translationUtils.getTranslation(2237, "A temporary password will be created for the account and shown to you.")+' '+translationUtils.getTranslation(2238, "The account will be marked as expired so that the user will be required to change the password when they login.")} 
                            </div>
                            <div className="col-6 col-sm-4 padding-browse pl-3">
                                <button type="button" className="button round-button small-button btn-bit-wider" data-toggle="modal" data-target="#temporarypasswordModal" onClick={()=>this.onResetPasswordTemp()}>{translationUtils.getTranslation(2239, "Temporary Password")}</button>
                            </div>    
                            
                        </div>

                    </div>
                    <div className="ph-modal__footer">
                        <button type="button" className="button accent-button round-button small-button"
                            data-dismiss="modal" onClick={()=> this.reset()}>{translationUtils.getTranslation(404, "Cancel")}</button>
                    </div>
                    {this.state.message != "" &&
                                        this.state.message != undefined && 
                                            <PopUpComponent
                                                status={this.state.status}
                                                message={this.state.message}
                                            />
                                        }
                </div>
            </div>
        </div>
        
                                            
                                    
        <TemporaryPassword tmpPas={this.state.temporaryPassword} onRefreshModel={this.onRefreshModel} message={this.state.message}/>
                                    
    </div>
        
        )
    }
}
export default ResetPassword;
