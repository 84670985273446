import React, { Component } from "react";
import {
  addUserApi,
  changeUserStatusApi,
  getAssignRolesApi,
  getEditUserDetails,
  getLocationByLogonId,
} from "../../common-utils/api_urls";
import * as translationUtils from '../../common-utils/translation_utils'
import getUserDetail, {
  ActivateUser,
  AssignRolesData,
  AddUserDetail,
} from "../../services/getUserDetail";
import "./assets/css/custom-styles.css";
import $ from "jquery";
import AssignRoles from "./AssignRoles";
import { getListOfLocation } from "../../services/getDeletegatedAdminData";
import { Link } from "react-router-dom";
const orgItemList = [];

class AddLocations extends Component {
  $ = window.jQuery;

  constructor(props) {
    super(props);
    this.state = {
      locationList: this.props.orgList,
      filterLocation: this.props.orgList,
      orgItems: [],
      userRolesData: null,
      addUserInfo: {
        logonId: JSON.parse(localStorage.getItem("userDetails")).email,
        firstName: JSON.parse(localStorage.getItem("userDetails")).firstName,
        lastName: JSON.parse(localStorage.getItem("userDetails")).lastName,
      },
      locationData: [],
      locationActiveTabClass: "yellow-active active",
      rolesActiveTabClass: "",
      isAllSelected: false,
    };

    //this.checkedLocation=this.checkedLocation.bind(this);
    this.removeListElement = this.removeListElement.bind(this);
    this.addListElement = this.addListElement.bind(this);
    this.getLocation = this.getLocation.bind(this);
  }
  nextButtonHandle(e) {
    this.handleLogin();
  }
  removeListElement(list = [], element) {
    let newList = [];
    let j = 0;
    for (let i = 0; i < list.length; i++) {
      if (i == list.indexOf(element)) {
        continue;
      }
      newList[j++] = list[i];
    }
    return newList;
  }
  addListElement(list = [], element) {
    list.push(element);
    let newList = list;

    return newList;
  }

  checkedLocation(orgItem) {
    //console.log("selectOrg", orgItem)
    let roleArr = []

    if (orgItem.length > 0 && this.state.isAllSelected == false) {
      this.setState({ isAllSelected: true })
      orgItem.map((item, index) => (
        item.isAdmin ? roleArr.push(item) : ""
      ))
    } else {
      this.setState({ isAllSelected: false })
    }
    let list = this.state.orgItems.includes(orgItem) ? this.removeListElement(this.state.orgItems, orgItem) : this.addListElement(this.state.orgItems, orgItem)
    orgItem.length > 0 ? this.setState({ orgItems: roleArr }) :
      this.setState({
        orgItems: list
      })


  }
  componentDidMount() {
    window.scrollTo(0,0);
    this.getLocation(JSON.parse(localStorage.getItem("userDetails")).logonId);
    var checkbox = window.$('table tbody input[type="checkbox"]');
    window.$("#selectAll").click(function () {
      if (this.checked) {
        checkbox.each(function () {
          this.checked = true;
        });
      } else {
        checkbox.each(function () {
          this.checked = false;
        });
      }
    });
    checkbox.click(function () {
      if (!this.checked) {
        window.$("#selectAll").prop("checked", false);
      }
    });

    //User roles Api call
    let rolesApi = getAssignRolesApi();
    AssignRolesData(this, rolesApi);
  }
  setUserData(data) {
    this.setState({
      userRolesData: data,
    });
  }
  handleEmailChange(e) {
    this.setState({
      addUserInfo: {
        logonId: e.target.value,
        firstName: this.state.addUserInfo.firstName,
        lastName: this.state.addUserInfo.lastName,
      },
    });
  }
  handleFNameChange(e) {
    this.setState({
      addUserInfo: {
        logonId: this.state.addUserInfo.logonId,
        firstName: e.target.value,
        lastName: this.state.addUserInfo.lastName,
      },
    });
  }
  handleLNameChange(e) {
    this.setState({
      addUserInfo: {
        logonId: this.state.addUserInfo.logonId,
        firstName: this.state.addUserInfo.firstName,
        lastName: e.target.value,
      },
    });
  }
  handleLogin() {
    //console.log("EMail: ", this.state.addUserInfo);
  }
  getLocation(logonId) {
    let apiUrl = getLocationByLogonId(logonId, this.state.locId);
    if (this.state.locId != undefined)
      getListOfLocation(this, apiUrl, this.state.locId, logonId);
    else {
      let arr = [];
      this.props.orgList.map((item) =>
        item.isAdmin ? arr.push(item.orgId) : []
      );
      getListOfLocation(this, apiUrl, arr.toString(), logonId);
    }
  }
  getLocationCallBack(data) {
    this.setState({
      locationData: data.rolesForCustomers,
    });
  }
  locationFormSubmit() {
    this.setState({
      locationActiveTabClass: "green-active active",
      rolesActiveTabClass: "yellow-active active",
    });
  }
  locationQuickSearch() {
    const userData = this.state.filterLocation ? this.state.filterLocation : "";
    let wordText = $("#ssearch")[0].value;
   // console.log(wordText);
    let filtData = userData.filter(function (value) {
      return (
        (value.legalId !== null &&
          value.legalId.includes(wordText)) || (value.orgName !== null &&
            value.orgName.toLowerCase().includes(wordText.toLowerCase()))
      );
    });

    //console.log("filtData",filtData)
    this.setState((prevState) => {
      let locList = Object.assign({}, prevState.filterLocation);
      locList = filtData;
      //console.log("locList",locList);
      this.setFilteredLocation(locList)
      return { locList };
    })

  }
  setFilteredLocation(locList) {
    //console.log("filteredData", locList)
    this.setState({ locationList: locList })
  }


  render() {
    let locationDataList = [];
    this.state.locationData.map((k, v) => (locationDataList[v] = k.customerID));
  //  console.log("locationData Add", locationDataList);
    const orgCustomesID=[]
        const locationData=[];
        this.state.orgItems.map((v,k)=>(
            orgCustomesID.push(v.legalId)
        ))
        this.state.filterLocation.map((v,k)=>(
            v.isAdmin?locationData.push(v):''

        ))
    return (
      <div>
        {/*<div className="header-rectangle">
                <div className="header-details padding-margin">
                    Add User
                </div>
    </div> */}
        <div class="container-fluid">
          <div class="ph-header-main__title row px-md-4 px-lg-3">
            <div class="col">
              <h1 class="p-title">{translationUtils.getTranslation(2219,"Add Location")}</h1>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div className="ph-header-main__breadcrumbs row px-md-4 px-lg-3 py-2 ">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item" aria-current="page">
                    <a href={window.location.origin+window.location.pathname.split('addLocations')[0]+'all'+window.location.search}>{  translationUtils.getTranslation(791, "Home")}</a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page"><Link to={"/" + this.props.lang + "/myparker/authenticatedUser?cid=" + this.props.cid}>{  translationUtils.getTranslation(2167, "Authorized Users")} </Link></li>
                  <li className="breadcrumb-item" aria-current="page"><Link to={"/" + this.props.lang + "/myparker/editUser?cid=" + this.props.cid}>{translationUtils.getTranslation(1167,"Edit") +" "+translationUtils.getTranslation(2185,"User")} </Link></li>
                  <li className="breadcrumb-item" aria-current="page">
                  {translationUtils.getTranslation(2219,"Add Location")}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <ul className="timeline nav" id="myTab">
                {/* <li className="green-active">  <a href="#user" data-toggle="tab"></a></li> */}
                <li className={this.state.locationActiveTabClass}>
                  <a href="#location" data-toggle="tab"></a>{" "}
                </li>
                <li className={"last " + this.state.rolesActiveTabClass}>
                  {" "}
                  <a href="#roles" data-toggle="tab"></a>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="tab-content" id="myTabContent">
                {/* <form className="tab-pane  active" id="user">
                            <div className="header-details">
                                1. User Details
                            </div>
                            <div className="item-spacing">
                                <label for="emailId">User ID/ Email</label>
                                <input type="text" id="emailID" name="emailId" placeholder="Email"  onChange={ (e) => this.handleEmailChange(e)}/>
                            </div>
                            <div className="item-spacing">
                                <label for="fname">First Name</label>
                                <input type="text" id="fname" name="firstname" placeholder="First Name" onChange={(e) => this.handleFNameChange(e)}/>
                            </div>
                            <div className="item-spacing">
                                <label for="lname">Last Name</label>
                                <input type="text" id="lname" name="lastname" placeholder="Last Name" onChange={(e) => this.handleLNameChange(e)}/>
                            </div>
                            <div className="flw btndiv">
                                <button type="submit" className="btn-import-user">Cancel</button>
                                <button type="submit" data-toggle="tab" data-target="#location" className="btn-add-user" onClick= { e => this.nextButtonHandle(e)} >Next</button>
                            </div>
                        </form>*/}
                <form className="tab-pane active " id="location">
                  <div className="header-details">1. {translationUtils.getTranslation(2220,"Add Location")}</div>
                  <div className="item-spacing">
                    <input
                      type="text"
                      id="ssearch"
                      name="emailId"
                      placeholder={translationUtils.getTranslation(2220,"Quick find")+" "+ translationUtils.getTranslation(386,"location")}
                      style={{ width: "40%" }}
                      onKeyUp={() => this.locationQuickSearch()}
                    />
                  </div>
                  <div className="table-wrapper xtraheight">
                    <table className="table table-striped">
                      <thead className="table-th-style">
                        <tr>
                          <th className="table-checkbox-style widt-10">
                            <span className="custom-checkbox">
                              <input type="checkbox" id="selectAll" checked={orgCustomesID.length==locationData.length} onClick={(e) => this.checkedLocation(this.state.locationList)} />
                              {/*<label for="selectAll"></label> */}
                            </span>
                          </th>
                          <th className="table-header-style widt-25">
                          {translationUtils.getTranslation(2175,"Location Number")}
                            {/* <i className="fa fa-sort" aria-hidden="true"></i> */}
                          </th>
                          <th className="table-header-style">
                          {translationUtils.getTranslation(2176,"Location Name")}
                            {/* <i className="fa fa-sort" aria-hidden="true"></i> */}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.locationList != null &&
                          this.state.locationList.map(
                            (orgItem, index) =>
                              orgItem.isAdmin &&
                              !locationDataList.includes(orgItem.legalId) && (
                                <tr>
                                  <td>
                                    <span className="custom-checkbox">
                                      <input
                                        type="checkbox"
                                        id={"checkbox" + index}
                                        name="options[]"
                                        value="1"
                                        checked={orgCustomesID.includes(orgItem.legalId)}
                                        onClick={(e) =>
                                          this.checkedLocation(orgItem)
                                        }
                                      />
                                      {/*<label for={"checkbox" + index}></label> */}
                                    </span>
                                  </td>
                                  <td>{orgItem.legalId}</td>
                                  <td>{orgItem.orgName}</td>
                                </tr>
                              )
                          )}
                      </tbody>
                    </table>
                  </div>
                  <div className="flw btndiv">
                    <Link
                      to={"/" + this.props.lang + "/myparker/editUser?cid=" + this.props.cid}
                      type="submit"
                      className="btn-import-user"
                    // data-toggle="tab"
                    // data-target="#user"
                    >
                    {translationUtils.getTranslation(547,"Back")}  
                    </Link>

                    {this.state.orgItems.length > 0 ?
                      <button type="submit" data-toggle="tab" data-target="#roles" className="btn-add-user" onClick={() => this.locationFormSubmit()}>{translationUtils.getTranslation(717,"Next")}</button> :
                      <button disabled={true} id="disabled-button" className="btn-add-user" onClick={() => this.locationFormSubmit()}> {translationUtils.getTranslation(717,"Next")}  </button>
                    }
                  </div>
                </form>
                <AssignRoles
                  orgList={this.state.orgItems}
                  userRolesData={this.state.userRolesData}
                  addUserInfo={this.state.addUserInfo}
                  getLoader={this.props.getLoader}
                  history={this.props.history}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddLocations;
