import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";

export function FilterSidebar(props){
    

  const tabs = props.filteTab;
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchWithinInputValue, setSearchWithinInputValue] = useState('');
  const [filterProductInputValue, setFilterProductInputValue] = useState('');
  const [filterDivisionInputValue, setFilterDivisionInputValue] = useState('');
  const [facetStateData, setFacetStateData] = useState([]);
  const [productFacetFilterlist, setProductFacetFilterlist] = useState([]);
  const [DivisionFacetFilterlist, setDivisionFacetFilterlist] = useState([]);
  const [materialFacetFilterlist, setMaterialFacetFilterlist] = useState([]);
  const [industryFacetFilterlist, setIndustryFacetFilterlist] = useState([]);
  const [brandFacetFilterlist, setBrandFacetFilterlist] = useState([]);
  const [categoryFacetFilterlist, setCategoryFacetFilterlist] = useState([]);
  const [isExpandedDivision, setIsExpandedDivision] = useState(false);
  const [checkedItem, setCheckedItem] = useState(null);
  const [divisionCheckedItem, setDivisionCheckedItem] = useState(null);
  const [materialCheckedItem, setMaterialCheckedItem] = useState(null);
  const [industryCheckedItem, setIndustryCheckedItem] = useState(null);
  const [brandCheckedItem, setBrandCheckedItem] = useState(null);
  const [categoryCheckedItem, setCategoryCheckedItem] = useState(null);
  const [supportFacetFilterList, setSupportFacetFilterList] = useState([]);
  const [resourceTypeFacetFilterList, setResourceTypeFacetFilterList] = useState([]);
  const [technologyFacetFilterlist, setTechnologyFacetFilterlist] = useState([]); 
  const [cADFacetFilterlist, setCADFacetFilterlist] = useState([]);
  const [fileTypeFacetFilterlist, setFileTypeFacetFilterlist] = useState([]);
  const [manufacturerFacetFilterlist, setManufacturerFacetFilterlist] = useState([]);
  const [crossrefTypeFacetFilterlist, setCrossrefTypeFacetFilterlist] = useState([]);
  
  const [selectedCatalog, setSelectedCatalog] = useState("General Catalog"); 

  const history = useHistory();
  const currentUrl = new URL(window.location.href);
  const urlParams = new URLSearchParams(currentUrl.search);

  const handleInputValue = (event) => {
    setSearchWithinInputValue(event.target.value);
  }

  const handleFilterByClearAll = (e) => {
    console.log('11111');
    onCheckBoxclick(e);
  }

  const handleCloseFilterSideBar = () => {
    props.closeFilterSideBar()
  }

  const handleCatalogChange = (event) => {
    console.log('event.target.value',event.target.value)
    setSelectedCatalog(event.target.value); 
    };  

  const updateSearchWithinResult = () => {
    
    let searchbox = urlParams.getAll('searchbox');
    urlParams.set("search_within", searchWithinInputValue);
    let fq_tag1 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:*';
    let pid = ""
    let fq_tag2 = ''
    if(tabs == "products"){
         pid = "p-939117180"
         fq_tag2 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;
        
    }else if(tabs == "support"){
        pid = "p-190113873"
        fq_tag2 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;
        
    }else{
        pid = "p-1526365397"
        fq_tag2 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;
        
    }
    if(searchWithinInputValue != "" && searchWithinInputValue != null){
        props.onfetchData(20, 0, fq_tag1, 'all', '(' +searchbox+ ') AND (' +searchWithinInputValue+ ')');
        props.onfetchData(20, 0, fq_tag2, tabs, '(' +searchbox+ ') AND (' +searchWithinInputValue+ ')');
    }else{
        props.onfetchData(20, 0, fq_tag1, 'all', searchbox);
        props.onfetchData(20, 0, fq_tag2, tabs, searchbox);
    }
    !searchWithinInputValue && setSearchWithinInputValue('')
    history.push(`${currentUrl.pathname}?${urlParams.toString()}`);
  }

  const onCheckBoxclick = (e) =>{
    const preValue = props.prevCheckedItem;
    console.log('divisionValue', preValue);
    let eValue = e.target.value;
    if(eValue){
        var dynamicPID = listInputValue(e.target.value);
    }

    let categoryVal = e.target.name;
    if (e.target.checked) {
        setCheckedItem(dynamicPID);
        setDivisionCheckedItem(dynamicPID)
        setMaterialCheckedItem(dynamicPID)
        setBrandCheckedItem(dynamicPID)
        setIndustryCheckedItem(dynamicPID)
        setCategoryCheckedItem(dynamicPID)
    } else {
        setCheckedItem(null);
        setDivisionCheckedItem(null);
        setMaterialCheckedItem(null)
        setBrandCheckedItem(null)
        setIndustryCheckedItem(null)
        setCategoryCheckedItem(null)
        // updateSearchWithinResult();
        // return;
    }
    const currentUrl = new URL(window.location.href);
    const urlParams = new URLSearchParams(currentUrl.search);
    let searchbox = urlParams.getAll('searchbox');
    let fq_tag1=  ''
    if(categoryVal === 'Division'){
         fq_tag1 = 'parkerDivisionName_fq:'+dynamicPID;
    }else if(categoryVal === 'Product') {
         fq_tag1 = '{!tag=tag_productEN_fq}productEN_fq:'+dynamicPID;
    }
    else if(categoryVal === 'Material') {
        fq_tag1 = '{!tag=tag_defAttDisplay_MaterialEN_fq}defAttDisplay_MaterialEN_fq:'+dynamicPID;
    }
    else if(categoryVal === 'Brand') {
        fq_tag1 = '{!tag=tag_defAttDisplay_BrandEN_fq}defAttDisplay_BrandEN_fq:'+dynamicPID;
    }
    else if(categoryVal === 'Industry') {
        fq_tag1 = '{!tag=tag_siteSectionEN_facet_fq}siteSectionEN_facet_fq:'+dynamicPID;
    }
    else if(categoryVal === 'Category') {
        fq_tag1 = '{!tag=tag_productTaxonomy_fq}productTaxonomy_fq:'+dynamicPID;
    }
    else if(categoryVal === 'Support') {
        fq_tag1 = '{!tag=tag_supportTypeEN_fq}supportTypeEN_fq:'+dynamicPID;
    }
    else if(categoryVal === 'ResourceType') {
        fq_tag1 = '{!tag=tag_supportResourceTypeEN_fq}supportResourceTypeEN_fq:'+dynamicPID;
    }
    else if(categoryVal === 'Technology') {
        fq_tag1 = '{!tag=tag_technologyEN_fq}technologyEN_fq:'+dynamicPID;
    }
    else if(categoryVal === 'CAD') {
        fq_tag1 = '{!tag=tag_cadType_fq}cadType_fq:'+dynamicPID;
    }
    else if(categoryVal === 'FileType') {
        fq_tag1 = '{!tag=tag_fileType_fq}fileType_fq:'+dynamicPID;
    }
    else if(categoryVal === 'LanguageName') {
        fq_tag1 = '{!tag=tag_languageName_fq}languageName_fq:'+dynamicPID;
    }
    else if(categoryVal === 'Manufacturer') {
        fq_tag1 = '{!tag=tag_xrefManufacturer_fq}xrefManufacturer_fq:'+dynamicPID;
    }
    else if(categoryVal === 'xrefType') {
        fq_tag1 = '{!tag=tag_xrefType_fq}xrefType_fq:'+dynamicPID;
    }
    
    props.onfetchData(20, 0, fq_tag1, 'all', searchbox);
    
    props.onfetchData(20, 0, fq_tag1, tabs, searchbox, true, dynamicPID);
  }

  const handleCheckboxKey = (key) => {
    let KeyData = Array.isArray(key)
    let label = ''
    if(KeyData) {
       let hasSep1 = key[0].includes('/$$$'),
            hasSep2 = key[0].includes('$$$');
 
        if (hasSep1) {
            label = key[0].split('/$$$')[1];
            // if(label.includes('0/')){
            //     label = label.split('0/')[1];
            // }
        } else if (hasSep2) {
            label = key[0].split('$$$')[0];
        }
    }

    const find = props?.prevCheckedItem?.find((val)=> val.includes(label))    
    //console.info("label check", label,props.prevCheckedItem, find)
    return find;
  }

  const listInputValue = (key) => {
    let KeyData = Array.isArray(key)
    let label = ''
    if(KeyData) {
       let hasSep1 = key[0].includes('/$$$'),
            hasSep2 = key[0].includes('$$$');
 
        if (hasSep1) {
            label = key[0].split('/$$$')[0];
            if(label.includes('0/')){
                label = label.split('0/')[1];
            }
        } else if (hasSep2) {
            label = key[0].split('$$$')[1];
        }
    }else {
        let hasSep1 = key.includes('/$$$'),
        hasSep2 = key.includes('$$$');
 
        if (hasSep1) {
            label = key.split('/$$$')[1];
            if(label.includes(',')){
                label = label.split(',')[0];
                
            }
        } else if (hasSep2) {
            label = key.split('$$$')[0];
        }
    }
    return label;
  }

  useEffect( () => {
   if(tabs == "support"){
    setSupportFacetFilterList(props.allFacetsData?.supportTypeEN_facet);
    setResourceTypeFacetFilterList(props.allFacetsData?.supportResourceTypeEN_facet);
    setIndustryFacetFilterlist(props.allFacetsData?.siteSectionEN_facet);
    setTechnologyFacetFilterlist(props.allFacetsData?.technologyEN_facet);
    setCADFacetFilterlist(props.allFacetsData?.cadType_facet);
    setFileTypeFacetFilterlist(props.allFacetsData?.fileType_facet);
   }else if(tabs == "products"){
    console.log('props.allFacetsData', props.allFacetsData);
    setFacetStateData(props.allFacetsData);
    setProductFacetFilterlist(props.allFacetsData?.productEN_facet);
    setDivisionFacetFilterlist(props.allFacetsData?.parkerDivisionName_facet);
    setMaterialFacetFilterlist(props.allFacetsData?.defAttDisplay_MaterialEN_facet);
    setIndustryFacetFilterlist(props.allFacetsData?.siteSectionEN_facet);
    setBrandFacetFilterlist(props.allFacetsData?.defAttDisplay_BrandEN_facet);
    setCategoryFacetFilterlist(props.allFacetsData?.productTaxonomy_facet);
   }else if (tabs == "xref"){
    setManufacturerFacetFilterlist(props.allFacetsData?.xrefManufacturer_facet);
    setCrossrefTypeFacetFilterlist(props.allFacetsData?.xrefType_facet);
    setDivisionFacetFilterlist(props.allFacetsData?.parkerDivisionName_facet);
   }

   let a = {fq: "1", fq: 2, fq:3}
   console.info(a,'console check 11111')
},[props.allFacetsData]);

console.info(props.allFacetsData,facetStateData, "console check")
// facetStateData && console.log("Object.keys(facetStateData)",Object.keys(facetStateData))
// if(facetStateData?.length > 0){
//     const { productEN_facet } = facetStateData
//     console.info("CHeckData", productEN_facet)
// }

// if(facetStateData && Object.keys(facetStateData)?.length > 0){
//  const { productEN_facet, parkerDivisionName_facet, defAttDisplay_MaterialEN_facet, siteSectionEN_facet, defAttDisplay_BrandEN_facet, productTaxonomy_facet } = facetStateData

//  console.info([...productEN_facet, ...parkerDivisionName_facet, ...defAttDisplay_MaterialEN_facet, ...siteSectionEN_facet, ...defAttDisplay_BrandEN_facet, ...productTaxonomy_facet], "Sanjay kumar  ")
// }
//   console.info(props.allFacetsData, "console check")
 const getSingleItemDetail = (key) => {
    const name = listInputValue(key)
    const id = handleCheckboxKey(key)

    return {
        name,
        id
    }
  }

// useEffect( () => {
//     console.log('545', props.allFacetsData?.supportTypeEN_facet)
//     if(props?.allFacetsData?.supportTypeEN_facet?.length>0){
//         setSupportFacetFilterList(props.allFacetsData?.supportTypeEN_facet);
//          setResourceTypeFacetFilterList(props.allFacetsData?.supportResourceTypeEN_facet)
//     }
     

//    },[props.allFacetsData?.supportTypeEN_facet]);
  
  useEffect(() => {
    console.log(filterProductInputValue)
    const filteredProducts = facetStateData?.productEN_facet?.filter((e) => 
        e[0].toLowerCase().includes(filterProductInputValue?.toLowerCase())
    ) || props.allFacetsData?.productEN_facet
    setProductFacetFilterlist(filteredProducts)
  },[filterProductInputValue])

  useEffect(() => {
    const filteredDivision = facetStateData?.parkerDivisionName_facet?.filter((e) => 
        e[0].toLowerCase().includes(filterDivisionInputValue?.toLowerCase())
    ) || props.allFacetsData?.parkerDivisionName_facet
    setDivisionFacetFilterlist(filteredDivision)
  },[filterDivisionInputValue])

    return (
        <div className="filter-area">
          {tabs == "support" && 
          <div className="main-filter-div">
        <div id="accordion">
            <div className="product-card">
                
                {props?.resultInfo?.length > 0 ?
                    <div className="sidehead-box"> Search in Results </div> : 
                    <div className="sidehead-box"> Results (0) </div>
                }
                <div className="card-header search-box" id="">
                <div class="srch-input"><input type="text" placeholder="Search" value={searchWithinInputValue} onChange={handleInputValue}/></div>
                <div className="srch-btn"><button class="btn" type="button" onClick={updateSearchWithinResult}><i class="fa fa-search"></i></button>
                </div>

                </div>
                <span className="break-line"><hr /></span>
            </div>
            {props?.resultInfo?.length > 0 &&
            <div>
            <div className="card mproduct-list product-card filter-results">
        
        <div className="sidehead-box filterresults-head-box"> Filter Results </div>
               <div className="card-header " id="">
                <ul className="radio_label filter-label">
                    <li>
                        {console.log('selectedCatalog', selectedCatalog === "General Catalog", selectedCatalog)}
                        <input type="radio" id="gen_catalog" name="catalog"  value="General Catalog" checked={true} defaultChecked={true} onChange={handleCatalogChange}/>
                        <label htmlFor="gen_catalog" className=""> General Catalog</label>
                    </li>
                    <li>
                        <input type="radio" id="gen_catalog" name="catalog" value="My Catalog" checked={selectedCatalog === "My Catalog"} onChange={handleCatalogChange}/>
                        <label htmlFor="my_catalog" className=""> My Catalog</label>
                    </li>
                 </ul>
                </div>
            </div>
            
           
            <div className="product-list product-card">
        <div class="sidebar-search-box-container">
       
           
        <div className="sidehead-box"> 
        <span className="filterby hide-mobile">Filter By</span>
        <span className="filterby hide-largescreen">Filters</span>
        <span className="filterby_clear_all hide-mobile" onClick={()=>handleFilterByClearAll}>Clear All</span> 
        </div>
            <div className="support-type">
                <details open>
                    <summary>Support Type</summary>
                    <ul>
                        {console.log('AllFacet', props.allFacetsData)}
                        {supportFacetFilterList?.length > 0 && supportFacetFilterList?.map( (item, index) => {
                            const isChecked = handleCheckboxKey(item);
                            return(<li class="text-sm filter-label" key={index}> <label class="flex items-center text-sm ">
                                <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Support' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label></li>)
                        })}
                        
                    </ul>
                </details>
            </div>
        
        {resourceTypeFacetFilterList &&<div class="card">
        
            <details>
                <summary>Resource Type</summary>
               {resourceTypeFacetFilterList && <ul>
                    {resourceTypeFacetFilterList?.map( (item, index) => {
                        const isChecked = handleCheckboxKey(item);
                        
                        return(isExpanded ?
                            <li class="text-sm filter-label"> <label class="flex items-center text-sm ">
                            <input type="checkbox" class="form-checkbox h-4 w-4" name = 'ResourceType' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label></li> 
                        : index < 4 && (
                        <li class="text-sm filter-label"> <label class="flex items-center text-sm ">
                            <input type="checkbox" class="form-checkbox h-4 w-4" name = 'ResourceType' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label></li> )
                    
                        )
                    })}
                    
                    
                </ul>}
                <div className="show-expanded">
                {resourceTypeFacetFilterList?.length >3 && <a className="expand-list" onClick={() => setIsExpanded(!isExpanded)}>

                {isExpanded ? <i class="fa fa-minus"></i> : <i class="fa fa-plus"></i>}

                </a>}
                {resourceTypeFacetFilterList?.length >3 && <a className="view-more-list" onClick={() => setIsExpanded(!isExpanded)}>

                {isExpanded ? 'View Less' : 'View More'}

                </a>}
                </div>
                
            </details>
                   
        </div>}

        {industryFacetFilterlist?.length > 0 && <div class="card">
           <details>
                <summary>Industry</summary>
                <ul>
                {industryFacetFilterlist && industryFacetFilterlist?.map( (item, index) => {
                    const isChecked = handleCheckboxKey(item);
                    return(<li class="text-sm filter-label"> <label class="flex items-center text-sm filter-label">
                            <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Industry' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label></li>)
                    })}  
                </ul>
            </details>
        </div>}
        {technologyFacetFilterlist?.length > 0 && <div class="card">
           <details>
                <summary>Technology</summary>
                <ul>
                    {technologyFacetFilterlist && technologyFacetFilterlist?.map( (item, index) => {
                    const isChecked = handleCheckboxKey(item);
                    return(<li class="text-sm filter-label"> <label class="flex items-center text-sm filter-label">
                            <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Technology' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label></li>)
                    })}
                    
                </ul>
            </details>
        </div>}
        {brandFacetFilterlist?.length > 0 && <div class="card">
           <details>
                <summary>Brand</summary>
                <ul>
                    {brandFacetFilterlist && brandFacetFilterlist?.map( (item, index) => {
                        const isChecked = handleCheckboxKey(item);
                    return(<li class="text-sm filter-label"> <label class="flex items-center text-sm filter-label">
                            <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Brand' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label></li>)
                    })}
                    
                </ul>
            </details>
        </div>}
        {DivisionFacetFilterlist?.length > 0 && <div class="card">
           <details>
                <summary>Division</summary>
                <ul>
                    {DivisionFacetFilterlist && DivisionFacetFilterlist.map( (item, index) => {
                        const isChecked = handleCheckboxKey(item);
                        return(<li class="text-sm filter-label"> 
                            <label class="flex items-center text-sm filter-label">
                                <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Division' checked={isChecked} value={item} onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label>
                        </li>)
                    })}
                    
                </ul>
            </details>
        </div>}
        {cADFacetFilterlist?.length > 0 && <div class="card">
           <details>
                <summary>CAD</summary>
                <ul>
                    {cADFacetFilterlist && cADFacetFilterlist.map( (item, index) => {
                        const isChecked = handleCheckboxKey(item);
                        return(<li class="text-sm filter-label"> 
                            <label class="flex items-center text-sm filter-label">
                                <input type="checkbox" class="form-checkbox h-4 w-4" name = 'CAD' checked={isChecked} value={item} onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label>
                        </li>)
                    })}
                    
                </ul>
            </details>
        </div>}
        {fileTypeFacetFilterlist?.length > 0 && <div class="card">
           <details>
                <summary>CAD</summary>
                <ul>
                    {fileTypeFacetFilterlist && fileTypeFacetFilterlist.map( (item, index) => {
                        const isChecked = handleCheckboxKey(item);
                        return(<li class="text-sm filter-label"> 
                            <label class="flex items-center text-sm filter-label">
                                <input type="checkbox" class="form-checkbox h-4 w-4" name = 'CAD' checked={isChecked} value={item} onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label>
                        </li>)
                    })}
                    
                </ul>
            </details>
        </div>}
        </div>
      </div>
      </div>
      }
      </div>
      <div className="bottom-btn">
                  <button className=" mob-btn-call btn" onClick={handleFilterByClearAll}>Clear All</button>
                  <button className="btn mob-btn-apply" onClick={handleCloseFilterSideBar}>Apply</button>
                </div>
      </div>
      }
         {tabs == "products" && 
          <div className="main-filter-div">
        <div id="accordion ">
        <div className="product-card">
           
        {props?.resultInfo?.length > 0 ?
            <div className="sidehead-box"> Search in Results </div> : 
            <div className="sidehead-box"> Results (0) </div>
        }
                
                {/* <div className="card-header" id="">
                <div className="ph-filter-group__search"><input type="text" placeholder="Search" value=""/></div> */}
                {/* <div class="text-box-area" >
                        <input type="text" placeholder="Search" value={searchWithinInputValue} onChange={handleInputValue} />
                        <button class="search_button" onClick={updateSearchWithinResult}><i class="fa fa-search"></i></button>
                </div> */}
                <div className="card-header search-box" id="">
                    <div class="srch-input"><input type="text" placeholder="Search" value={searchWithinInputValue} onChange={handleInputValue}/></div>
                    <div className="srch-btn"><button class="btn" type="button" onClick={updateSearchWithinResult}><i class="fa fa-search"></i></button>
                    </div>

                </div>
                <span className="break-line"><hr /></span>
                </div>
       
       
                {props?.resultInfo?.length > 0 &&
           <div className="product-list product-card">
        
        <div class=" sidebar-search-box-container">
            <div className="sidehead-box"> <span className="filterby hide-mobile">Filter By</span>
        <span className="filterby hide-largescreen">Filters</span><span className="filterby_clear_all hide-mobile" onClick={handleFilterByClearAll}>Clear All</span></div>
            <div className="support-type">
                <details open>
                    <summary>Product</summary>
                    {/* <div class="ph-filter-group__search"><input type="text" placeholder="Search" value=""/></div> */}
                    <div className="search-box-product" id="">
                        <div class="srch-input"><input type="text" placeholder="Search" value={filterProductInputValue} onChange={(e) => setFilterProductInputValue(e.target.value)}/></div>
                        <div className="srch-btn"><button class="btn" type="button" ><i class="fa fa-search"></i></button>
                        </div>
                    </div>
                    <ul>
                        {console.log('props.prevCheckedItem', props.prevCheckedItem)}
                        {productFacetFilterlist && productFacetFilterlist.map( (item, index) => {
                            const isChecked = handleCheckboxKey(item);
                                return( isExpanded ? (<li class="text-sm filter-label"> 
                                <label class="flex items-center text-sm filter-label">
                                    <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Product' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                                    <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                                </label>
                                </li>) : 
                                index < 4 && (<li class="text-sm filter-label"> 
                                    <label class="flex items-center text-sm filter-label">
                                        <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Product' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                                        <span class="ml-2">{listInputValue(item)+" (2)"}</span>
                                    </label>
                                </li>)
                            ) 
                        })}
                        
                    </ul>

                    <div className="show-expanded">
                    {productFacetFilterlist?.length >3 && <a className="expand-list" onClick={() => setIsExpanded(!isExpanded)}>

                        {isExpanded ? <i class="fa fa-minus"></i> : <i class="fa fa-plus"></i>}

                    </a>}
                    {productFacetFilterlist?.length >3 && <a className="view-more-list" onClick={() => setIsExpanded(!isExpanded)}>

                        {isExpanded ? 'View Less' : 'View More'}

                    </a>}
                        </div>
                 </details>
                 </div>
                
        
        
        { brandFacetFilterlist?.length > 0 && <div class="card ">
            <details>
                <summary>Material</summary>
                <ul>
                {materialFacetFilterlist && materialFacetFilterlist.map( (item, index) => {
                    const isChecked = handleCheckboxKey(item);
                    return(<li class="text-sm filter-label"> 
                    <label class="flex items-center text-sm filter-label">
                        <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Material' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                        <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                    </label>
                    </li>)
                })}
                    
                </ul>
            </details>

        </div>}
       
        { brandFacetFilterlist?.length > 0 && <div class="card">
         
           <details>
                <summary>Brand</summary>
                <ul>
                {console.log('setbrandFacetFilterlist', brandFacetFilterlist)}
                    {brandFacetFilterlist && brandFacetFilterlist.map( (item, index) => {
                        const isChecked = handleCheckboxKey(item);
                        return(<li class="text-sm filter-label"> 
                        <label class="flex items-center text-sm filter-label">
                            <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Brand' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label>
                        </li>)
                    })}
                </ul>
            </details>
        </div>}
        { categoryFacetFilterlist?.length > 0 && <div class="card">
       
           <details>
                <summary>Product Category</summary>
                <ul>
                {categoryFacetFilterlist && categoryFacetFilterlist.map( (item, index) => {
                     const isChecked = handleCheckboxKey(item);
                    return(<li class="text-sm filter-label"> 
                    <label class="flex items-center text-sm filter-label">
                        <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Category' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                        <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                    </label>
                    </li>)
                })}
                </ul>
            </details>
        </div>}
        
        { DivisionFacetFilterlist?.length > 0 && <div className="card">
            <details open>
                <summary>Division</summary>

                <div className="card-header search-box division-search" id="">
                        <div class="srch-input"><input type="text" placeholder="Search" value={filterDivisionInputValue} onChange={(e) => setFilterDivisionInputValue(e.target.value)}/></div>
                        <div className="srch-btn"><button class="btn" type="button" ><i class="fa fa-search"></i></button>
                        </div>
                    </div>

                <ul>
                    {DivisionFacetFilterlist && DivisionFacetFilterlist.map( (item, index) => {
                        const isChecked = handleCheckboxKey(item);
                        return( isExpandedDivision ? (<li class="text-sm filter-label"> 
                            <label class="flex items-center text-sm filter-label">
                                <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Division' checked={isChecked} value={item} onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label>
                        </li>) : 
                        index < 4 && (<li class="text-sm filter-label"> 
                            <label class="flex items-center text-sm filter-label">
                                <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Division' checked={isChecked} value={item} onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label>
                        </li>)
                        ) 
                    })}
                </ul>

                <div className="show-expanded">
        
                    {DivisionFacetFilterlist?.length > 3 && 
                    <a className="expand-list" onClick={() => setIsExpandedDivision(!isExpandedDivision)}>
                        {isExpandedDivision ? <i class="fa fa-minus"></i> : <i class="fa fa-plus"></i>}
                    </a>}
                    {DivisionFacetFilterlist?.length > 3 && <a className="view-more-list" onClick={() => setIsExpandedDivision(!isExpandedDivision)}>
                        {isExpandedDivision ? 'View Less' : 'View More'}
                    </a>}
                </div>
            </details>
        </div>}
                </div>
                </div>
}
                </div>
                <div className="bottom-btn">
                  <button className=" mob-btn-call btn" onClick={handleFilterByClearAll}>Clear All</button>
                  <button className="btn mob-btn-apply" onClick={handleCloseFilterSideBar}>Apply</button>
                </div>
     
                </div>
               
               
               
         }
         { tabs == "xref" && 
               <div className="main-filter-div">
               <div id="accordion">
                   <div className="product-card">
                   {props.resultInfo.length > 0 ?
                    <div className="sidehead-box"> Search in Results </div> : 
                    <div className="sidehead-box"> Results (0) </div> }
                   <div className="card-header search-box" id="">
                <div class="srch-input"><input type="text" placeholder="Search" value={searchWithinInputValue} onChange={handleInputValue}/></div>
                <div className="srch-btn"><button class="btn" type="button" onClick={updateSearchWithinResult}><i class="fa fa-search"></i></button>
                </div>

                </div>
                   </div>
                   {props.resultInfo.length > 0 &&
                   <div className="product-list product-card">
                   <div class=" sidebar-search-box-container">
              
                  
                   <div className="sidehead-box"> 
                   <span className="filterby hide-mobile">Filter By</span>
        <span className="filterby hide-largescreen">Filters</span><span className="filterby_clear_all hide-mobile" onClick={handleFilterByClearAll}>Clear All</span> </div>
        { manufacturerFacetFilterlist?.length > 0 && <div className="support-type">
            <details open>
                <summary>Manufacturer</summary>
                <ul>
                {manufacturerFacetFilterlist && manufacturerFacetFilterlist.map( (item, index) => {
                    const isChecked = handleCheckboxKey(item);
                    return(<li class="text-sm filter-label"> 
                    <label class="flex items-center text-sm filter-label">
                        <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Manufacturer' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                        <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                    </label>
                    </li>)
                })}
                    
                </ul>
             </details>
        </div>}
               
               
        { crossrefTypeFacetFilterlist?.length > 0 && <div class="card">
               
            <details>
                <summary>Type</summary>
                <ul>
                    {crossrefTypeFacetFilterlist && crossrefTypeFacetFilterlist.map( (item, index) => {
                    const isChecked = handleCheckboxKey(item);
                    return(isExpanded ? (<li class="text-sm filter-label"> 
                    <label class="flex items-center text-sm filter-label">
                        <input type="checkbox" class="form-checkbox h-4 w-4" name = 'xrefType' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                        <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                    </label>
                    </li>) 
                    : 
                    index < 4 && (<li class="text-sm filter-label"> 
                        <label class="flex items-center text-sm filter-label">
                            <input type="checkbox" class="form-checkbox h-4 w-4" name = 'xrefType' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label>
                        </li>))
                    })}
                </ul>
                <div className="show-expanded">
                {crossrefTypeFacetFilterlist?.length > 3 && 
                    <a className="expand-list" onClick={() => setIsExpandedDivision(!isExpandedDivision)}>
                        {isExpandedDivision ? <i class="fa fa-minus"></i> : <i class="fa fa-plus"></i>}
                    </a>}
                    {crossrefTypeFacetFilterlist?.length > 3 && <a className="view-more-list" onClick={() => setIsExpandedDivision(!isExpandedDivision)}>
                        {isExpandedDivision ? 'View Less' : 'View More'}
                    </a>}
                </div>
            </details> 
        </div>}
        { DivisionFacetFilterlist?.length > 0 && <div class="card">
             <details>
                <summary>Division</summary>
                <ul>
                {DivisionFacetFilterlist && DivisionFacetFilterlist.map( (item, index) => {
                    const isChecked = handleCheckboxKey(item);
                    return(<li class="text-sm filter-label"> 
                    <label class="flex items-center text-sm filter-label">
                        <input type="checkbox" class="form-checkbox h-4 w-4" name = 'Division' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                        <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                    </label>
                    </li>)
                })}
                </ul>
            </details>
        </div>}
             
             </div>
             </div>
            }
             </div>
             <div className="bottom-btn">
                         <button className=" mob-btn-call btn" onClick={handleFilterByClearAll}>Clear All</button>
                         <button className="btn mob-btn-apply" onClick={handleCloseFilterSideBar}>Apply</button>
                       </div>
             </div>
         }
      </div>

    )
}