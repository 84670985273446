import React, { Component } from 'react';
import $, { event, nodeName } from 'jquery';
import * as apis from '../../common-utils/api_urls'
import { Link } from 'react-router-dom';
import * as translationUtils from '../../common-utils/translation_utils'
import './applicationTiles.css'

/**
 * header class renders header
 */
class ApplicationTiles extends Component {
  constructor(props){
    super(props);
    this.state = {
      applicationTilesData:this.props.data['11_response'].docs,
      tilesToShow:this.props.data['11_response'].docs.length,
      totalTiles:this.props.data['11_response'].docs.length,
      tilesFlag: false,
      defaultTiles:this.props.data['11_response'].docs
      
    }
    //console.log("TotalTiles",this.state.totalTiles);
    //console.log("tilesToShow",this.state.tilesToShow);
    this.showLess = this.showLess.bind(this);
    this.showMore = this.showMore.bind(this);
    this.sorting=this.sorting.bind(this);
    this.defaulSort=this.defaulSort.bind(this);
  }
  
  showLess(){
    this.setState({tilesToShow:6});
  }
  showMore(){
    this.setState({tilesToShow:this.props.data['11_response'].docs.length});
  }
  
  sorting(event){
      let tiledata=this.state.applicationTilesData;
      //var tiles
      if(event.target.value==='option-0' && this.state.tilesFlag){
         
        this.defaulSort()
         //this.setState({defaultTiles: this.props.data['11_response'].docs, tilesFlag: true});
        // console.log("defaultTiles",this.state.defaultTiles);
        console.log("event",event.target.value);
      }
      
      if(event.target.value==='descending'){
      tiledata.sort((a,b) => b.titleEN_t.localeCompare(a.titleEN_t));
      } else if(event.target.value==='ascending'){
        tiledata.sort((a,b) => a.titleEN_t.localeCompare(b.titleEN_t));
      } else if(event.target.value==='option-0' && this.state.defaultTiles!=null){
        tiledata= this.state.defaultTiles;
        //console.log("default: "+JSON.stringify(this.props.data['11_response'].docs))
      }
      this.setState({applicationTilesData: tiledata, tilesFlag:true});
      console.log("tiledata",tiledata);

  }
  
  defaulSort(){
    this.props.defaultAppTile()
  }
  
    render(){
      
      let applicationtile=null;
      
      let title='title'+this.props.lang+'_t';
      if (this.props.data!==null && this.props.data !== undefined){
        applicationtile=this.state.applicationTilesData;
       
      // console.log(this.state.tilesToShow+" "+title+" "+applicationtile.length+" propSize="+this.props.data['11_response'].docs.length);
       
      }
        return (
          <React.Fragment>
      {
        (this.props.data!==null && this.props.data['11_response'].docs.length>0)?(
            <div id="mainDiv" style={{width: '100%'}}>
            {/* Application Section */}
            <link href={process.env.PUBLIC_URL+"/assets/css/apps.css"} rel="stylesheet" type="text/css" />
            <div className="mainPage">
              {/* My Apps Products */}
              <div className="col-xs-12 col-md-12 col-sm-12 secton-headings main-apps">
                <div className="col-xs-12 col-md-12 col-sm-12 padLM0 padR0 padL0">
                  <div className="sotbyalign">
                    <h1>{translationUtils.getTranslation(1915,"My Apps")}</h1>
                    <label className="Sort1">{translationUtils.getTranslation(243,"Sort by:")} </label>
                    <select className="sortby" onClick={this.sorting}>
                      <option value="option-0">{translationUtils.getTranslation(1972,"Default")} </option>
                      <option value="ascending">A-Z</option>
                      <option value="descending">Z-A</option>
                    </select>
                  </div>
                  <div className="apps-box">
                    <div className="row">
                      <div className="onlydm">
                      {applicationtile.slice(0,this.state.tilesToShow).map((tiles,index) =>
                        <div className="col-lg-2 col-md-2 col-sm-3 col-xs-12 app-tile" key={index}>
                          <div className="ribbon-menu">
                            <div className="listingbox border-grey">
                              <a href={tiles.appUrl_si+'?cid='+this.props.cid} target = "_blank" rel="noopener noreferrer">
                                <div className="app-box">
                                  <div className="image-box">
                                    <img alt="" src={'https://www.parker.com/parkerimages'+tiles.imageUrl_si} className="img-fluid" title={tiles[title]} />
                                  </div>
                                    <p className="text-desc">{tiles[title]}</p>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                        )}
     
                        
                      </div>
                      <div className="onlyipad">
                      {applicationtile.slice(0,this.state.tilesToShow).map((tiles,index) =>
                        <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12" key={index}>
                          <div className="ribbon-menu">
                            <div className="listingbox border-grey ipadbtn">  
                              <a href={tiles.appUrl_si+'?cid='+this.props.cid} target = "_blank" rel="noopener noreferrer">
                                <div className="app-box">
                                  <div className="image-box">
                                    <img alt="" src={'https://www.parker.com/parkerimages'+tiles.imageUrl_si} className="img-fluid" title={tiles[title]} />
                                  </div>
                                    <p className="text-desc">{tiles[title]}</p>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    
                      </div>
                      {this.state.tilesToShow>6 &&
                      <div className="col-xs-12 col-md-12 col-sm-12 text-center">
                        <button type="button" className="btn btn-brown showmore" onClick={this.showLess}><i className="fa fa-chevron-up" aria-hidden="true" />{translationUtils.getTranslation(1917,"Show Less Apps")}</button>
                      </div>
                      }
                      {(this.state.tilesToShow==6 && this.state.totalTiles>6) &&
                      <div className="col-xs-12 col-md-12 col-sm-12 text-center">
                        <button type="button" className="btn btn-brown showmore" onClick={this.showMore}><i className="fa fa-chevron-down" aria-hidden="true" />{translationUtils.getTranslation(1916,"Show More Apps")}</button>
                      </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <style dangerouslySetInnerHTML={{__html: "\n\t.events-heading{\n\t\tmargin-top: 0px !important;\n\t}\n\t.mail-details .email-to{\n\t\tcursor: pointer;\n\t}\n\t.telephone-number{\n\t\tcursor: pointer;\n\t}\n\t@media (min-width: 768px){\n\t\t.mail-details p{\n\t\t\tmargin-bottom:7px;\n\t\t}\n\t}\n" }} />
            {/* Contact Us */}
            {/* Mobile View */}
            <div className="col-xs-12 col-md-12 col-sm-12 secton-headings mobile-display contact-section">
              <h1>Contact Us</h1>
              <div className="col-xs-12 pad0 <!--marT10--> account-manager-details-mobile">
                <div className="panel-group" id="accordion6">
                  <div className="panel panel-default">
                    <div className="panel-heading announcements">
                      <h4 className="panel-title">
                        <a className="accordion-toggle collapsed" data-toggle="collapse" href="https://www.parker.com/portal/site/MyParker/menuitem.37eb03f20c5d959b1f7c238a4256d1ca/?vgnextoid=59519942ad8bf510VgnVCM10000025651dacRCRD&vgnextfmt=EN&cid=4000000000000061639#collapse6" data-parent="#accordion5" aria-expanded="false">
                          <span className="panelTitle-text">{translationUtils.getTranslation(1709,"Your Account Manager Details")}</span>
                          <span className="arrowUpDown" />
                        </a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <span className="go-to-top-arrow noneDisplay"><a href="#  "><img title="Go Up" alt="Go Up" src="./MyParker_files/go-up.png" /></a></span>
            </div>
            {/* Tablet View */}
            <div className="col-sm-12 col-md-12 contactus-container secton-headings ipad-display contact-section-ipad">
              <h1 className="sections-heading container">{translationUtils.getTranslation(127,"Contact Us")}</h1>
              <div className="col-sm-12 contact-us">
                {/*
	  <div class="col-sm-12 announcement-list">
		 <div class="col-sm-12  pad0 scrollbar" id="announcement-list">
			<div class="col-sm-6 padL0">
			   <div class="">
				  <div class="contact-us-div-border">
					 <div class="contact-heading">
						<h2 class="events-heading">Americas <span class="aircraft-span">(USA, Canada, Mexico)</span></h2>
					 </div>
					 <div class="contact-parker-div-container">
						<div class="contact-parker-div">
						   <div class="col-md-2 col-sm-2 col-lg-2"><img title="Mail" alt="Mail Icon" src="/parker/myparker/assets/images/mail.png"></div>
						   <div class="col-md-10 col-sm-10 col-lg-10 email-text">c-parker@parker.com</div>
						</div>
						<div class="contact-parker-div brdr-top">
						   <div class="col-md-2 col-sm-2 col-lg-2"><img title="Phone" alt="Phone Icon" src="/parker/myparker/assets/images/Call.png"></div>
						   <div class="col-md-10 col-sm-10 col-lg-10 email-text">1-800-272-1920</div>
						</div>
					 </div>
				  </div>
			   </div>
			</div>
			<div class="col-sm-6 padL0">
			   <div class="">
				  <div class="contact-us-div-border">
					 <div class="contact-heading">
						<h2 class="events-heading">Europe </h2>
					 </div>
					 <div class="contact-parker-div-container">
						<div class="contact-parker-div">
						   <div class="col-md-2 col-sm-2 col-lg-2"><img title="Mail" alt="Mail Icon" src="/parker/myparker/assets/images/mail.png"></div>
						   <div class="col-md-10 col-sm-10 col-lg-10 email-text">c-parker@parker.com</div>
						</div>
						<div class="contact-parker-div brdr-top">
						   <div class="col-md-2 col-sm-2 col-lg-2"><img title="Phone" alt="Phone Icon" src="/parker/myparker/assets/images/Call.png"></div>
						   <div class="col-md-10 col-sm-10 col-lg-10 email-text">1-800-272-1920</div>
						</div>
					 </div>
				  </div>
			   </div>
			</div>
			<div class="col-sm-12 pad0 marT10">
			   <div class="col-sm-6 padL0">
				  <div class="">
					 <div class="contact-us-div-border">
						<div class="contact-heading">
						   <h2 class="events-heading">AOG <span class="aircraft-span">(Aircraft on Ground)</span></h2>
						</div>
						<div class="contact-parker-div-container">
						   <div class="contact-parker-div">
							  <div class="col-md-2 col-sm-2 col-lg-2"><img title="Mail" alt="Mail Icon" src="/parker/myparker/assets/images/mail.png"></div>
							  <div class="col-md-10 col-sm-10 col-lg-10 email-text">c-parker@parker.com</div>
						   </div>
						   <div class="contact-parker-div brdr-top">
							  <div class="col-md-2 col-sm-2 col-lg-2"><img title="Phone" alt="Phone Icon" src="/parker/myparker/assets/images/Call.png"></div>
							  <div class="col-md-10 col-sm-10 col-lg-10 email-text">1-800-272-1920</div>
						   </div>
						</div>
					 </div>
				  </div>
			   </div>
			</div>
		 </div>
	  </div>
	  */}
                <div className="col-md-12 col-xs-12 manager-details-container">
                  <div className="col-md-4 col-sm-2 col-lg-4 padL0">
                    {/*<div class="col-md-12 pad0">
			   <h2>&nbsp;</h2>
			</div>*/}
                    <div className="col-md-12 pad0 text-center">
                      <h2>{translationUtils.getTranslation(1709,"Your Account Manager Details")}</h2>
                    </div>
                  </div>
                </div>
                <div className="email-parker-button">
                  <button type="button" className="btn btn-brown callToActionLink linkEventTag" data-name="Contact Parker" data-url="/portal/site/PARKER/menuitem.e2f2dfced453a3eb30291f89237ad1ca/?vgnextoid=ef73ec35df463110VgnVCM100000c9040d0aRCRD&vgnextchannel=ef73ec35df463110VgnVCM100000c9040d0aRCRD&vgnextfmt=EN">
                    Contact Parker</button>
                </div>
                <span className="go-to-top-arrow noneDisplay"><a href="# "><img src="./MyParker_files/go-up.png" title="Go Up" alt="Go Up" /></a></span>
                <div className="info_section_container col-sm-12 ">
                  <div className="info_section clearfix follow-us follow-us-div-container" id="follow">
                    <div className="row">
                      <div className="col-lg-12 col-xs-12 col-sm-12 follow-us-div ">
                        <div className="row">
                          <div className="col-lg-3 col-xs-12 col-sm-3 follow-us-div sectons-heading">
                            <h1 className="sections-heading follow-us-div-heading">Follow Us</h1>
                          </div>
                          <div className="col-lg-9 col-xs-12 col-sm-9 follow-us-div follow-us-icons-div">
                            <div className="row">
                              <div className="col-lg-5 col-xs-12 col-sm-7 follow-us-div-heading follow-us-text"> <span>Follow Parker Hannifin on social media:</span> </div>
                              <div className="col-lg-7 col-xs-12 col-sm-5 follow-us-div">
                                <ul className="socialSection">
                                  <li> <a href="https://www.parker.com/portal/site/MyParker/menuitem.37eb03f20c5d959b1f7c238a4256d1ca/?vgnextoid=59519942ad8bf510VgnVCM10000025651dacRCRD&vgnextfmt=EN&cid=4000000000000061639#" title="Follow Parker Hannifin on social media"><img src="./MyParker_files/social.png" alt="Follow Parker Hannifin on social media" title="Follow Parker Hannifin on social media" /></a> </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Desktop View */}
            <div className="col-sm-12 col-md-12 pad0 contactus-container secton-headings desktop-display ipad-hidden" id="contactus-container">
              <h1 className="sections-heading container">Contact Us</h1>
              <div className="col-md-12 col-xs-12 contact-us">
                {/*
	  <div class="col-md-4 col-sm-4 col-lg-4 padL0">
		 <div class="contact-us-div-border">
			<div class="contact-heading">
			   <h2 class="events-heading">Americas <span class="aircraft-span">(USA, Canada, Mexico)</span></h2>
			</div>
			<div class="contact-parker-div-container">
			   <div class="contact-parker-div">
				  <div class="col-md-2 col-sm-2 col-lg-2"><img title="Mail" alt="Mail Image" src="/parker/myparker/assets/images/mail.png"></div>
				  <div class="col-md-10 col-sm-10 col-lg-10 email-text">c-parker@parker.com</div>
			   </div>
			   <div class="contact-parker-div brdr-top">
				  <div class="col-md-2 col-sm-2 col-lg-2"><img title="Phone" alt="Phone Icon" src="/parker/myparker/assets/images/Call.png"></div>
				  <div class="col-md-10 col-sm-10 col-lg-10 email-text">1-800-272-1920</div>
			   </div>
			</div>
		 </div>
	  </div>
	  <div class="col-md-4 col-sm-4 col-lg-4">
		 <div class="contact-us-div-border">
			<div class="contact-heading">
			   <h2 class="events-heading">Europe </h2>
			</div>
			<div class="contact-parker-div-container">
			   <div class="contact-parker-div">
				  <div class="col-md-2 col-sm-2 col-lg-2"><img title="Mail" alt="Mail Icon" src="/parker/myparker/assets/images/mail.png"></div>
				  <div class="col-md-10 col-sm-10 col-lg-10 email-text">c-parker@parker.com</div>
			   </div>
			   <div class="contact-parker-div brdr-top">
				  <div class="col-md-2 col-sm-2 col-lg-2"><img title="Phone" alt="Phone Icon" src="/parker/myparker/assets/images/Call.png"></div>
				  <div class="col-md-10 col-sm-10 col-lg-10 email-text">1-800-272-1920</div>
			   </div>
			</div>
		 </div>
	  </div>
	  <div class="col-md-4 col-sm-4 col-lg-4 padR0">
		 <div class="contact-us-div-border">
			<div class="contact-heading">
			   <h2 class="events-heading">AOG <span class="aircraft-span">(Aircraft on Ground)</span></h2>
			</div>
			<div class="contact-parker-div-container">
			   <div class="contact-parker-div">
				  <div class="col-md-2 col-sm-2 col-lg-2"><img title="Mail" alt="Mail Icon" src="/parker/myparker/assets/images/mail.png"></div>
				  <div class="col-md-10 col-sm-10 col-lg-10 email-text">c-parker@parker.com</div>
			   </div>
			   <div class="contact-parker-div brdr-top">
				  <div class="col-md-2 col-sm-2 col-lg-2"><img title="Phone" alt="Phone Icon" src="/parker/myparker/assets/images/Call.png"></div>
				  <div class="col-md-10 col-sm-10 col-lg-10 email-text">1-800-272-1920</div>
			   </div>
			</div>
		 </div>
	  </div>
	  */}
                {/* Desktop View Account Detail */}
                <div className="col-md-12 col-xs-12 manager-details-container">
                  <div className="col-md-4 col-sm-4 col-lg-4 padL0 manager-contacts" style={{paddingLeft: '5% !important', paddingTop: '0%'}}>
                    {/*<div class="col-md-12 pad0">
			   <h2 title="Header">&nbsp;</h2>
			</div>
			<div class="col-md-12 pad0 text-center">
			   <h2>YOUR ACCOUNT MANAGER DETAILS</h2>
			</div>*/}
                    <h2>{translationUtils.getTranslation(1709,"Your Account Manager Details")}</h2>
                  </div>
                </div>
                <div className="email-parker-button">
                  <button type="button" className="btn btn-brown callToActionLink linkEventTag" data-name="Contact Parker" data-url="/portal/site/PARKER/menuitem.e2f2dfced453a3eb30291f89237ad1ca/?vgnextoid=ef73ec35df463110VgnVCM100000c9040d0aRCRD&vgnextchannel=ef73ec35df463110VgnVCM100000c9040d0aRCRD&vgnextfmt=EN">
                  {translationUtils.getTranslation(1028,"Contact Parker")}</button>
                </div>
              </div>
              <span className="go-to-top-arrow noneDisplay"><a href="# "><img title="Go up" alt="Go up Icon" src="./MyParker_files/go-up.png" /></a></span>
              <div className="info_section_container">
                <div className="info_section clearfix container follow-us follow-us-div-container" id="follow">
                  <div className="row">
                    <div className="col-lg-12 col-xs-12 col-sm-12 follow-us-div ">
                      <div className="row">
                        <div className="col-lg-3 col-xs-12 col-sm-3 follow-us-div sectons-heading">
                          <h1 className="sections-heading follow-us-div-heading">{translationUtils.getTranslation(1435,"Follow Us")}</h1>
                        </div>
                        <div className="col-lg-9 col-xs-12 col-sm-9 follow-us-div follow-us-icons-div">
                          <div className="row">
                            <div className="col-lg-5 col-xs-12 col-sm-5 follow-us-div-heading follow-us-text"> <span>{translationUtils.getTranslation(1434,"Follow Parker Hannifin on social media:")}</span> </div>
                            <div className="col-lg-7 col-xs-12 col-sm-7 follow-us-div">
                              <ul className="socialSection">
                                <li> <a href="https://www.parker.com/portal/site/MyParker/menuitem.37eb03f20c5d959b1f7c238a4256d1ca/?vgnextoid=59519942ad8bf510VgnVCM10000025651dacRCRD&vgnextfmt=EN&cid=4000000000000061639#" title="Follow Parker Hannifin on social media"><img src="./MyParker_files/social.png" alt="Follow Parker Hannifin on social media" title="Follow Parker Hannifin on social media" /></a> </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <style dangerouslySetInnerHTML={{__html: "\n\n.contactus-container , .contact-section{\n\tdisplay:none !important;\n}\n" }} />
          </div>):(<div id="mainDiv" style={{width: '100%'}}></div>)
      }
      </React.Fragment>
        );
    }
}
export default ApplicationTiles;