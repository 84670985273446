import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { editRolesApiUrl } from '../../common-utils/api_urls';
import { saveRemoveLocation } from '../../services/getUserDetail';
import PopUpComponent from './PopUpComponent';

import * as translationUtils from '../../common-utils/translation_utils';
class SuccessUserPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRole: {
        displayName: null,
        roleId: null,
        roleName: null,
        message: '',
        status: '',
      },
    };
    // console.log("OpenState",this.props.open)
  }
  updateIsModalOpen() {
    this.props.assignModal();
  }
  render() {
    //console.log("Property Detail", window.location)
    if (this.props.message != '' && this.props.message != null)
      return (
        <div id="success_popup">
          <div
            className={
              this.props.open ? 'ph-modal fade show in' : 'ph-modal fade'
            }
            id="editMultipleLocation"
            tabindex="-1"
            role="dialog"
            aria-labelledby="Reset Password Modal"
            style={{ display: this.props.open ? 'block' : 'none' }}
          >
            <div className="modal-dialog" role="document">
              <div className="ph-modal__content ph-modal__content__wider">
                <div
                  className="d-flex justify-content-end m-0 p-0"
                  onClick={() => this.updateIsModalOpen()}
                >
                  <span
                    data-dismiss="modal"
                    aria-label="Close"
                    className="ph-modal__header--close ph-modal-header-close-font-size"
                    aria-hidden="true"
                  >
                    <i className="fas fa-times"></i>
                  </span>
                </div>
                {this.props.status == 'Success' && (
                  <div className="ph-modal__header">
                    <i
                      class="fas fa-check-circle"
                      style={{
                        fontSize: '38px',
                        color: '#e9a100',
                        margin: '0 auto',
                      }}
                    ></i>
                  </div>
                )}
                <div className="ph-modal__body">
                  <h4
                    className="ph-modal__title"
                    id="resetpasswordModalLabel"
                    style={{ textAlign: 'center' }}
                  >
                    {this.props.message}
                  </h4>
                </div>
                {
                  //this.props.status == "Success" &&
                  <div className="ph-modal__footer">
                    <Link
                      to={
                        '/EN/myparker/authenticatedUser' +
                        window.location.search
                      }
                      type="button"
                      className="button primary-button round-button medium-button ml-1 cround"
                    >
                      {translationUtils.getTranslation(
                        2191,
                        'Return to User List'
                      )}
                    </Link>
                    &nbsp;&nbsp;
                    {window.location.pathname.includes('/addLocations') ? (
                      <a
                        href={
                          window.location.origin +
                          '/EN/myparker/editUser' +
                          window.location.search
                        }
                        type="button"
                        className="button primary-button round-button medium-button ml-1 cround"
                      >
                        {translationUtils.getTranslation(
                          2215,
                          'Add Another User'
                        )}
                      </a>
                    ) : (
                      <a
                        href={
                          window.location.origin +
                          '/EN/myparker/addUser' +
                          window.location.search
                        }
                        type="button"
                        className="button primary-button round-button medium-button ml-1 cround"
                      >
                        {translationUtils.getTranslation(
                          2215,
                          'Add Another User'
                        )}
                      </a>
                    )}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      );
    else return <></>;
  }
}

export default SuccessUserPopup;
