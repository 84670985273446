import React, {useState, useEffect} from "react";
import CrossReference from "./CrossReference";
// import { FilterSidebar } from "./FilterSidebar";
import { FilterSidebar } from "./FilterSidebar";
import { NoResultPage } from "./NoResultPage";
import { Tab } from "react-bootstrap";

export default function Results(props){
   //const tabs = props.filteTab;
   var isProduct = props.tabResult;
   let isResult = props.resultInfo;
   let myposts = props.resultInfo;
   
   const urlParams = new URLSearchParams(window.location.search);
   const searchbox = urlParams.getAll('searchbox');
   const parentdiv = document.getElementById('body-overlay');

   const searchWithIn = urlParams.getAll('search_within');


   const [sidepanelfour, setSidePanelFour] = useState(false)
   //const [clearAll, setClearAll] = useState(false)

   // const [productLink, setProductLink] = useState("");


function convertDate(dateString){
      //const dateString = "2020-06-18T10:57:15.077Z";

// Create a Date object from the string
const date = new Date(dateString);

// Get the day, month, and year
const day = date.getUTCDate();
const month = date.toLocaleString('default', { month: 'short' }); 
const year = date.getUTCFullYear();

// Format the date string
const formattedDate = `${day} ${month} ${year}`;

return formattedDate;

  }

 function getProductLink(item){
   let partType = item.siteSubSection_ss;
   if(partType != ""){
      partType = Array.isArray(partType) ?  partType[0] : partType;
   }
   let supportType = item.supportType_ss;
   if(supportType != ""){
      supportType = Array.isArray(supportType)  ?  supportType[0] : supportType;
   }
   let siteSection =  item.siteSection_ss;

   if(siteSection != ""){
      siteSection = Array.isArray(siteSection) ?  siteSection[0] : siteSection;
   }
  
   let fileType =  item.fileType_ss;

   if(fileType != ""){
      fileType = Array.isArray(fileType) ?  fileType[0] : fileType;
   }
            //let supportType = props.resultInfo.supportType_ss?props.resultInfo.supportType_ss[0]: '';
            // if(partType === 'Part' || supportType=== 'CAD'){
            //     let url = `https://`+`phtest`+`.parker.com/`+`us`+`/en/${psURLKeyword_s}/${urlKeyword_s}`
            // return url;
            //    }
            let contentSource = item.contentSource_s;
            let url;
               if(partType === 'Part' ||  supportType=== 'CAD'){

               url =  undefined || ' ' !== item.urlKeyword_s 
                          ? `https://`+`phtest`+`.parker.com/`+`us`+`/en/${item.psURLKeyword_s}/${item.urlKeyword_s}`
                          : `https://`+`phtest`+`.parker.com/`+`us`+`/en/${item.urlKeyword_s}`;
              } else if(partType === 'Product Series'){
               url = `https://`+`phtest`+`.parker.com/`+`us`+`/en/product-list/${item.urlKeyword_s}`;

              } else if(contentSource === 'Tacton'){
               url = `https://econfig.parker.com.parker.com/?session=${item.urlKeyword_s}&lang=en&countryID=687PDC}`;
              } else if( contentSource ===  'AEM-Support'){
               // if( item.audience_ss == "private" && item.otAssetType_s == "Static File"){

               //    url = getPrivateAssetLink(item.id);
               // }
                 if(fileType === "PDF" ||  fileType === "MP4" || fileType === "MOV"){
                     url = `https://`+`test`+`.parker.com${item.urlKeyword_s}`;
                 }else if ( fileType === "ZIP"){
                  url = `https://`+`test`+`.parker.com/${item.urlKeyword_s}`;
                 }else if(fileType == 'URL' ){
                  fileType = 'ZIP';
                  url = `https://`+`test`+`.parker.com/${item.urlKeyword_s}`;
                 } else {

                  url = item.urlKeyword_s;
                 }
                 
              } else if( contentSource ===  'Analytics'){
               url = `https://`+`phtest`+`.parker.com/`+`us`+`/en/${item.urlKeyword_s}`;
              } else if(  contentSource ===  'WebSphere'){
                  if(partType === 'Part' ||  supportType === 'CAD'){

                  url =  undefined || ' ' !== item.urlKeyword_s 
                         ? `https://`+`phtest`+`.parker.com/`+`us`+`/en/${item.psURLKeyword_s}/${item.urlKeyword_s}`
                         : `https://`+`phtest`+`.parker.com/`+`us`+`/en/${item.urlKeyword_s}`;
                  }else if(partType === 'Product Category'){
                     url = `https://`+`phtest`+`.parker.com/`+`us`+`/en/category/${item.urlKeyword_s}`;
                  }else if(partType === 'Product Series'){
                     url = `https://`+`phtest`+`.parker.com/`+`us`+`/en/series/${item.urlKeyword_s}`;
                  }else if(supportType === "Configurator"){
                     url = item.configURL_s;
                   }else{
                        url = `https://`+`phtest`+`.parker.com/`+`us`+`/en/${item.urlKeyword_s}`;
                     }
            }else if(contentSource == "WebCrawl-IS" && siteSection === "Support" ){
               url =item.urlKeyword_s;
              }
            
            else{
               url = `https://`+`phtest`+`.parker.com/`+`us`+`/en/${item.urlKeyword_s}`;

               console.log("urls",url)
              }
              return url;
 }

//  async function getPrivateAssetLink(assetId){
//    // return a private asset link
//    let distId = sessionStorage.getItem("distributorId");
//    try {
//       const response1 = await fetch("https://apidev.parker.com/dev/support/myparker-asset-authorizer/parker/OpenContent?id=14bcbdbaa9b05810VgnVCM100000e6651dacRCRD&distributorId=A62985&subscription-key=9a7b6552198d41fb9b000548826f59fb", {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           'ocp-apim-subscription-key': '9a7b6552198d41fb9b000548826f59fb'  
//                }
//       }
//     );

//   const jsonData1 = await response1.json();
  
//   setAuthId(jsonData1.AuthId[0]);
//   setCustomerId(jsonData1.CustomerId);
//     } catch (error) {
      
//       console.error("Error fetching data:", error);
//       setAuthId(`1-9R05F|1-9R05G|1-9R05J|1-9R05L|1-9R06A`);
//     } 

//  }

  function truncateDesription(str, maxLength) {
   if (str.length <= maxLength) {
     return str;
   }
   return str.substring(0, maxLength - 3) + "...";
 }

 const clearSelectedcheckedItem = (item, preValve) => {

      let fq_tag1 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:*';
      props.onfetchData(20, 0, fq_tag1, "all", searchbox);
   
   props.onfetchData(20, 0, item, isProduct, searchbox, true);
 }

 const setClearAll = () => {
   
      let searchbox = urlParams.getAll('searchbox');
      
      let fq_tag1 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:*';
      let pid = ""
      let fq_tag2 = ''
      if(isProduct == "products"){
           pid = "p-939117180"
           fq_tag2 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;
          
      }else if(isProduct == "support"){
          pid = "p-190113873"
          fq_tag2 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;
          
      }else{
          pid = "p-1526365397"
          fq_tag2 = '{!tag=tag_siteSectionEN_fq}siteSectionEN_fq:'+pid;
          
      }
         props.clearFilter();
         props.onfetchData(20, 0, fq_tag1, 'all', searchbox);
         props.onfetchData(20, 0, fq_tag2, isProduct, searchbox);
    
 }

 function openNav(e) {
   
      
      //document.getElementById('body-overlay').style.display = "block";

   if(e.currentTarget.id == "mypanelSupport"){
     
      document.getElementById('body-overlay1').style.display = "block";
     
   }
   if(e.currentTarget.id == "mypanelProducts"){
     
      document.getElementById('body-overlay').style.display = "block";
   }
   if(e.currentTarget.id == "mypanelXref"){
      document.getElementById('body-overlay2').style.display = "block";
   }

   setSidePanelFour(true);

   
 }

 const handleCheckboxKey = (key) => {
   let KeyData = Array.isArray(key)
   let label = ''
   if(KeyData) {
      let hasSep1 = key[0].includes('/$$$'),
           hasSep2 = key[0].includes('$$$');

       if (hasSep1) {
           label = key[0].split('/$$$')[1];
           // if(label.includes('0/')){
           //     label = label.split('0/')[1];
           // }
       } else if (hasSep2) {
           label = key[0].split('$$$')[0];
       }
   }

   const find = props?.prevCheckedItem?.find((val)=> val.includes(label))    
   //console.info("label check", label,props.prevCheckedItem, find)
   return find;
 }

 const listInputValue = (key) => {
   let KeyData = Array.isArray(key)
   let label = ''
   if(KeyData) {
      let hasSep1 = key[0].includes('/$$$'),
           hasSep2 = key[0].includes('$$$');

       if (hasSep1) {
           label = key[0].split('/$$$')[0];
           if(label.includes('0/')){
               label = label.split('0/')[1];
           }
       } else if (hasSep2) {
           label = key[0].split('$$$')[1];
       }
   }else {
       let hasSep1 = key.includes('/$$$'),
       hasSep22 = key.includes('$$$');

       if (hasSep1) {
           label = key.split('/$$$')[1];
           if(label.includes(',')){
               label = label.split(',')[0];
           }
       } else if (hasSep22) {
           label = key.split('$$$')[0];
       }
   }
   //console.log('labelSupport', label);
   return label;
 }


 const getSingleItemDetail = (key) => {
   const name = listInputValue(key)
   const id = handleCheckboxKey(key)
   //console.info("getSingleItemDetail", key,"---------", name,"++++++++++++++++++++++++++++++++++++", id)

   return {
       name,
       id
   }
 }
 
 function closeNav(e) {

    document.getElementById('body-overlay').style.display = "none";
     
   
      document.getElementById('body-overlay1').style.display = "none";
   
   
      document.getElementById('body-overlay2').style.display = "none";
   
   setSidePanelFour(false);
}
 function getProductLabel(entry) {
   if(entry.siteSubSection_ss == "Part"){
      return "View Product";
   } else if(entry.siteSubSection_ss == "Product Series"){
      if (entry.hasConfig_s === 'Y') return 'Configure';
      else if (entry.partCount_i === 0 && entry.hasConfig_s === 'N' ) return `${entry.partCount_i} ${"Produucts"}`;
      else {
         if(entry.partCount_i == 1){
            return entry.partCount_i + " Product";
         } else{
            return entry.partCount_i + " Products";
         }
      }
   } else if( entry.siteSubSection_ss == "Product Category"){
      return "View Category";
   }
}

    return(
      <div>
      {/* {sidepanelfour &&
      <div id="body-overlay"></div>
      } */}
      
      
      {isProduct == 'products' && 
      <div>
            {/* <div className="filter-list hide-mobile  show-1024">

               <span>Filters:</span>  
               {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
                  return props?.allFacetsData[keyValue]?.map((data) => {
                     if(props.prevCheckedItem?.indexOf(getSingleItemDetail(data).id) !== -1){
                        return <button className="button round-button outline-button my-0 py-1">
                     {getSingleItemDetail(data).name}<i className="fa fa-close" ></i></button>
                     } else {
                        return <></>
                     }
                  }
                  )
               }) : <></>}
               
               <button className="button round-button outline-button my-0 py-1" >Clear all <i className="fa fa-close" ></i></button>
            </div> */}
         <div id="body-overlay"></div>
         
             <div class="row products-lists">
             <div class=" col-xl-3 ph-search-sidebar hide-mobile">

             <FilterSidebar filteTab="products" onfetchData = {props.onfetchData} 
             resultAPIData = {myposts} 
             allFacetsData = {props.allFacetsData} 
             resultInfo={props.resultInfo} 
             closeFilterSideBar={closeNav}
             prevCheckedItem = {props.prevCheckedItem}/>
             </div>
             {props?.resultInfo?.length > 0 ? (
               
            <div className="col-md-12 col-lg-12 col-xl-9 results">
            {sidepanelfour && 
            <div id="mySidepanel" className="sidepanel">
     
     <div className=" top-filter-bar">
     <div className="filters_search">Filters & Search</div>
    
<div><a href="javascript:void(0)" className="closebtn " onClick={closeNav}>×</a></div>
<span className="break-line"><hr /></span>
</div>

<FilterSidebar filteTab="products" onfetchData = {props.onfetchData} 
resultAPIData = {myposts} 
allFacetsData = {props.allFacetsData} 
resultInfo={props.resultInfo} 
closeFilterSideBar={closeNav}
prevCheckedItem = {props.prevCheckedItem}/>

</div>
}

{/* <div className="filter-list hide-mobile  show-1024">

      
<span>Filters:</span>  <button className="button round-button outline-button my-0 py-1" >Clear all <i className="fa fa-close" ></i></button>
</div> */}
<div className="mob-all-filter">
      <hr />
         <button class="openbtn btn" id="mypanelProducts" onClick={(e)=>openNav(e)}><span className="all-filter-img"><img alt="no image" src={process.env.PUBLIC_URL +
            '/assets/img/Shape Copy@3x.png'
         } /></span><span>All Filters</span></button>
      <hr />
   </div>
   {props?.resultInfo?.length > 0 &&  (
   <div>
      <div className="filter-list hide-mobile  show-1024">
         <span>Filters:</span>  
         {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
               return props?.allFacetsData[keyValue]?.map((data) => {
                  const SingleItemDetail = getSingleItemDetail(data)
                  if(props.prevCheckedItem?.indexOf(SingleItemDetail.id) !== -1){
                     
                     return SingleItemDetail.id.includes("p-939117180") 
                     || SingleItemDetail.id.includes("p-190113873")
                     || SingleItemDetail.id.includes("p-1526365397")
                     || SingleItemDetail.name === " " ? <></> : <button className="button round-button outline-button my-0 py-1">
                     {SingleItemDetail.name}<i className="fa fa-close" onClick={() => clearSelectedcheckedItem(SingleItemDetail.id, props.prevCheckedItem)}></i></button>
                  } else {
                     return <></>
                  }
               }
               )
            }) : <></>}
             <button className="button round-button outline-button my-0 py-1"  onClick={ setClearAll}>Clear all <i className="fa fa-close" ></i></button>
      </div>
   
      <div className="hide-largescreen">
         <hr />
            <div className="filter-list">
               <span>Filters:</span> 
               {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
               return props?.allFacetsData[keyValue]?.map((data) => {
                  const SingleItemDetail = getSingleItemDetail(data)
                  if(props.prevCheckedItem?.indexOf(SingleItemDetail.id) !== -1){
                     return SingleItemDetail.id.includes("p-939117180") 
                     || SingleItemDetail.id.includes("p-190113873")
                     || SingleItemDetail.id.includes("p-1526365397")
                     || SingleItemDetail.name === " " ? <></> : <button className="button round-button outline-button my-0 py-1" >
                     {SingleItemDetail.name}<i className="fa fa-close" onClick={() => clearSelectedcheckedItem(SingleItemDetail.id, props.prevCheckedItem)}></i></button>
                  } else {
                     return <></>
                  }
               }
               )
            }) : <></>}
             <button className="button round-button outline-button my-0 py-1"  onClick={ setClearAll}>Clear11 all <i className="fa fa-close" ></i></button>
            </div>
         <hr />
      </div>  
   </div>
)} 
         <div className="srp-area">
        
            
         <div>
        <div className=" showing-top-results">
         {searchWithIn.length > 0 ? <h4>Showing top results for: {searchbox} {searchWithIn &&  searchWithIn[0]!== '' && <span>for "{searchWithIn}"</span>}</h4> 
         : <h4>Showing top results for: {searchbox}</h4>}
           
        </div>
        <hr/>
        </div>
       
        
        <div class="row">
    
        <div className="clearfix ph-search-contents__results">
        { myposts.length > 0 && myposts.map((item, index) => {
return (
           <div className="ph-browse-list-view__product">
            <a href={getProductLink(item)}  className="a-no-underline default">
                 <div className="ph-browse-list-view__content">
                    <div className="ph-browse-list-view__left">
                       <div className="ph-browse-list-view__left--img-container"><img alt="no image" src={ item.thumbnailURL_s ? item.thumbnailURL_s :
                                        process.env.PUBLIC_URL +
                                        '/assets/img//Rectangle Copy.png'
                                      } title="Category | Valves" /></div>
                       <div className="ph-browse-list-view__left--container">
                          <h2 className="ph-browse-list-view__left--title">{item.siteSubSection_ss == "Product Category" ? "Category | "+ item.titleEN_t : item.titleEN_t}
                          {item._parkerexactmatch ? <span class="ph-flag ph-flag__noteworthy exact-match">Exact Match</span> : ''}
                          </h2>
                          <div className="ph-browse-list-view__left--short-desc js-browse-list__truncate "><span><div dangerouslySetInnerHTML={{ __html: item.descriptionEN_t }}></div></span></div>
                       </div>
                    </div>
                    <div className="ph-browse-list-view__right">
                    <div className="ph-browse-list-view__right--literature entry">{getProductLabel(item)}<i className="far fa-chevron-right" aria-hidden="true"></i></div>
                    
                    </div>
                 </div>
              </a>
           </div>
)}
        )}
       
         </div>
        </div>
        </div>
        </div>
      
          ) : 
          <div className="col-md-12 col-lg-12 col-xl-9 results">
          <NoResultPage />
          </div>
          }
       </div>
       </div>
                                    }
      

      {isProduct == 'support' && 
      <div>
   <div id="body-overlay1"></div>
   <div className="row">
  
      <div className="col-xl-3 ph-search-sidebar hide-mobile">
     
       <FilterSidebar filteTab="support" onfetchData = {props.onfetchData} 
             resultAPIData = {myposts} 
             allFacetsData = {props.allFacetsData} 
             resultInfo={props.resultInfo} 
             closeFilterSideBar={closeNav}
             prevCheckedItem = {props.prevCheckedItem}/>
      </div>
   <div className="col-md-12 col-lg-12 col-xl-9 results">
   
   {sidepanelfour &&    <div id="mySidepanel1" className="sidepanel">
     
     <div className=" top-filter-bar">
     <div className="filters_search">Filters & Search</div>
    
<div><a href="javascript:void(0)" className="closebtn " onClick={closeNav}>×</a></div>
<span className="break-line"><hr /></span>
</div>
  
  <FilterSidebar filteTab="support" onfetchData = {props.onfetchData} 
             resultAPIData = {myposts} 
             allFacetsData = {props.allFacetsData} 
             resultInfo={props.resultInfo} 
             closeFilterSideBar={closeNav}
             prevCheckedItem = {props.prevCheckedItem}/>
</div>
}
<div className="mob-all-filter">
   <hr />
   <button class="openbtn btn" id="mypanelSupport" onClick={(e)=>openNav(e)}><span className="all-filter-img"><img alt="no image" src={process.env.PUBLIC_URL +
                                        '/assets/img/Shape Copy@3x.png'
                                      } /></span><span>All Filters</span></button>
                                      <hr />
                                      </div>
{props?.resultInfo?.length > 0 && (
   <div>
      <div className="filter-list hide-mobile  show-1024">
         <span>Filters:</span>  
         {console.log('props.allFacetsDataObject', props.allFacetsData)}
         {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
               return props?.allFacetsData[keyValue]?.map((data) => {
                  const SingleItemDetail = getSingleItemDetail(data)
                  if(props.prevCheckedItem?.indexOf(SingleItemDetail.id) !== -1){
                     return SingleItemDetail.id.includes("p-939117180") 
                     || SingleItemDetail.id.includes("p-190113873")
                     || SingleItemDetail.id.includes("p-1526365397")
                     || SingleItemDetail.name === " " ? <></> : <button className="button round-button outline-button my-0 py-1">
                  {SingleItemDetail.name}<i className="fa fa-close" onClick={ () => clearSelectedcheckedItem(SingleItemDetail.id)}></i></button>
                  } else {
                     return <></>
                  }
               }
               )
            }) : <></>}
             <button className="button round-button outline-button my-0 py-1" onClick={ setClearAll}>Clear all <i className="fa fa-close" ></i></button>
         </div>
   
      <div className="hide-largescreen">
         <hr />
            <div className="filter-list">
               <span>Filters:</span>  
               {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
               return props?.allFacetsData[keyValue]?.map((data) => {
                  const SingleItemDetail = getSingleItemDetail(data)
                     if(props.prevCheckedItem?.indexOf(SingleItemDetail.id) !== -1){
                        return SingleItemDetail.id.includes("p-939117180") 
                        || SingleItemDetail.id.includes("p-190113873")
                        || SingleItemDetail.id.includes("p-1526365397")
                        || SingleItemDetail.name === " " ? <></> : <button className="button round-button outline-button my-0 py-1">
                     {SingleItemDetail.name}<i className="fa fa-close" onClick={ () => clearSelectedcheckedItem(SingleItemDetail.id)}></i></button>
                     } else {
                        return <></>
                     }
                  }
                  )
               }) : <></>}
             <button className="button round-button outline-button my-0 py-1" onClick={setClearAll}>Clear all <i className="fa fa-close" ></i></button>
               </div>
         <hr />
      </div>  
   </div>
)} 
   {props?.resultInfo?.length > 0 ? (   
   <div className="srp-area">
   {props?.resultInfo?.length > 0 && (
      <div>
      <div className="srp-header">
        <div className=" showing-top-results">
           <h4>Showing top results for: {searchbox}</h4>
        </div>
        <div className="sortby">
        <h4>Sort By:</h4> 
        <select className="form-select form-select-sm" onChange={(e) => props.sortBy(e.target.value)}>
     <option value="">Default</option>
     <option value="title_s asc">A-Z</option>
     <option value="title_s desc">Z-A</option>
     <option value="publishedCiDate_dt desc">Newest-Oldest</option>
     <option value="publishedCiDate_dt asc">Oldest-Newest</option>
   </select>
 
   
   </div>
   </div>
   <hr />
   </div>
   
   )}
   
   <div className="clearfix ph-search-contents__results support-list">
    {/* <span class="ph-flag ph-flag__noteworthy exact-match">Exact Match</span> */}
    { myposts.length > 0 ? myposts.map((item, index) => {
return (
   
      <div key={index} className="ph-browse-list-view__product">
      
       

<a href={getProductLink(item)} className="a-no-underline default">
           
            <div className="ph-browse-list-view__content">
               <div className="ph-browse-list-view__left">
                  
                  <div className="ph-browse-list-view__left--img-container">
                     <img alt="no image" src={ item.imageURL_s ? item.imageURL_s :
                                        process.env.PUBLIC_URL +
                                        '/assets/img//email.png'
                                      } title="SBW110 Mobile Valve | Mobile Hydraulic Systems Division Europe"/>
                                                                             
       {item.fileType_ss ? 
      <div className="ph-browse-list-view__left--files">
                        
                        <div>
         <a className="links"><span className="link-label"><button>.{item.fileType_ss}</button></span></a>
         </div>
         </div>
          : ''}
                                      </div>
                                     
                  <div className="ph-browse-list-view__left--container support-content-left">
                     <p>{item.supportTypeEN_ss}</p>
                     <h2 className="ph-browse-list-view__left--title">{item.titleEN_t}
                     {item._parkerexactmatch ? <span class="ph-flag ph-flag__noteworthy exact-match">Exact Match</span> : ''}
                     
                     </h2>
                     <div className="createdate_publishdate">
                     {item.createCiDate_dt ? 
                     <div><span><b>Created Date:</b></span><span>{convertDate(item.createCiDate_dt)}</span></div> : ''}
                     {item.publishedCiDate_dt ? <div><span><b>Publish Date:</b></span><span>{convertDate(item.publishedCiDate_dt)}</span></div> : ''}
                     
                     </div>
                   
         </div>
         </div>
         <div className="ph-browse-list-view__right support-content-right">
            
         <div className="support-email ph-browse-list-view__right--literature entry">
            <div className="description">
             
           
            {item.descriptionEN_t && 
               <p>{ truncateDesription(item.descriptionEN_t.toString(), 100) }</p>
            }
      {item.parkerDivisionName_ss && 
            <div>{ truncateDesription(item.parkerDivisionName_ss.toString(), 45) }</div>
      }
      {item.supportResourceType_ss && 
            <div>{ truncateDesription(item.supportResourceType_ss.toString(), 45) }</div>
      }
            </div>
            <div className="result-email-btn">
            <a href="mailto:support@parker.com" className="button round-button outline-button my-0 py-1" style={{background:"none"}}><span>Email</span></a></div>
            </div>
         </div>
         
         </div>
         </a>
      </div>
  )}) : <NoResultPage />}
  
    {/* <div className="ph-browse-list-view__product">
         <a href="https://phtest.parker.com/us/en/sporlan-normally-closed-solenoid-valve-with-check-valve" className="a-no-underline default">
           
            <div className="ph-browse-list-view__content">
               <div className="ph-browse-list-view__left">
                  <div className="ph-browse-list-view__left--img-container">
                     <img alt="no image" src="./src/containers/search/assets/image/Rectangle Copy.png" title="SBW110 Mobile Valve | Mobile Hydraulic Systems Division Europe"/></div>
                  <div className="ph-browse-list-view__left--container">
                     <p>Distributor Support</p>
                     <h2 className="ph-browse-list-view__left--title">Video</h2>
                     <div className="createdate_publishdate"><div><span><b>Created Date:</b></span><span>16 OCt</span></div><div>Publish Date:<span>16 Oct</span></div></div>
                     <div className="ph-browse-list-view__left--files">
                        <div>
         <a className="links"><span className="link-label"><button>.zip</button></span><i className="far fa-arrow-to-bottom" aria-hidden="true"></i></a></div></div></div></div>
         <div className="ph-browse-list-view__right">
            
         <div className="support-email ph-browse-list-view__right--literature entry">
            <div className="description">
            <p className="">
            In English</p>
            <h4>Corporate</h4>
            <h4>Customer sales</h4>
            </div>
            <button className="button round-button outline-button my-0 py-1" style={{background:"none"}}><span>Email</span></button></div>
         </div>
         
         </div>
         </a>
      </div> */}
         
      </div>
      </div>
   ) : <NoResultPage /> }
      </div>
         
   </div>
   </div>
   }
   {isProduct == 'xref' && 
      <div>
         <div id="body-overlay2"></div>
         <div class="row">
         <div class=" col-xl-3 ph-search-sidebar hide-mobile">
         <FilterSidebar filteTab="xref" onfetchData = {props.onfetchData} 
             resultAPIData = {myposts} 
             allFacetsData = {props.allFacetsData} 
             resultInfo={props.resultInfo} 
             closeFilterSideBar={closeNav}
             prevCheckedItem = {props.prevCheckedItem}/>
             </div>
         
      
   <div className="col-md-12 col-lg-12 col-xl-9 results cross-ref">
   {sidepanelfour && 
   <div id="mySidepanel2" className="sidepanel">
     
     <div className=" top-filter-bar">
     <div className="filters_search">Filters & Search</div>
    
<div><a href="javascript:void(0)" className="closebtn " onClick={closeNav}>×</a></div>
<span className="break-line"><hr /></span>
</div>

<FilterSidebar filteTab="xref" onfetchData = {props.onfetchData} 
             resultAPIData = {myposts} 
             allFacetsData = {props.allFacetsData} 
             resultInfo={props.resultInfo} 
             closeFilterSideBar={closeNav}
             prevCheckedItem = {props.prevCheckedItem}/>
</div>
}
   <div className="mob-all-filter">
      <hr />
         <button class="openbtn btn" id="mypanelXref" onClick={(e)=>openNav(e)}><span className="all-filter-img"><img alt="no image" src={process.env.PUBLIC_URL +
            '/assets/img/Shape Copy@3x.png'
         } /></span><span>All Filters</span></button>
      <hr />
   
   </div>
   {props?.resultInfo?.length > 0 && (
   <div>
      <div className="filter-list hide-mobile  show-1024">
         <span>Filters:</span>  
         {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
               return props?.allFacetsData[keyValue]?.map((data) => {
                  const SingleItemDetail = getSingleItemDetail(data)
                  if(props.prevCheckedItem?.indexOf(SingleItemDetail.id) !== -1){
                     return SingleItemDetail.id.includes("p-939117180") 
                     || SingleItemDetail.id.includes("p-190113873")
                     || SingleItemDetail.id.includes("p-1526365397")
                     || SingleItemDetail.name === " " ? <></> : <button className="button round-button outline-button my-0 py-1">
                  {SingleItemDetail.name}<i className="fa fa-close" onClick={() => clearSelectedcheckedItem(SingleItemDetail.id)}></i></button>
                  } else {
                     return <></>
                  }
               }
               )
            }) : <></>}
             <button className="button round-button outline-button my-0 py-1" onClick={setClearAll}>Clear all <i className="fa fa-close" ></i></button>
         </div>
   
      <div className="hide-largescreen">
         <hr />
            <div className="filter-list">
               <span>Filters:</span>  
               {props.allFacetsData ? Object.keys(props.allFacetsData).map((keyValue) => {
               return props?.allFacetsData[keyValue]?.map((data) => {
                     const SingleItemDetail = getSingleItemDetail(data)
                     if(props.prevCheckedItem?.indexOf(SingleItemDetail.id) !== -1){
                        return SingleItemDetail.id.includes("p-939117180") 
                        || SingleItemDetail.id.includes("p-190113873")
                        || SingleItemDetail.id.includes("p-1526365397")
                        || SingleItemDetail.name === " " ? <></> : <button className="button round-button outline-button my-0 py-1" >
                     {SingleItemDetail.name}<i className="fa fa-close" onClick={() => clearSelectedcheckedItem(SingleItemDetail.id)}></i></button>
                     } else {
                        return <></>
                     }
                  }
                  )
               }) : <></>}
             <button className="button round-button outline-button my-0 py-1"  onClick={setClearAll}>Clear all <i className="fa fa-close" ></i></button>
               </div>
         <hr />
      </div>  
   </div>
)} 
   {/* <div className="srp-area">  */}
      {/* <div className={props.resultInfo.length > 0 ? "srp-area" : ""}> */}
      {props?.resultInfo?.length > 0 &&
         <div className="ph-search-selected__query showing-top-results">
            <h4>Showing top results for: {searchbox}</h4>
         </div> 
   }
   {/* <hr/> */}
   <div className="clearfix ph-search-contents__results cross-ref">
      <CrossReference crossrefResults={props.resultInfo} />

   {/* </div> */}
      </div>
      </div>
      </div>
      </div>
   }
</div>

    )
}