import React, { Component } from 'react';

import * as translationUtils from '../../common-utils/translation_utils';

class RemoveMultipleLocationModel extends Component {
  constructor(props) {
    super(props);
  }
  updateIsModalOpen(flag) {
    this.props.assignModal(flag);
  }
  removeLocation(e) {
    this.props.chnageClass(e);
    this.updateIsModalOpen(false);
  }
  render() {
    return (
      <div
        className={this.props.open ? 'ph-modal fade show in' : 'ph-modal fade'}
        id="removeMultipleLocation"
        tabindex="-1"
        role="dialog"
        aria-labelledby="Remove Multiple Location Modal"
        style={{ display: this.props.open ? 'block' : 'none' }}
      >
        <div className="modal-dialog" role="document">
          <div className="ph-modal__content ph-modal_remove">
            <div
              className="d-flex justify-content-end m-0 p-0"
              onClick={() => this.updateIsModalOpen(false)}
            >
              <span
                data-dismiss="modal"
                aria-label="Close"
                className="ph-modal__header--close ph-modal-header-close-font-size"
                aria-hidden="true"
              >
                <i className="fas fa-times close-icon"></i>
              </span>
            </div>
            <div className="ph-modal__header modal-heder">
              <h3
                className="ph-modal__title modal-title"
                id="resetpasswordModalLabel"
                style={{ textAlign: 'center' }}
              >
                {' '}
                {'Remove Locations'}
              </h3>
            </div>
            <div className="ph-modal__body">
              <h4
                className="modal-text"
                id="resetpasswordModalLabel"
                style={{ textAlign: 'center' }}
              >
                {'Are you sure you want to remove these locations?'}
              </h4>
            </div>
            {
              //this.props.status == "Success" &&
              <div className="ph-modal__footer modal-footer">
                <a
                  type="button"
                  className="button primary-button btn-secondary-medium-default round-button medium-button ml-1 cround btn_remove_cancel"
                  onClick={() => this.updateIsModalOpen(false)}
                >
                  {'Cancel'}
                </a>
                &nbsp;&nbsp;
                <a
                  type="button"
                  id={this.props.elementId}
                  className="button primary-button btn-secondary-medium-default round-button medium-button ml-1 cround multipleLocationInactive btn_multi_inactive"
                  onClick={(e) => this.removeLocation(e)}
                >
                  {'Remove'}
                </a>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default RemoveMultipleLocationModel;
