import axios from 'axios'


export default function getAuth(callingClass, apiUrl){
  
    axios(
        apiUrl,{
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
        //'Ocp-Apim-Subscription-Key': '1560822963354a4888aa2dd3dc5bec99'
      }      
    })
    .then(
          (result)=>{
            let response = result.data;
            
            callingClass.setAuth(response);
          }).catch(
            (error)=>{
              console.log(error);
            }
          );
        
        }