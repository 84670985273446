import React,{Component} from "react";
import ReactDOM from "react-dom";



class footer extends Component {

  render() {
    return (
      <footer id="footer" className="ph-footer">
      </footer>
    );
  }
}

export default footer;