import React, { Component } from "react";
import "./header.css";

class Header extends Component {
  render() {  
    return ( 
      <header id="header" className="ph-header-main"> 
       </header>
       
    );
  }
}
export default Header;
