import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as translationUtils from '../../common-utils/translation_utils';

class RemoveLocationModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRole: {
        displayName: null,
        roleId: null,
        roleName: null,
        message: '',
        status: '',
      },
    };
    // console.log("OpenState",this.props.open)
  }
  updateIsModalOpen(flag) {
    this.props.assignModal(flag);
  }
  removeLocation(e) {
    this.props.chnageClass(e);
    this.updateIsModalOpen(false);
  }
  render() {
    return (
      <div id="success_popup rem_loc">
        <div
          className={
            this.props.open ? 'ph-modal fade show in' : 'ph-modal fade'
          }
          id="editMultipleLocation"
          tabindex="-1"
          role="dialog"
          aria-labelledby="Reset Password Modal"
          style={{ display: this.props.open ? 'block' : 'none' }}
        >
          <div className="modal-dialog" role="document">
            <div className="ph-modal__content ph-modal__content__wider">
              <div
                className="d-flex justify-content-end m-0 p-0"
                onClick={() => this.updateIsModalOpen(false)}
              >
                <span
                  data-dismiss="modal"
                  aria-label="Close"
                  className="ph-modal__header--close ph-modal-header-close-font-size"
                  aria-hidden="true"
                >
                  <i className="fas fa-times"></i>
                </span>
              </div>

              <div className="ph-modal__body">
                <h4
                  className="modal-text"
                  id="resetpasswordModalLabel"
                  style={{ textAlign: 'center' }}
                >
                  {'Are you sure you want to remove this location?'}
                </h4>
              </div>
              {
                //this.props.status == "Success" &&
                <div className="ph-modal__footer">
                  <a
                    type="button"
                    className="button primary-button round-button medium-button ml-1 cround"
                    onClick={() => this.updateIsModalOpen(false)}
                  >
                    {translationUtils.getTranslation(139, 'No')}
                  </a>
                  &nbsp;&nbsp;
                  <a
                    type="button"
                    id={this.props.elementId}
                    className="button primary-button round-button medium-button ml-1 cround remove"
                    onClick={(e) => this.removeLocation(e)}
                  >
                    {translationUtils.getTranslation(137, 'Yes')}
                  </a>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default RemoveLocationModel;
