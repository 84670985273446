import React, {useState} from "react";
import * as translationUtils from '../../../common-utils/translation_utils';
 
export const NoResultPage = (props) => {
    //const { translate, getLangCode } = useTranslations();
    const urlParams = new URLSearchParams(window.location.search);
    const searchbox = urlParams.getAll('searchbox');
    const searchWithIn = urlParams.getAll('search_within');
    
    return (
        <div className = "ph-results_noResultPage">
            <div className="ph-results__not-found-text">
                <p>
                    {translationUtils.getTranslation(952, "We're sorry, but we couldn't find any results for") || "We're sorry, but we couldn't find any results for"}
                    &nbsp;
                    {searchWithIn.length > 0 ? <strong><p>"{searchbox} for {searchWithIn}"</p></strong> 
                    : <strong><p>"{searchbox}"</p></strong>}
                    
                </p>
                <p>{translationUtils.getTranslation(1059, 'Please search on another term or browse for an item') || 'Please search on another term or browse for an item'}</p>
                {/* <p>
                    <strong>Additional Resources:</strong>
                </p> */}
            </div>
        </div>
    )
}