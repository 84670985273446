import React, { Component } from 'react';
import axios from 'axios';
import * as apis from '../../common-utils/api_urls';

import { getHeaderSubKeyForApplicationRole } from "../../apiconfig";
import {
  addUserApi,
  changeUserStatusApi,
  getAssignRolesApi,
  getEditUserDetails,
} from '../../common-utils/api_urls';
import getUserDetail, {
  ActivateUser,
  AssignRolesData,
  AddUserDetail,
} from '../../services/getUserDetail';
import './assets/css/custom-styles.css';
import $ from 'jquery';
import AssignRoles from './AssignRoles';
import { Link } from 'react-router-dom';
import * as translationUtils from '../../common-utils/translation_utils';
const orgItemList = [];
class Adduser extends Component {
  $ = window.jQuery;

  constructor(props) {
    super(props);
    this.state = {
      locationList: this.props.locationList,
      filterLocation: this.props.locationList,
      orgItems: [],
      userRolesData: null,
      addUserInfo: { logonId: null, firstName: null, lastName: null },
      fnameErrMsg: '',
      lnameErrMsg: '',
      emailErrMsg: '',
      isValidForm: false,
      activeTabClass: 'yellow-active active',
      locationActiveTabClass: '',
      rolesActiveTabClass: '',
      validFname: false,
      validLname: false,
      validEmail: false,
      isAllSelected: false,
    };

    //this.checkedLocation=this.checkedLocation.bind(this);
    this.removeListElement = this.removeListElement.bind(this);
    this.addListElement = this.addListElement.bind(this);
    this.setFilteredLocation = this.setFilteredLocation.bind(this);
  }

  async getApplicationBasedRolesCallBackForAddUser(emailid) {

    let data = '';
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url:
        apis.getRolesBasedAccess() +
        emailid,
      headers: {
        'ocp-apim-subscription-key': getHeaderSubKeyForApplicationRole(),
        'Content-Type': 'application/json',
      },
      data: data,
    };
    return await axios
      .request(config)
      .then((response) => {
          if (response.data !== null && response.data !== undefined) {
              return response.data;
         }

      })
      .catch((error) => {
        console.log(error);
      });
    // let arr =
    //   data != null &&
    //   data.map(
    //     (orgItem, index) =>
    //       orgItem.WebResourceID + '-' + orgItem.RecordStatusCode
    //   );
        
    // let evntArr = arr[0].split('-');
    
    // let WebResourceIDVal = evntArr[0];
    // let RecordStatusCode = evntArr[1];
    // console.log("RecordStatusCode==",RecordStatusCode)
    // this.setState({
    //   applicationbasedlocationData: data,
    //   recordStatusCodeArr: arr,
    //   isChecked: RecordStatusCode === 'A' ? true : false,
    // });
  }
  async nextButtonHandle(e) {
    //console.log("inside nextButtonHandle" + this.state.validEmail)
    if (!this.state.validEmail) {
      let err = (
        <strong style={{ color: '#FF0000' }}>
          {translationUtils.getTranslation(2205, 'Please Enter Valid Email')}
        </strong>
      );
      this.setState({ emailErrMsg: err });

    } else {
      const email = this.state.addUserInfo.logonId;
      const result = await this.getApplicationBasedRolesCallBackForAddUser(email);
      this.setState({newUserInfo: result});
      sessionStorage.setItem("newUserInfo",JSON.stringify(result));
    }
    if (!this.state.validLname) {
      let err = (
        <strong style={{ color: '#FF0000' }}>
          {translationUtils.getTranslation(2206, 'Please Enter Last name')}
        </strong>
      );
      this.setState({ lnameErrMsg: err });
    }
    if (!this.state.validFname) {
      let err = (
        <strong style={{ color: '#FF0000' }}>
          {translationUtils.getTranslation(2207, 'Please Enter First name')}
        </strong>
      );
      this.setState({ fnameErrMsg: err });
    }
    if (
      this.state.validFname == true &&
      this.state.validLname == true &&
      this.state.validEmail == true
    ) {
      this.setState({
        activeTabClass: 'green-active active',
        locationActiveTabClass: 'yellow-active active',
      });
    }
  }
  removeListElement(list = [], element) {
    let newList = [];
    let j = 0;
    for (let i = 0; i < list.length; i++) {
      if (i == list.indexOf(element)) {
        continue;
      }
      newList[j++] = list[i];
    }
    return newList;
  }
  addListElement(list = [], element) {
    list.push(element);
    let newList = list;

    return newList;
  }
  setLocationOnBack(LocItems) {
    //console.log("item", LocItems)
    if (orgItemList.length == 0) {
      LocItems.map((loc, index) => orgItemList.push(loc));
    }
  }

  checkedLocation(orgItem) {
    console.log('selectOrg', orgItem);
    console.log('isAllSelected', this.state.isAllSelected);
    let roleArr = [];

    if (orgItem.length > 0 && this.state.isAllSelected == false) {
      this.setState({ isAllSelected: true });
      orgItem.map((item, index) => (item.isAdmin ? roleArr.push(item) : ''));
    } else {
      this.setState({ isAllSelected: false });
    }
    let list = this.state.orgItems.includes(orgItem)
      ? this.removeListElement(this.state.orgItems, orgItem)
      : this.addListElement(this.state.orgItems, orgItem);
    orgItem.length > 0
      ? this.setState({ orgItems: roleArr })
      : this.setState({
          orgItems: list,
        });
  }
  componentDidMount() {
    //console.log("componentDidMount called....")
    var checkbox = window.$('table tbody input[type="checkbox"]');
    window.$('#selectAll').click(function () {
      if (this.checked) {
        checkbox.each(function () {
          this.checked = true;
        });
      } else {
        checkbox.each(function () {
          this.checked = false;
        });
      }
    });
    checkbox.click(function () {
      if (!this.checked) {
        window.$('#selectAll').prop('checked', false);
      }
    });

    //User roles Api call
    let rolesApi = getAssignRolesApi();
    AssignRolesData(this, rolesApi);
  }
  setUserData(data) {
    this.setState({
      userRolesData: data,
    });
  }
  async handleFormFields(e) {
    
    if (e.target.name == 'emailId') {
      // console.log(e.target.name)
      
      this.setState({
        addUserInfo: {
          logonId: e.target.value,
          firstName: this.state.addUserInfo.firstName,
          lastName: this.state.addUserInfo.lastName,
        },
      });

      
      let expression =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.setState({ validEmail: expression.test(e.target.value) });
      //console.log("expression", expression.test(e.target.value))
      let err = (
        <strong style={{ color: '#FF0000' }}>
          {translationUtils.getTranslation(2205, 'Please Enter Valid Email')}
        </strong>
      );
      if (!expression.test(e.target.value)) {
        this.setState({ emailErrMsg: err });
      } else {
        this.setState({ emailErrMsg: '' });
      }

      /*if(this.state.emailErrMsg == ''){
       
        const email = this.state.addUserInfo.logonId;
        const result = await this.getApplicationBasedRolesCallBackForAddUser(email);*/
        //console.log('result',result[0])

        //this.setState({newUserInfo: result});
      //}
     
    }

    if (e.target.name == 'firstname') {
      this.setState({
        addUserInfo: {
          logonId: this.state.addUserInfo.logonId,
          firstName: e.target.value,
          lastName: this.state.addUserInfo.lastName,
        },
      });

      let err = (
        <strong style={{ color: '#FF0000' }}>
          {translationUtils.getTranslation(2207, 'Please Enter First Name')}
        </strong>
      );
      if (e.target.value == '' || e.target.value == null) {
        this.setState({ fnameErrMsg: err });
        this.setState({ validFname: false });
      } else {
        this.setState({ fnameErrMsg: '' });
        this.setState({ validFname: true });
      }
    }

    if (e.target.name == 'lastname') {
      this.setState({
        addUserInfo: {
          logonId: this.state.addUserInfo.logonId,
          firstName: this.state.addUserInfo.firstName,
          lastName: e.target.value,
        },
      });

      let err = (
        <strong style={{ color: '#FF0000' }}>
          {translationUtils.getTranslation(2206, 'Please Enter Last Name')}
        </strong>
      );
      if (e.target.value == '' || e.target.value == null) {
        this.setState({ lnameErrMsg: err });

        this.setState({ validLname: false });
      } else {
        this.setState({ lnameErrMsg: '' });
        this.setState({ validLname: true });
      }
    }
  }

  locationFormSubmit() {
    this.setState({
      activeTabClass: 'green-active active',
      locationActiveTabClass: 'green-active active',
      rolesActiveTabClass: 'yellow-active active',
    });
  }
  locationQuickSearch() {
    const userData = this.state.filterLocation ? this.state.filterLocation : '';
    let wordText = $('#ssearch')[0].value;
    //console.log(wordText);
    let filtData = userData.filter(function (value) {
      return (
        (value.legalId !== null && value.legalId.includes(wordText)) ||
        (value.orgName !== null &&
          value.orgName.toLowerCase().includes(wordText.toLowerCase()))
      );
    });
    //window.destroyTable();
    //console.log("filtData",filtData)
    this.setState((prevState) => {
      let locList = Object.assign({}, prevState.filterLocation);
      locList = filtData;
      //console.log("locList",locList);
      this.setFilteredLocation(locList);
      return { locList };
    });
  }
  setFilteredLocation(locList) {
    // console.log("filteredData",locList)
    this.setState({ locationList: locList });
  }

  render() {
    //console.log("orgItems", this.state.orgItems);
    const orgCustomesID = [];
    const locationData = [];
    this.state.orgItems.map((v, k) => orgCustomesID.push(v.legalId));
    this.state.filterLocation.map((v, k) =>
      v.isAdmin ? locationData.push(v) : ''
    );
    //console.log("orgCustomesID",orgCustomesID.length==locationData.length)
    // console.log("selectedOrg", orgItemList)
    // console.log("1 " + this.state.validFname + " 2 " + this.state.validLname + " 3 " + this.state.validEmail)
    // console.log(this.state.emailErrMsg + this.state.fnameErrMsg + this.state.lnameErrMsg)
    if (
      this.state.validFname == true &&
      this.state.validLname == true &&
      this.state.validEmail == true
    ) {
      // this.setState({activeTabClass: "green-active active",locationActiveTabClass: "yellow-active active"})
      // console.log("Form is validated")
      // this.setState({isValidForm: true})
      // this.setState({activeTabClass: "green-active active",locationActiveTabClass: "yellow-active active"})
    } else {
      //this.setState({isValidForm: false})
    }
    //console.log("form", this.state.isValidForm);
    // console.log("AddUser CID", this.props.cid);
    return (
      <div>
        {/*<div className="header-rectangle">
                    <div className="header-details padding-margin">
                        Add User
                    </div>
        </div> */}
        <div class="container-fluid">
          <div class="ph-header-main__title row px-md-4 px-lg-3">
            <div class="col">
              <h1 class="p-title">
                {translationUtils.getTranslation(1088, 'Add') +
                  ' ' +
                  translationUtils.getTranslation(2185, 'User')}
              </h1>
            </div>
          </div>
        </div>
        {/*<div className="navigation-breadcrumb-browse padding-margin">
                    Home / Authorised Users / Add User
    </div> */}
        <div class="container-fluid">
          <div className="ph-header-main__breadcrumbs row px-md-4 px-lg-3 py-2">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item" aria-current="page">
                    <a
                      href={
                        window.location.origin +
                        window.location.pathname.split('addUser')[0] +
                        'all' +
                        window.location.search
                      }
                    >
                      {translationUtils.getTranslation(791, 'Home')}
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    <Link
                      to={
                        '/' +
                        this.props.lang +
                        '/myparker/authenticatedUser?cid=' +
                        this.props.cid
                      }
                    >
                      {translationUtils.getTranslation(
                        2167,
                        'Authorized Users'
                      )}{' '}
                    </Link>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {translationUtils.getTranslation(1088, 'Add') +
                      ' ' +
                      translationUtils.getTranslation(2185, 'User')}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <ul className="timeline nav" id="myTab">
                <li className={this.state.activeTabClass}>
                  {' '}
                  <a href="#user" data-toggle="collapse"></a>
                </li>
                <li className={this.state.locationActiveTabClass}>
                  <a href="#location" data-toggle="collapse"></a>{' '}
                </li>
                <li className={'last ' + this.state.rolesActiveTabClass}>
                  {' '}
                  <a href="#roles" data-toggle="collapse"></a>
                </li>
              </ul>
            </div>
            <div className="col-lg-12 col-md-12">
              <div className="tab-content" id="myTabContent">
                <form className="tab-pane  active" id="user">
                  <div className="header-details">
                    1.{' '}
                    {translationUtils.getTranslation(2199, 'Account Profile')}
                  </div>
                  <div className="item-spacing">
                    <label for="emailId">
                      {translationUtils.getTranslation(408, 'Email')}{' '}
                      <span className="asterikMark">*</span>
                    </label>
                    <input
                      type="text"
                      id="emailID"
                      name="emailId"
                      placeholder={translationUtils.getTranslation(
                        408,
                        'Email'
                      )}
                      onChange={(e) => this.handleFormFields(e)}
                    />
                    {this.state.emailErrMsg}
                  </div>
                  <div className="item-spacing">
                    <label for="fname">
                      {translationUtils.getTranslation(61, 'First Name')}{' '}
                      <span className="asterikMark">*</span>
                    </label>
                    <input
                      type="text"
                      id="fname"
                      name="firstname"
                      placeholder={translationUtils.getTranslation(
                        61,
                        'First Name'
                      )}
                      onChange={(e) => this.handleFormFields(e)}
                    />
                    {this.state.fnameErrMsg}
                  </div>
                  <div className="item-spacing">
                    <label for="lname">
                      {translationUtils.getTranslation(62, 'Last Name')}{' '}
                      <span className="asterikMark">*</span>
                    </label>
                    <input
                      type="text"
                      id="lname"
                      name="lastname"
                      placeholder={translationUtils.getTranslation(
                        62,
                        'Last Name'
                      )}
                      onChange={(e) => this.handleFormFields(e)}
                    />
                    {this.state.lnameErrMsg}
                  </div>
                  <div className="flw btndiv">
                    <Link
                      type="submit"
                      to={
                        '/' +
                        this.props.lang +
                        '/myparker/authenticatedUser?cid=' +
                        this.props.cid
                      }
                      className="btn-import-user"
                    >
                      {translationUtils.getTranslation(404, 'Cancel')}
                    </Link>
                    {this.state.validFname == true &&
                    this.state.validLname == true &&
                    this.state.validEmail == true ? (
                      <button
                        type="submit"
                        data-toggle="tab"
                        className="btn-add-user"
                        data-target="#location"
                        onClick={(e) => this.nextButtonHandle(e)}
                      >
                        {translationUtils.getTranslation(717, 'Next')}
                      </button>
                    ) : (
                      <button
                        type="button"
                        data-toggle="tab"
                        className="btn-add-user"
                        onClick={(e) => this.nextButtonHandle(e)}
                      >
                        {translationUtils.getTranslation(717, 'Next')}
                      </button>
                    )}
                  </div>
                </form>
                <form className="tab-pane " id="location">
                  <div className="header-details">
                    2. {translationUtils.getTranslation(2220, 'Add Locations')}
                    <div style={{ fontWeight: 'normal', fontSize: '13px' }}>
                      {translationUtils.getTranslation(
                        2200,
                        'Select from the available list of locations below:'
                      )}
                    </div>
                  </div>
                  <div className="item-spacing">
                    <input
                      type="text"
                      id="ssearch"
                      name="emailId"
                      placeholder={
                        translationUtils.getTranslation(2220, 'Quick find') +
                        ' ' +
                        translationUtils.getTranslation(386, 'location')
                      }
                      onKeyUp={() => this.locationQuickSearch()}
                      style={{ width: '40%' }}
                    />
                  </div>
                  <div className="table-wrapper xtraheight">
                    <table className="ph-table">
                      <thead className="ph-table__header">
                        <tr className="ph-table__row wrap-text">
                          <th className="ph-table__title noVis widt-10">
                            <span className="custom-checkbox">
                              <input
                                type="checkbox"
                                id="selectAll"
                                checked={
                                  orgCustomesID.length == locationData.length
                                }
                                onClick={(e) =>
                                  this.checkedLocation(this.state.locationList)
                                }
                              />
                              {/*<label for="selectAll"></label> */}
                            </span>
                          </th>
                          <th className="ph-table__title noVis widt-25">
                            {translationUtils.getTranslation(
                              2175,
                              'Location Number'
                            )}
                            {/* <i className="fa fa-sort" aria-hidden="true"></i> */}
                          </th>
                          <th className="ph-table__title noVis">
                            {translationUtils.getTranslation(
                              2176,
                              'Location Name'
                            )}
                            {/* <i className="fa fa-sort" aria-hidden="true"></i> */}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="ph-table__body">
                        {this.state.locationList != null &&
                          this.state.locationList.map((orgItem, index) =>
                            orgItem.isAdmin ? (
                              <tr className="ph-table__row wrap-text">
                                <td>
                                  <span className="custom-checkbox">
                                    <input
                                      type="checkbox"
                                      id={'checkbox' + index}
                                      name="options[]"
                                      value="1"
                                      checked={orgCustomesID.includes(
                                        orgItem.legalId
                                      )}
                                      onClick={(e) =>
                                        this.checkedLocation(orgItem)
                                      }
                                    />
                                    {/* <label for={"checkbox" + index}></label> */}
                                  </span>
                                </td>
                                <td>{orgItem.legalId}</td>
                                <td className="elipse">{orgItem.orgName}</td>
                              </tr>
                            ) : (
                              ''
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                  <div className="flw btndiv">
                    <button
                      type="submit"
                      className="button primary-button round-button medium-button ml-1"
                      data-toggle="tab"
                      data-target="#user"
                    >
                      {translationUtils.getTranslation(547, 'Back')}
                    </button>
                    {this.state.orgItems.length > 0 ? (
                      <button
                        type="submit"
                        data-toggle="tab"
                        data-target="#roles"
                        className="button primary-button round-button medium-button ml-1"
                        onClick={() => this.locationFormSubmit()}
                      >
                        {translationUtils.getTranslation(717, 'Next')}
                      </button>
                    ) : (
                      <button
                        disabled={true}
                        id="disabled-button"
                        className="button primary-button round-button medium-button ml-1"
                        onClick={() => this.locationFormSubmit()}
                      >
                        {translationUtils.getTranslation(717, 'Next')}
                      </button>
                    )}
                  </div>
                </form>
                <AssignRoles
                  history={this.props.history}
                  getLoader={this.props.getLoader}
                  orgList={this.state.orgItems}
                  userRolesData={this.state.userRolesData}
                  addUserInfo={this.state.addUserInfo}
                  setLocationOnBack={this.setLocationOnBack}
                  newUserInfo={this.state.newUserInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Adduser;
