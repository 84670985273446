import axios from 'axios';
import { getListOfUsers, getRolesByLogonId } from '../common-utils/api_urls';
import * as translationUtils from '../common-utils/translation_utils';
import { getAPIAuthKey, getEnvDetail } from '../apiconfig';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

/**
 * Get Header Footer Data Service
 * @param {MainWrapper} callingClass
 * @param {string} apiUrl
 * @param {string} authToken
 */
export default function getDeligatedAuthUserData(callingClass, locId) {
  //let customizeHeader={}
  let headers = {
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };

  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
    };
  }

  // customizeHeader.Accept='application/json'
  //if(window.location.origin.indexOf('myparkerdev')>0)
  //customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")
  //console.log(getDAFilterByLocation)
  const adminId =
    window.location.hostname.toLowerCase().indexOf('localhost') < 0
      ? window.sessionStorage.getItem('oktaUserEmail')
      : 'reji.menon@parker.com';
  const res = axios(getListOfUsers(locId, adminId), {
    method: 'GET',
    headers: headers,
  })
    .then((result) => {
      // console.log("Result: "+result)
      if (result.data !== null && result.data !== undefined) {
        callingClass.setDeligatedAuthUserData(result.data);
      }
    })
    .catch((error) => {
      // sessionStorage.clear();
      //  window.location.href=`${window.allData.loginUrl}${window.location.href}`
    });
}

export function getListOfLocation(callingClass, apiUrl, location, logonId) {
  //let customizeHeader={}
  let headers = {
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    //'oktaSessionId': window.sessionStorage.getItem('oktaSessionId')
    WCToken: window.sessionStorage.getItem('WCToken'),
    WCTrustedToken: window.sessionStorage.getItem('WCTrustedToken'),
  };

  if (headers.WCToken == null || headers.WCTrustedToken == null) {
    headers = {
      'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
      'Cache-Control': 'no-cache',
      Accept: 'application/json',
      // "WCToken": '757209%2CtOl%2B3tfPMDT4VljxJpGJvHjcTL8K3JPJ3DBUZQBf5xc9yK67%2FDkr6G65%2B7Gcjan89FizcOFTTUu8JIvQkMa7ZUIxq8Jv%2BhdhecSZy6%2FlR%2FWEtY2EasjdskyZapyXaJ221IyY2wh%2Bav0alKzdEkf6JxoQ5u582zGNwsd9qhZgcJATYRAhdtwilTqvclM13meqWIsSDgtCSklccLwGo4WBquaxD0jikSShcC3Uis9F%2BJqb61V1B0ceoTis8Y1l9mXM',
      // "WCTrustedToken": '757209%2CKfUxtlIp2BB%2BHZkJMZxql3ZiuWN6vz%2B9%2FivvVGF6As8%3D'
    };
  }

  //customizeHeader.Accept='application/json'
  //if(window.location.origin.indexOf('myparkerdev')>0)
  //customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")
  //console.log(getDAFilterByLocation)
  const res = axios(apiUrl, {
    method: 'POST',
    headers: headers,
    data: {
      orgList: location,
      logonId: logonId,
    },
  })
    .then((result) => {
      console.log('Result: ' + result);
      if (result.data !== null && result.data !== undefined) {
        callingClass.getLocationCallBack(result.data);
      }
    })
    .catch((error) => {
      // sessionStorage.clear();
      //  window.location.href=`${window.allData.loginUrl}${window.location.href}`
    });
}

// export function getRoles(callingClass, langCode, LogonId){
//   //let customizeHeader={}
//   const headers = {
//     "Ocp-Apim-Subscription-Key" : getAPIAuthKey(), "Cache-Control":"no-cache",
//     "Accept":'application/json'
//     };

//   //customizeHeader.Accept='application/json'
//   //if(window.location.origin.indexOf('myparkerdev')>0)
//   //customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")
//   //console.log(getDAFilterByLocation)
//   const res =   axios(getRolesByLogonId(langCode, LogonId),{
//       method: 'GET',
//       headers:headers
//     }).then(
//         (result)=>{

//           if(result.data!==null && result.data!==undefined){
//             callingClass.getRoles(result.data);
//           }}).catch(
//               (error)=>{
//                   // sessionStorage.clear();
//                   //  window.location.href=`${window.allData.loginUrl}${window.location.href}`
//         })

// }

// export function getUserDetail(callingClass, logonId){
//   let customizeHeader={}

//   customizeHeader.Accept='application/json'
//   if(window.location.origin.indexOf('myparkerdev')>0)
//   customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")
//   const res =   axios(getEditUserDetails(logonId),{
//       method: 'GET',
//     }).then(
//         (result)=>{
//           if(result.data!==null && result.data!==undefined){
//            callingClass.setUserData(result.data.groupMap.lastLogin);

//           }else{
//            // sessionStorage.clear();
//             //window.location.href=`${window.allData.loginUrl}${window.location.href}`;
//           }}).catch(
//               (error)=>{
//                   //sessionStorage.clear();
//                   // window.location.href=`${window.allData.loginUrl}${window.location.href}`
//         })

// }

export async function getWCSTrustedToken(
  callingClass,
  apiUrl,
  logonId,
  accessToken
) {
  let headers = {
    'Ocp-Apim-Subscription-Key': getAPIAuthKey(),
    'Cache-Control': 'no-cache',
  };

  const res = await axios(apiUrl, {
    method: 'POST',
    headers: headers,
    data: {
      logonId: logonId,
      oktaSessionId: accessToken,
    },
  })
    .then((result) => {
      console.log('Result==', result.data);
      if (result.data !== null && result.data !== undefined) {
        window.sessionStorage.setItem('WCToken', result.data.WCToken);
        window.sessionStorage.setItem(
          'WCTrustedToken',
          result.data.WCTrustedToken
        );
        // window.sessionStorage.setItem('WCUserId', result.data.userId);

        /* WCToken and WCTrusted set to cookies */

        cookies.set(
          getEnvDetail() + '_WCToken',
          decodeURIComponent(result.data.WCToken),
          { path: '/', domain: '.parker.com' }
        );
        cookies.set(
          getEnvDetail() + '_WCTrustedToken',
          decodeURIComponent(result.data.WCTrustedToken),
          { path: '/', domain: '.parker.com' }
        );
        cookies.set('WCUserId', decodeURIComponent(result.data.userId), {
          path: '/',
          domain: '.parker.com',
        });
      }
    })
    .catch((error) => {
      console.log('mp: inside catch');
      // window.sessionStorage.setItem('WCToken', '7270684%2CUqc8CHuFrRgC8hTt0DVHx2HBa1715Ul7FPJvIGMcYqoNJTmRYOZPzelR%2FBG1AJUHvkrIP6F%2BMPGBePpGZGkNiO6JmZsoaw3rrcBXZ3vwGS2H%2F8mUMBoWFzhYujfcZrLWgfY7KhzhN%2FTsi39wy0kSokrDO57mhFlOE8VrGMB6bC5v%2B4KApwb%2FeOsA%2FHSO7GV2TflfEtu968ZkBu9iRPxRJbVNe19LLHwhjMgfPJiQIf57xbwF4UIzTnL4WGKWdSgM');
      // window.sessionStorage.setItem('WCTrustedToken','7270684%2CZF1AkshCrEBeSxfaInAMEEBVee6FLLxe1%2FGixNqqUHE%3D' );
      // cookies.set('dev_WCToken','7270684%2CUqc8CHuFrRgC8hTt0DVHx2HBa1715Ul7FPJvIGMcYqoNJTmRYOZPzelR%2FBG1AJUHvkrIP6F%2BMPGBePpGZGkNiO6JmZsoaw3rrcBXZ3vwGS2H%2F8mUMBoWFzhYujfcZrLWgfY7KhzhN%2FTsi39wy0kSokrDO57mhFlOE8VrGMB6bC5v%2B4KApwb%2FeOsA%2FHSO7GV2TflfEtu968ZkBu9iRPxRJbVNe19LLHwhjMgfPJiQIf57xbwF4UIzTnL4WGKWdSgM' ,{path:'/',domain:'.parker.com'});
      // cookies.set('dev_WCTrustedToken', '7270684%2CZF1AkshCrEBeSxfaInAMEEBVee6FLLxe1%2FGixNqqUHE%3D',{path:'/',domain:'.parker.com'});
    });
}
