import React, { Component } from 'react';
import $, { event, nodeName } from 'jquery';
import * as apis from '../../common-utils/api_urls'
import { Link } from 'react-router-dom';
import * as translationUtils from '../../common-utils/translation_utils'
import './supportCustom.css';
import L1TilesList from './L1TilesList';
import RecentVisitedAssets from './RecentVisitedAssets';


/**
 * header class renders header
 */
class SupportAssetsL1Tiles extends Component {
    constructor(props){
        super(props);
        this.state = {
          supportAssetsL1Data:this.props.data['3_response'].docs,
          noOfL1tiles: this.props.data['3_response'].docs.length,
          selectedCustomer: this.props.selectedCustomer,
          bannerDocArray: this.props.data['1_response'].docs,
          isEnoviaFlag:true,
          enoviaResp: this.props.data['5_response'],
          row:0
        }
        console.log("selectedCustomer",this.props)
       
    }
    componentDidMount(){
      let phCatalogNum = this.state.enoviaResp.numFound!=null?this.state.enoviaResp.numFound:0;
      if(phCatalogNum==0){
        this.setState({isEnoviaFlag:false});
        
      } 
     
    }
    
    splitInColumns(colNum) {
      let jQuery=window.jQuery;
   var colNum = colNum;
   var total = jQuery("#tileL1").children('div').size();
   var count = Math.ceil(total / colNum);
   var column = 0;
   for (var i = 0; i < total; i += count) {
       column++;
       jQuery("#tileL1").append('<div class="col-xs-12 col-md-12 pad0 marT30" id="catL1' + column + '"></div>');
       jQuery("#catL1"+column).html(jQuery("#L1-"+i).splice(0, count));
   }
   
}


    render() {
        console.log("isEnoviaFlag",this.state.isEnoviaFlag)
        let arraySize=this.state.noOfL1tiles;
        let l1TilesData =[];
        for(var i=0;i<arraySize;i++){
          if(this.state.supportAssetsL1Data[i].categoryType_si!='enovia'){
           
            l1TilesData[i]=this.state.supportAssetsL1Data[i]
          }
          
        }
        const l1Tiles=l1TilesData
        
        if(arraySize%3==0){
          arraySize=arraySize;
        } else {
          arraySize=arraySize+2;
        }
        let row=3;
	      let index=0;
        return (

            <div className="mainContent" style={{width: '100%'}}>
              <div className="col-md-12 col-xs-12 breadcrumbWrapper hidden-xs ipad-hidden">
                <ol className="breadcrumb"><li style={{paddingLeft: '3px'}}>  <Link to={"/"+this.props.lang+"/myparker/all?cid="+this.props.cid} title={translationUtils.getTranslation(791, "Home")}>{translationUtils.getTranslation(791, "Home")}</Link><img src={process.env.PUBLIC_URL+"/assets/images/breadcrumb-arrow.png"} alt="breadcrumb arrow icon" title="Breadcrumb Arrow" className="padL15" /></li><li className="active" style={{paddingLeft: '3px'}}> <a href="#" style={{textDecoration: 'none'}} title="Support">Support</a></li> </ol>
                <style dangerouslySetInnerHTML={{__html: "\n.breadcrumb li{\n\tbackground:none !important;\n}\n" }} />
              </div>
              <div className="product-catalog-mainContainer acme-support-container">
                <div className="search-results-container">
                  <div className="search-box-container">
                    <div className="col-xs-12 pad0 hidden-xs">
                      <div className="col-xs-3 col-sm-2 pad0">
                        <p className="search-by-text">{translationUtils.getTranslation(1414,"SEARCH BY TERM")}</p>
                      </div>
                      <div className="col-xs-9 col-sm-10 pad0">
                        <form name="searchForm3" className="searchForm" method="get" autoComplete="off" action="/EN/myparker/supportSearch/">
                          <div className="search-options-div">
                            <label htmlFor="search-select-box" name="selectCategory" className="visuallyhidden"> search-select-box</label>
                            <input type="hidden" name="selectCategory" defaultValue="support" />
                            <input type="hidden" name="vgnextfmt" defaultValue="EN" />
                            <input type="hidden" name="cid" defaultValue={4000000000000025006} />
                            <input type="hidden" name="vgnextoid" id="vgnextoid" defaultValue="49a9917e4ff20610VgnVCM10000025651dacRCRD" />
                            <label htmlFor="keyWord" className="visuallyhidden"> Search Box</label>
                            <input id="keyWord" type="search" name="searchbox" placeholder={translationUtils.getTranslation(1413,"Enter Search Term")} className="search-input-box" />
                            <div id="searchDivHead" style={{float: 'right', cursor: 'pointer', width: '8px', height: '8px', marginTop: '13px', zIndex: 2, marginRight: '10px', display: 'none'}}><img id="crossButtonHead" src="/parker/myparker/assets/images/textboxcross.gif" alt="" className="hidden" title /></div>
                            <span className="search-button-span" style={{marginLeft: 'auto', marginTop: '-1px'}}>
                              <button className="btn btn-default search-button-icon" type="submit"> 
                                <span> 
                                  <i className="fa fa-search" title="Search" />{translationUtils.getTranslation(46,"Search")} 
                                </span>
                              </button> 
                            </span>
                          </div>
                        </form>
                      </div>
                    </div>
                    <p className="search-by-text hidden-lg ipad-hidden">{translationUtils.getTranslation(1414,"SEARCH BY TERM")}</p>
                    <div className="search-options-div hidden-lg ipad-hidden">
                      <form name="searchForm2" method="get" autoComplete="off" action="/portal/site/MyParker/menuitem.3f30bc9d9710e7dad4fa334f4256d1ca/" className="searchForm">
                        <input type="hidden" name="selectCategory" defaultValue="support" />
                        <input type="hidden" name="vgnextfmt" defaultValue="EN" />
                        <input type="hidden" name="cid" defaultValue={4000000000000025006} />
                        <input type="hidden" name="vgnextoid" id="vgnextoid" defaultValue="49a9917e4ff20610VgnVCM10000025651dacRCRD" />
                        <input id="keyWord" type="search" name="searchbox" placeholder={translationUtils.getTranslation(1413,"Enter Search Term")} className="search-input-box" />
                        <div id="searchDivHead" style={{float: 'right', cursor: 'pointer', width: '8px', height: '8px', marginTop: '13px', zIndex: 2, marginRight: '10px'}}>
                          <img id="crossButtonHead" src="/parker/myparker/assets/images/textboxcross.gif" alt="Cross Button" title="Cross Button" />
                        </div>
                        <span className="search-button-span" style={{marginTop: '-1px'}}>
                          <button className="btn btn-default search-button-icon" type="submit"> 
                            <span> 
                              {translationUtils.getTranslation(46,"Search")} 
                            </span> 
                          </button> 
                        </span>
                      </form>
                    </div>
                  </div>
                </div>
                <div>
                 
                    {/* Desktop View */}
                    
                      {(l1TilesData!=null && l1TilesData!=undefined)?(<L1TilesList l1Tiles={l1Tiles} orgName={this.props.orgName}></L1TilesList>):<></> }
                   
                    {/* Mobile View */}
              
                 
                </div>
              </div>
              {/* Recently Added Support Assets */}
              {/* <RecentVisitedAssets /> */}
            </div>
          );
        
    
  
    }

}

export default SupportAssetsL1Tiles;