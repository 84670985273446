import axios from 'axios'
import * as apis from '../common-utils/api_urls'
import * as translationUtils from '../common-utils/translation_utils'

/**
 * Get Application Tiles Data Service
 * @param {MainWrapper} callingClass 
 * @param {string} apiUrl 
 * @param {string} authToken 
 */
export default   function getApplicationTilesData(callingClass, apiUrl){
  let customizeHeader={}
  
  customizeHeader.Accept='application/json'
  //if(window.location.origin.indexOf('dev')>0)
  //customizeHeader.Authorization= 'Basic '+btoa("phstage:phstage")
  const res =   axios(apiUrl,{
      method: 'GET',
      headers:{'Ocp-Apim-Subscription-Key': '1560822963354a4888aa2dd3dc5bec99'}
    }).then(        
        (result)=>{ 
          if(result.data!==null && result.data!==undefined){
            callingClass.setApplicationTilesdata(result.data);
            //console.log("11_response "+JSON.stringify(result.data));
          }else{
            // sessionStorage.clear();
            // window.location.href=`${window.allData.loginUrl}${window.location.href}`; 
          }}).catch(
              (error)=>{
                  // sessionStorage.clear();
                  //  window.location.href=`${window.allData.loginUrl}${window.location.href}`
        })        
     
    
}
