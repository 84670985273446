import React, { Component } from 'react';
import * as translationUtils from '../../../common-utils/translation_utils';

class ConfirmationPopUp extends Component {
  render() {
    return (
      <div>
        <div
          className="ph-modal fade"
          id="confirmationModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="Create Token Modal"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="ph-modal__content">
              <div className="d-flex justify-content-end m-0 p-0">
                <span
                  data-dismiss="modal"
                  aria-label="Close"
                  className="ph-modal__header--close ph-modal-header-close-font-size"
                  aria-hidden="true"
                >
                  <i className="fas fa-times"></i>
                </span>
              </div>
              <div className="ph-modal__header">
                {/*<h2 className="ph-modal__title" id="importuserModalLabel">
                      {"Are you sure you want to deactivate toke?"}
                    </h2>*/}
              </div>
              <div className="ph-modal__body">
                <div className="ph-form-import-row text-c">
                  {this.props.action == 'refresh'
                    ? translationUtils.getTranslation(
                        3344,
                        `Are you sure you want to refresh this token?`
                      )
                    : translationUtils.getTranslation(
                        3345,
                        `Are you sure you want to deactivate this token?`
                      )}
                  {/*`Are you sure you want to ${this.props.action} this token?`*/}
                </div>
              </div>
              <div className="ph-modal__footer">
                <button
                  type="button"
                  className="button accent-button round-button medium-button small-button"
                  data-dismiss="modal"
                >
                  {translationUtils.getTranslation(404, 'Cancel')}
                </button>
                {this.props.action == 'refresh' ? (
                  <button
                    type="button"
                    className="button primary-button round-button medium-button ml-1"
                    data-dismiss="modal"
                    onClick={this.props.handleRefreshToken}
                  >
                    {translationUtils.getTranslation(1889, 'Confirm')}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="button primary-button round-button medium-button ml-1"
                    data-dismiss="modal"
                    onClick={this.props.handleDeactivateToken}
                  >
                    {translationUtils.getTranslation(1889, 'Confirm')}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmationPopUp;
