import React, { Component } from 'react';
import { editRolesApiUrl } from '../../common-utils/api_urls';
import { saveRemoveLocation } from '../../services/getUserDetail';
import PopUpComponent from './PopUpComponent';
import * as translationUtils from '../../common-utils/translation_utils'

class EditMultipleLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRole: { displayName: null, roleId: null, roleName: null, message: "", status: "",isOpen: true },
        }
    }
    handleRoles(e) {
        //console.log(e.target.value);
        let roles = {}
        roles = [{
            "displayName": e.target.value.split('$$$')[0],
            "roleId": e.target.value.split('$$$')[1],
            "roleName": e.target.value.split('$$$')[2]
        }]
        this.setState({ selectedRole: roles })

    }
    saveCommonRole() {
        window.scrollTo(0,0)
        this.props.getLoader(true)
        this.props.changeAllRoles(this.state.selectedRole)
        let apiUrl = editRolesApiUrl(this.props.userEmail, "edit");
        let rolesInOrgPayload = []
        let role = this.state.selectedRole[0].roleId
        this.props.orgList.map((org, index) => (
            this.props.isAllSelected ?
                this.props.locationData.map((loc, idx) =>
                    org.legalId == loc.customerID ? rolesInOrgPayload.push(org.orgId + ":" + role) : ""
                ) :
                this.props.checkedLocationList.map((locItem, idx) =>
                    org.legalId == locItem.value.split("$$$")[0] ? rolesInOrgPayload.push(org.orgId + ":" + role) : ""
                )


        ))
        let locationsAndRoles = { "rolesInOrg": rolesInOrgPayload, logonId: this.props.userEmail.toLowerCase(), "adminId": window.sessionStorage.oktaUserEmail ? window.sessionStorage.oktaUserEmail.toLowerCase() : "" }
        this.props.saveMultiLocationRoles( apiUrl, locationsAndRoles);
       // console.log("Locations Data", role)
       // console.log(rolesInOrgPayload)
      
        // setTimeout(() => {
        //     this.props.getLocation(JSON.parse(localStorage.getItem("userDetails")).logonId);
        //     this.props.getLoader(false);
        //     this.setState({message:""})
        //     },5000);
    }
    setAPIResponose(status, message) {
        this.props.getLoader(true)
        if (status == 200) {
            status = "Success"
        } else {
            message = "Failed"
        }
        this.setState({
            status: status,
            message: message
        })
    }
    render() {
       // console.log("EditMultipleLocation", this.props)
        return (
            <div>
                <div className="ph-modal fade" id="editMultipleLocation" tabindex="-1" role="dialog" aria-labelledby="Reset Password Modal"
                    aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="ph-modal__content ph-modal__content__wider" style={{ width: "30%" }}>
                            <div className="d-flex justify-content-end m-0 p-0">
                                <span data-dismiss="modal" aria-label="Close" className="ph-modal__header--close ph-modal-header-close-font-size" aria-hidden="true"><i
                                    className="fas fa-times"></i></span>
                            </div>
                            <div className="ph-modal__header">
                                <h3 className="ph-modal__title" id="resetpasswordModalLabel"> {translationUtils.getTranslation(2188,"Apply a role to all locations")}</h3>
                            </div>
                            <div className="ph-modal__body">
                                <form>
                                    <div className="item-spacing" style={{ textAlign: 'left' }}>
                                        <span><strong>{translationUtils.getTranslation(2224,"Select a User Role from the List.")}</strong></span>
                                        <select name="role_filter_all" onChange={(e) => this.handleRoles(e)} style={{marginTop: "6px",marginBottom: "10px"}} >
                                            <option value="defaultValue">{translationUtils.getTranslation(2218,"Please select a role")}...</option>
                                            {this.props.userRoles != undefined && this.props.userRoles.map((role, index) => (
                                                <option value={role.displayName + "$$$" + role.roleId + "$$$" + role.roleName}>{role.displayName}</option>
                                            ))}

                                            {

                                                /*(this.props!=undefined && this.props.userRolesData) && this.props.userRolesData.roleList.map((role,index) => (
                                                <option value={role.displayName+'$$$'+role.roleId+'$$$'+role.roleName}  >{role.displayName}</option>
                                                )) */

                                            }
                                        </select>
                                        <button data-dismiss="modal" type="button" class="button primary-button round-button medium-button ml-1" onClick={() => this.saveCommonRole()}>{translationUtils.getTranslation(1075,"Save")}</button>
                                        {(this.state.message != "" && this.state.message != undefined) &&
                                            <PopUpComponent status={this.state.status} message={this.state.message} />
                                        }

                                    </div>
                                </form>

                            </div>
                            {/*<div className="ph-modal__footer">
                                <button type="button" className="button accent-button round-button small-button"
                                    data-dismiss="modal">Cancel</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>

        );
                        
    }
}

export default EditMultipleLocation;