import React, { Component } from "react";
import MultiSelect from "react-multi-select-component";
import * as translationUtils from '../../common-utils/translation_utils'

export default class MultiSelectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
    };
    this.setSelected = this.setSelected.bind(this);
  }
  setSelected(e) {
    this.setState({ selected: Array.isArray(e) ? e.map((x) => x) : [] });
    this.props.onUserLocChange(Array.isArray(e) ? e.map((x) => x.value) : [], Array.isArray(e) ? e.map((x) => x) : []);
  }
  componentDidMount() {
    let selectedOptions = [];
    let selectedOptions1 = [];
    let count=0;
    let listOfOrg=this.props.listOfOrg
    //console.log("listOfOrg",listOfOrg)
    this.props.options && this.props.options.map((option, index) => {
       if (option.isAdmin && option.cid === this.props.cid)
        selectedOptions[0] = { label: option.orgName, value: option.orgId };
       else if(option.isAdmin && this.props.cid === 'all') {
        selectedOptions[count] ={ label: option.orgName, value: option.orgId };
        count++
       }
    });
    //console.log("selectedOptions",listOfOrg)
    if(listOfOrg.length>0)
    this.setSelected(listOfOrg);
    else 
    this.setSelected(selectedOptions);
    
  }

  render() {
    const options = [];
    let count=0
    this.props.options && this.props.options.map((option, index) => {
      {
        if(option.isAdmin){
          (options[count] = { label: option.orgName, value: option.orgId });
          count++;
        } 
          
      }
    });

    //console.log(options.length+" length "+this.state.selected.length);
    return (
      <div>
        <MultiSelect
          options={options}
          value={this.state.selected}
          onChange={this.setSelected}
          labelledBy={translationUtils.getTranslation(228, "Select Location")}
          disableSearch={true}
          selectAllLabel={translationUtils.getTranslation(1008, "All Locations")}
          overrideStrings={{
            allItemsAreSelected: translationUtils.getTranslation(198, "All"),
            noOptions: translationUtils.getTranslation(2230, "No options"),
            selectAll: translationUtils.getTranslation(2229, "Select All"),
            selectSomeItems: translationUtils.getTranslation(228, "Select Location"),
          }}
        />
      </div>
    );
  }
}
