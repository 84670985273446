import React from "react";

export default function PageTitle (){
   
    return (
        <div>
            {/* <div classNameName="container-fluid greyBackground">
            <div classNameName="ph-header-main__title row px-md-4 px-lg-3 padding0">
                <div classNameName="col">
                    <h1 classNameName="p-title">Search</h1>
                </div>
            </div>
            </div>
            <div classNameName="container-fluid">
            <div classNameName="ph-header-main__breadcrumbs row px-md-4 px-lg-3 py-2 hide-mobile">
                <div classNameName="col">
                <nav aria-label="breadcrumb">
                    <ol classNameName="breadcrumb">
                        <li classNameName="breadcrumb-item" aria-current="page"><a title='Back to home page' href="/">Home</a></li>
                        <li classNameName="breadcrumb-item active p-title" aria-current="page">Search</li>
                    </ol>
                </nav>
                </div>
            </div>
        </div> */}
           <div className="container-fluid"><div className="ph-header-main__title row px-md-4 px-lg-3">
            <div className="col"><h1 className="p-title">Search </h1></div>
            </div>
            </div>
           <div className="container-fluid"><div className="ph-header-main__breadcrumbs row px-md-4 px-lg-3 py-2 "><div className="col"><nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item" aria-current="page">
                    <a href="https://testmy.parker.com/us/en/dashboard?cid=4000000000000082538">Home</a>
                    </li><li className="breadcrumb-item" aria-current="page">Search</li>
                    </ol></nav></div></div></div>
      
    </div>
    );
}