import React, { Component } from "react";
import axios from 'axios';
import * as apis from '../../common-utils/api_urls';
// import {
//   getAPIAuthKey,
//   getHeaderSubKey,
//   getHeaderSubKeyForApplicationRole,
// } from '../apiconfig';
import { getHeaderSubKeyForApplicationRole } from "../../apiconfig";
import { addUserApi, getAssignRolesApi, getEditUserDetails, getRolesBasedAccess } from "../../common-utils/api_urls";
import { AddUserDetail } from "../../services/getUserDetail";
import getUserDetail, {
  ActivateUser,
  saveRemoveLocation,
  getOktaLoginHistory,
  AssignRolesData,
  AssignApplicationBasedAccessNewUser,
  saveApplicationBasedAccess,
} from '../../services/getUserDetail';
import PopUpComponent from "./PopUpComponent";
import SuccessUserPopup from "./success_popup_user";
import * as translationUtils from '../../common-utils/translation_utils';

const rolesArr = [];

class AssignRoles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRoles: [],
      payload: null,
      rolesList: [],
      rolesFlage: false,
      message: "",
      status: "",
      commonRole: "",
      open:true,
      isChecked: false,
    };
    this.handleSelectedRoles = this.handleSelectedRoles.bind(this);
    this.removeListElement=this.removeListElement.bind(this);
  }

  // getApplicationBasedRolesCallBack(emailid) {

  //   console.log("accessdata",emailid)
  //   let data = '';
  //   let config = {
  //     method: 'get',
  //     maxBodyLength: Infinity,
  //     url:
  //       apis.getRolesBasedAccess() +
  //       emailid,
  //     headers: {
  //       'ocp-apim-subscription-key': getHeaderSubKeyForApplicationRole(),
  //       'Content-Type': 'application/json',
  //     },
  //     data: data,
  //   };
  //   axios
  //     .request(config)
  //     .then((response) => {
  //       console.log("assigneddat",response.data);
  //       // if (response.data !== null && response.data !== undefined) {
  //       //   callingClass.getApplicationBasedRolesCallBack(response.data);
  //       // }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   // let arr =
  //   //   data != null &&
  //   //   data.map(
  //   //     (orgItem, index) =>
  //   //       orgItem.WebResourceID + '-' + orgItem.RecordStatusCode
  //   //   );
        
  //   // let evntArr = arr[0].split('-');
    
  //   // let WebResourceIDVal = evntArr[0];
  //   // let RecordStatusCode = evntArr[1];
  //   // console.log("RecordStatusCode==",RecordStatusCode)
  //   // this.setState({
  //   //   applicationbasedlocationData: data,
  //   //   recordStatusCodeArr: arr,
  //   //   isChecked: RecordStatusCode === 'A' ? true : false,
  //   // });
  // }
  handleSelectedRoles(e) {
    
    // if(rolesArr.length==0){
    //   rolesArr.push(e.target.value);
    // }
    console.log("default",e.target.value)
    let role =[]
    
    role=this.removeListElement(this.state.selectedRoles,e.target.value)
    role.push(e.target.value)
    this.setState({
      //selectedRoles: rolesArr,
      selectedRoles: role,
    });
  }
  removeListElement(list= [], element){
    let newList=[]
    let j=0
    for(let i=0;i<list.length;i++){
        if(list[i].split(":")[0]==element.split(":")[0]){
         // newList[j++]=element
        } else{
          newList[j++]= list[i];
        } 
    }
    return newList;
}
  addUser(payload) {
    let checkRole=this.state.selectedRoles
   
    if(this.state.selectedRoles.length==this.props.orgList.length && !checkRole.toString().includes("default")){
    window.scrollTo(0,0)
    this.props.getLoader(true)    //console.log("MyData ",this.props);
    let addUserInfoPayload = {};
   
    if(document.querySelector('#RecordStatusCode') !== null){
      console.log("if")
      addUserInfoPayload = {
        firstName: this.props.addUserInfo.firstName,
        lastName: this.props.addUserInfo.lastName,
        logonId: this.props.addUserInfo.logonId.toLowerCase(),
        adminId: window.sessionStorage.oktaUserEmail,
        rolesInOrg: this.state.selectedRoles,
        resourceCode: document.querySelector('#RecordStatusCode').value,
        WebResourceID: document.querySelector('.WebResourceID').value,
      };

    } else {
      console.log("else")
      addUserInfoPayload = {
        firstName: this.props.addUserInfo.firstName,
        lastName: this.props.addUserInfo.lastName,
        logonId: this.props.addUserInfo.logonId.toLowerCase(),
        adminId: window.sessionStorage.oktaUserEmail,
        rolesInOrg: this.state.selectedRoles,
       };
    }
   
    console.log("FinalPayload ",addUserInfoPayload);
    let apiUrl = addUserApi();
    AddUserDetail(this, apiUrl, addUserInfoPayload);

    //console.log(this.props)
    
   /* setTimeout(() => {
      this.setState({message:""})
   
  },5000) */
} else {
  this.setState({message:translationUtils.getTranslation(2208,"Please select Roles !") , status: "Failed"})
  if (this.state.status != "Success") {
  setTimeout(() => {
    this.setState({message:""})
  },5000) 
}
}
    
  }
  setMessage(status, message) {
    this.props.getLoader(false)
    let apiStatus = "";
    if (status == 200) {
      apiStatus = "Success";
    } else {
      apiStatus = "Failed";
     
    }
    this.setState({ message: message, status: apiStatus,open:true });
    if (apiStatus != "Success") {
      setTimeout(() => {
        this.setState({message:""})
      },5000) 
   }
  }
  handleCommonRolesDropdown(e) {
    //console.log("Orgroles", this.props.orgList);
    this.props.orgList.map((val,index)=>(
      rolesArr.pop()
    ))
    //console.log("handleCommonRolesDropdown", e.target.value.split("$$$"));
    let roles = {};
    roles = [
      {
        displayName: e.target.value.split("$$$")[0],
        roleId: e.target.value.split("$$$")[1],
        roleName: e.target.value.split("$$$")[2],
      },
    ];

   // console.log("rolesdropdown", roles);
    if (e.target.value == "defaultValue") {
      this.setState({
        rolesList: this.props.userRolesData.roleList,
        commonRole: e.target.value
      });
    } else {
      this.props.orgList.map((item) =>
      rolesArr.includes(item.orgId +":-21" || item.orgId +":-31")?this.removeListElement(rolesArr,e.target.value):rolesArr.push(item.orgId + ":" + e.target.value.split("$$$")[1])
      );
      this.setState({
        //rolesList: roles,
        //rolesList:this.props.userRolesData.roleList,
        selectedRoles: rolesArr,
        commonRole: e.target.value.split("$$$")[1]
      });
    }
  }
  
  onCheckedApplicationRoleBox(event) {
    // const value = event.target.value;
    // console.log("eventcheck",value)
    if (event.target.checked) {
        this.setState({
        //  RecordStatusCode : "A",
          isChecked: true,
        })
        
    } else {
      this.setState({
       // RecordStatusCode : "D",
        isChecked: false,
      })
    }
  }

  componentDidUpdate() {
    if (
      this.state.rolesFlage == false &&
      this.props.userRolesData != undefined
    ) {
      this.setState({
        rolesList: this.props.userRolesData.roleList,
        rolesFlage: true,
      });
    }
    console.log("adduserinfo",this.props.newUserInfo);
  }
 

  componentDidMount() { 
    window.scrollTo(0,0);
  }
  assignModal = ()=>{this.setState({open:false})}
  render() {
    //window.scrollTo(0,0);
    //console.log("commonRole",this.state.commonRole)
    return (
      <form className="tab-pane  " id="roles">
        <div className="header-details">
          2. {  translationUtils.getTranslation(2186, "Assign Roles")}
          <div style={{fontWeight:"normal",fontSize:"13px"}}>{translationUtils.getTranslation(2187, "Please assign a user role for each location below:")}</div>
        </div>

        <div className="item-spacing">
          <span style={{fontWeight:"Bold"}}>{  translationUtils.getTranslation(2188, "Apply a role to all locations")}</span>
          <select
            name="role_filter_all"
            onChange={(e) => this.handleCommonRolesDropdown(e)}
          >
            <option value="defaultValue">{translationUtils.getTranslation(2218, "Please select a role")}...</option>
            {this.props != undefined &&
              this.props.userRolesData &&
              this.props.userRolesData.roleList.map((role, index) => (
                <option
                  value={
                    role.displayName +
                    "$$$" +
                    role.roleId +
                    "$$$" +
                    role.roleName
                  }
                >
                {role.displayName==='Administrator'?translationUtils.getTranslation(2223, "Administrator"):translationUtils.getTranslation(2185, "User")}
                </option>
              ))}
          </select>
        </div>
        <span style={{fontWeight:"Bold"}}>{translationUtils.getTranslation(2189,"Or select role for each individual location.")}</span>
        <div className="table-wrapper">
        
          <table className="table table-striped">
            <thead className="table-th-style">
              <tr>
                <th className="table-header-style widt-70">
                {translationUtils.getTranslation(2176,"Location Name")}
                  {/*<i className="fa fa-sort" aria-hidden="true"></i> */}
                </th>
                <th className="table-header-style">
                {translationUtils.getTranslation(2177,"Roles")} {/*<i className="fa fa-sort" aria-hidden="true"></i>*/}
                </th>
              </tr>
            </thead>
            <tbody>
              {this.props.orgList.sort((a, b) => a['orgName'].localeCompare(b['orgName'])).map((item, index) => (
                <tr>
                  <td>{item.orgName}</td>
                  <td>
                    <select style={{width:"50% !important"}}
                      name="role_filter_for"
                      onChange={this.handleSelectedRoles}
                    >
                      {this.state.rolesList.length > 1 && (
                        <option value={item.orgId + ":default"} selected={true}> { translationUtils.getTranslation(2218, "Please select a role")}...</option>
                      )}
                      {this.props != undefined &&
                        this.state.rolesList &&
                        this.state.rolesList.map((role, index) => (
                          <option selected={this.state.commonRole==role.roleId} value={item.orgId + ":" + role.roleId}>
                            {role.displayName==='Administrator'?translationUtils.getTranslation(2223, "Administrator"):translationUtils.getTranslation(2185, "User")}
                          </option>
                        ))}
                    </select>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        <div className="flw btndiv">
          {//this.state.message != "" && this.state.message != undefined && (

            <>
             {this.state.open && this.state.status == "Success" &&
             <SuccessUserPopup status={this.state.status} message={this.state.message} open={this.state.open} assignModal={this.assignModal} />
            }
             { (this.state.status == "Failed" && this.state.message != "") && 
             <PopUpComponent
                status={this.state.status}
                message={this.state.message}
              />  }
            
            </>
          //)
        }

         
        </div>

           {/*Adding section for Assign Roles to Application */}
                { this.props.newUserInfo && this.props.newUserInfo.map( item => ( 
                  
                  <div>
                    { item.RecordStatusCode == "D" ? 
                    <div>
                      <div className="header-details pt-4">
                        3. Assigned Application Roles
                        <div style={{fontWeight:"normal",fontSize:"13px"}}>{translationUtils.getTranslation(2187, "Please assign a user role for each location below:")}</div>
                      </div>
                    

                      {/* Code to Display the API Data listed*/}
                      <div className="pt-3"></div>
                      <div className="dialog-box" hidden="true"></div>
                      
                        <div className="contain-table">
                          <div className="table-wrapper">
                            <table className="table table-striped">
                              <thead className="ph-table__header--browse">
                                <tr className="ph-table__row">
                                  <th
                                    className="ph-table__title"
                                    scope="col"
                                    style={{ paddingLeft: '1.1rem', width: '0%' }}
                                  >
                                    {/* <input
                                      type="checkbox"
                                      checked={this.state.isAllSelected}
                                      id="selectAll"
                                      value="all"
                                      onClick={(e) => this.onCheckedApplicationRoleBox(e)}
                                      onChange={this.handleOnChange1}
                                    /> */}
                                  </th>
                                  <th className="ph-table__title" scope="col">
                                    Application Name
                                    <i className="fa fa-sort th-margin"></i>
                                  </th>
                                </tr>
                              </thead>

                              
                                <tbody className="ph-table__body">
                                
                                          <tr className="ph-table__row">
                                            <td className="extra_cursor">
                                              <span className="ml-2">
                                                <input
                                                  type="checkbox"
                                                  id="RecordStatusCode"
                                                  //name="laptop"
                                                  name="RecordStatusCode"
                                                  value={this.state.isChecked ? "A" : "D"}
                                                  defaultChecked={this.state.isChecked}
                                                  onClick={(e) =>
                                                    this.onCheckedApplicationRoleBox(e)
                                                  }

                                                />
                                                <input type="hidden" className="WebResourceID" name="WebResourceID" value={item.WebResourceID} />
                                              </span>
                                            </td>
                                            <td className="extra_cursor_remove">
                                              Price & Availability
                                            </td>
                                          </tr>
                                    </tbody>
                              </table>
                          </div>
                        </div>
                        </div>

                : ""}
             </div>
             
                
           ))}

<div className="flw btndiv">
           <button
            type="submit"
            className="button primary-button round-button medium-button ml-1"
            data-toggle="tab"
            data-target="#location"
            
          >
          {translationUtils.getTranslation(547, "Back")}  
          </button>
          <a
            type="button"
            className="button primary-button round-button medium-button ml-1"
            onClick={() => this.addUser(this.state.payload)}
          >
          {translationUtils.getTranslation(1162, "Done")}    
          </a>
          </div>

          {/*Ending section for Assign Roles to Application */}
        </form>
    );
  }
}

export default AssignRoles;
