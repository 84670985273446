import React, { Component } from 'react'
import SearchHeaderComponent from './SearchHeaderComponent'


class SupportSearchResult extends Component {
    
    render() {
        return (
            <div className="parkerSearch">
              <link rel="stylesheet" href={process.env.PUBLIC_URL+"/assets/css/search/styles-header-2017.css"} type="text/css"/>
              <link href={process.env.PUBLIC_URL+"/assets/css/suppport-search-2021/css/font-awesome.min.css"} rel="stylesheet"/>
              <link href={process.env.PUBLIC_URL+"/assets/css/suppport-search-2021/css/bootstrap.css"} rel="stylesheet" type="text/css"/>
              <link href={process.env.PUBLIC_URL+"/assets/css/suppport-search-2021/css/flexslider.css"} rel="stylesheet" type="text/css"/>
              <link href={process.env.PUBLIC_URL+"/assets/css/suppport-search-2021/css/jquery.mCustomScrollbar.css"} rel="stylesheet" type="text/css"/>
              <link href={process.env.PUBLIC_URL+"/assets/css/suppport-search-2021/css/styles-2017.css"} rel="stylesheet" type="text/css"/>
              <SearchHeaderComponent />
              <div className="search-results-content container left-rail ng-scope">
                

        <div className="row">
          <div className="container results-wrapper">
            <div className="filter-nav-filtered-products">
              <div className="row">
              <div className="custom-right-of-left-rail">
              <div id="content-results" class="ng-scope">
                <div id="search-results" class="ng-scope">
                <div className="row result ng-scope">
        <div className="docMetaData ng-binding" style={{display: 'none'}}>
          [id = os-da1e0307dda75710VgnVCM100000e6651dacRCRD , ot_id_s = ]
        </div>
        <a href="javascript:void(0)" className="result-link clearfix">
          <i className="fa fa-angle-right" aria-hidden="true" style={{marginTop: '70.5px', marginLeft: '799px'}} />
          {/* ngIf: wrapper.image */}<div className="col-xs-3 col-md-2 col-thumbnail ng-scope">
            <div href="javascript:void(0)" className="thumbnail">
              {/* ngIf: wrapper.image.length > 0 */}<img src="/parkerimages/Parker.com/Controlled%20Documents/Support/Support%20Category%20images/literature-documents1.gif" alt="..." className="ng-scope" />{/* end ngIf: wrapper.image.length > 0 */}
              {/* ngIf: wrapper.fileType.length */}<span className="file-type ng-binding ng-scope">.pdf</span>{/* end ngIf: wrapper.fileType.length */}</div></div>{/* end ngIf: wrapper.image */}{/*col-thumbnail*/}
          <div className="col-title col-md-5 col-xs-9">
            <h6>
              <span className="ng-binding">Literature and Reference Materials</span>
              {/*<span ng-repeat="section in rs| asLocaleField : 'siteSection_ss'|unique">{{section}}<span ng-if="!$last">, </span></span>
                    <span ng-repeat="support in rs| asLocaleField : 'supportType_ss'|unique">{{support}}<span ng-if="!$last">, </span></span>
                    <span ng-repeat="subsection in rs| asLocaleField : 'rs.siteSubSection_ss'|unique">{{subsection}}<span ng-if="!$last">, </span></span>
                    <span ng-repeat="resource in rs| asLocaleField : 'supportResourceType_ss'|unique">{{resource}}<span ng-if="!$last">, </span></span>*/}
            </h6>                
            <h3 className="ng-binding">MSG-HCS_Lead-Time Adjustment Announcement_Oct 30_20201030</h3>{/* ngIf: wrapper.starIcon */}
            <div className="title-meta">
              {/* ngIf: wrapper.date */}
              {/* ngIf: wrapper.author */}
              {/* ngIf: wrapper.location */}                    
            </div>
            <div className="title-date-meta">
              {/* ngIf: wrapper.createDate */}<div className="ng-scope">Created Date:<span className="ng-binding"> 30 October 2020</span></div>{/* end ngIf: wrapper.createDate */}
              {/* ngIf: wrapper.publishDate */}<div ng-if="wrapper.publishDate" className="ng-scope">Published Date:<span className="ng-binding"> 30 October 2020</span></div>{/* end ngIf: wrapper.publishDate */}                   
            </div>
          </div>{/* col-title */}
          <div className="col-desc col-md-5 col-xs-9">
            {/* ngIf: wrapper.description.length > 0 */}<div className="desc ng-binding ng-scope">Announcement of Lead-Time Adjustment for GS028* series valve, effective immediately </div>{/* end ngIf: wrapper.description.length > 0 */}
            <div className="desc-meta">
              <h6 className="ng-binding" />
              <h6 className="ng-binding">Product notifications</h6>
            </div>
          </div>{/* col-desc */}
        </a>{/* result-link */}
        <div className="col-md-12 col-actions">
          <div className="btn-group btn-group-justified" role="group" aria-label>
            {/* ngRepeat: inlineAction in inlineActions */}<a href="javascript:void(0)" className="btn btn-default email" role="button"><span className="ng-binding">Email</span></a>{/* end ngRepeat: inlineAction in inlineActions */}
          </div>
        </div>{/* col-actions */}
      </div>
                </div>
              </div>
              </div>
              </div>
					</div>
					</div>
					</div>
					</div>
           </div>
        )
    }
}
export default SupportSearchResult;