import React from "react";
import Table from 'react-bootstrap/Table';
import {NoResultPage} from './NoResultPage';

export default function CrossReference (props){
   
   let results = props.crossrefResults;
   console.log("results==",results)
    return (
      <div> 
        {results.length > 0 ? (
        <div className="table-responsive">
          
            <Table stripped bordered hover size="sm" className="table table-striped"> 
            <thead> 
              <tr> 
                <th>Type</th> 
                <th>Cross Form</th>
                <th>Part Numbers</th> 
                
                <th>Cross To Parker</th> 
                <th>Fit</th>
                <th>Comments</th>
              </tr> 
            </thead> 
            <tbody> 
             {results.map((item, index) => (
              <tr> 
                <td>{item.xrefType_s}</td> 
                <td>{item.xrefManufacturer_s}</td> 
                <td>{item.xrefPartNumber_s}</td> 
                
                <td>{item.urlKeyword_s != undefined || item.urlKeyword_s != null ? <a href={`https://phtest.parker.com/us/en/`+
       item.urlKeyword_s}>{item.xrefCrossToParker_s}</a> : <span>{item.xrefCrossToParker_s}</span>}</td> 
                <td><i className="fa fa-check"></i></td>
                <td>{item.description_t}</td>
            
              </tr> 
              ))}
              {/* <tr> 
              <td>Fittings and Quick Couplings</td> 
                <td>Dixon Quick Coupling</td> 
                <td>F-P-M184B-CCELCONVALVE</td> 
                <td>B23EV</td> 
                <td><i className="fa fa-check"></i></td> 
                <td></td>
            
              </tr>  */}
               {/* <tr> 
              <td>Fittings and Quick Couplings</td> 
                <td>Dixon Quick Coupling</td> 
                <td>F-P-M184B-CCELCONVALVE</td> 
                <td>B23EV</td> 
                <td><i className="fa fa-check"></i></td> 
                <td></td>
            
              </tr> 
              <tr> 
              <td>Fittings and Quick Couplings</td> 
                <td>Dixon Quick Coupling</td> 
                <td>F-P-M184B-CCELCONVALVE</td> 
                <td>B23EV</td> 
                <td><i className="fa fa-check"></i></td> 
                <td></td>
            
              </tr> 
              <tr> 
              <td>Fittings and Quick Couplings</td> 
                <td>Dixon Quick Coupling</td> 
                <td>F-P-M184B-CCELCONVALVE</td> 
                <td>B23EV</td> 
                <td><i className="fa fa-check"></i></td> 
                <td></td>
            
              </tr> 
              <tr> 
              <td>Fittings and Quick Couplings</td> 
                <td>Dixon Quick Coupling</td> 
                <td>F-P-M184B-CCELCONVALVE</td> 
                <td>B23EV</td> 
                <td><i className="fa fa-check"></i></td> 
                <td></td>
            
              </tr>  
             */}
            </tbody> 
              </Table> 
        
          
        </div> 
      ) : <NoResultPage />}
    </div>
  );
}
