import React, { useMemo, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import * as translationUtils from '../../common-utils/translation_utils';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

function StyledDropzone(props) {
  console.log('Status==', props.isDisable);
  const [isChecked, setIsChecked] = useState(props.isChecked);
  const onDrop = useCallback((acceptedFiles) => {
    props.fileReader(acceptedFiles[0]);
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const acceptedFileItems = acceptedFiles.map((file) => {
    return (
      <span key={file.path} className="file_span">
        <img src="/assets/img/Icon.svg" className="file_img" />
        {file.path}
        <br />
        {file.size + ' Bytes'}
      </span>
    );
  });
  console.log('Data', acceptedFiles);
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  function handleCheckBox(e) {
    props.handleCheckBox(!isChecked);
    setIsChecked(!isChecked);
  }

  return (
    <>
      <div className="container">
        {(acceptedFiles.length <= 0 || props.csvFile == null) && (
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <p className="drag_icon">
              <img src="/assets/img/Upload-01.svg" />
            </p>
            <p className="drg_pr">
              {translationUtils.getTranslation(
                2197,
                'Drag and drop files here or click to select files'
              )}
            </p>
          </div>
        )}
        {
          acceptedFiles.length > 0 &&
            props.csvFile &&
            props.status != 'Success' && (
              <div className="file_uploadedd">
                <aside className="file_up_load">
                  {acceptedFileItems}
                  <span>
                    <i
                      class="far fa-trash-alt"
                      onClick={() => props.resetFile()}
                    ></i>
                  </span>
                </aside>
                {props.isDisable && (
                  <p style={{ marginTop: '40px' }}>
                    <input
                      type="checkbox"
                      name="conf"
                      value={props.isChecked}
                      checked={props.isChecked}
                      onChange={(e) => handleCheckBox(e)}
                    />
                    &nbsp; By accepting this upload it is acknowledged that a
                    new MyParker account will be created and if the email is
                    currently registered with Parker the First and Last Name
                    fields will be updated.
                  </p>
                )}
              </div>
            )
          // <div className="file_load">
          //   <p className="file_up_load">

          //     <aside>
          //       {acceptedFileItems}
          //     </aside>

          //   </p>
          // </div>
        }
        {props.status == 'Success' && props.csvFile && (
          <div {...getRootProps({ style })}>
            {/*<input {...getInputProps()} />*/}
            <p className="drag_icon">
              <img src="/assets/img/Upload-02.svg" />
            </p>
            <p className="drag_pr_success">
              {translationUtils.getTranslation(2040, 'Success')}
            </p>
            <p className="drg_pr">
              {translationUtils.getTranslation(
                2198,
                'We have imported users successfully from CSV file.'
              )}
            </p>
          </div>
        )}
      </div>
    </>
  );
}

export default StyledDropzone;
