import React, { Component } from 'react'
import * as translationUtils from '../../common-utils/translation_utils'
import './css/style.css'; 
class manageuser extends Component {
    render() {
        return (
            <div className="container">
                <div className="header-rectangle">
                    <div className="header-details padding-margin">
                    {translationUtils.getTranslation(1167,"Edit") +" "+translationUtils.getTranslation(2185,"User")}
                    </div>
                </div>
                <div className="navigation-breadcrumb-browse padding-margin">
                {translationUtils.getTranslation(791, "Home")} / {translationUtils.getTranslation(2167, "Authorized Users")}  / {translationUtils.getTranslation(1167,"Edit") +" "+translationUtils.getTranslation(2185,"User")}
                </div>
                <div className="item-spacing"></div>
                <div className="user-heading">
                    <div className="header-details">
                    {translationUtils.getTranslation(2178,"User Details")} 
                         <div className="line"></div>
                    </div>
                    <div className="user-details-container">
                        <div className="item">
                            <div className="rectangle-header">
                                <div className="sub-headers">{translationUtils.getTranslation(2179,"Profile")}</div>
                            </div>
                            <div className="profile-container">
                                <div className="item-spacing">
                                <label for="emailId">{translationUtils.getTranslation(2182,"User ID")}/ {translationUtils.getTranslation(408, "Email")}</label>
                                <input type="text" id="fname" name="emailId" placeholder="Email"/>
                                </div>
                                <div className="item-spacing">
                                <label for="fname">{translationUtils.getTranslation(61, "First Name")}</label>
                                <input type="text" id="lname" name="firstname" placeholder={translationUtils.getTranslation(61, "First Name")}/>
                                </div>
                                <div className="item-spacing">
                                <label for="lname">{translationUtils.getTranslation(62, "Last Name")}</label>
                                <input type="text" id="fname" name="lastname" placeholder={translationUtils.getTranslation(62, "Last Name")}/>
                                </div>
                                <div style={{ textAlign:"right" }}>
                                <button type="button" className="button round-button">{translationUtils.getTranslation(1075, "Save")}</button>
                                </div>
                                
                            </div>
                        </div>

                        <div className="mid-item">
                            <div className="mid-item-a">
                                <div className="rectangle-header">
                                <div className="sub-headers">{translationUtils.getTranslation(1274, "Password")}</div>
                                </div>
                                <div className="btn-reset-div">
                                <button type="button" className="button round-button btn-reset-inline">{translationUtils.getTranslation(58, "Reset")+' '+translationUtils.getTranslation(1274, "Password")}</button>
                                </div>
                                <div className="send-a-reset-password">
                                {translationUtils.getTranslation(2209, "Send a reset password link or sent a temporary one.")}
                                </div>
                            </div>
                            <div className="mid-item-b">
                                <div className="rectangle-header">
                                <div className="sub-headers">{translationUtils.getTranslation(2180,"Account Status")}</div>
                                </div>
                                <div className="btn-active">
                                <div>
                                <input type="radio" value="Active" checked name="accountstatus"/>
                                <label for="Active" className="label-style">{translationUtils.getTranslation(2169,"Active")}</label>
                                </div>
                                <div style={{flex:"2"}}>
                                <input type="radio" value="Disabled" name="accountstatus"/>
                                <label for="Disabled" className="label-style">{translationUtils.getTranslation(1274, "Disabled")}</label>
                                </div>
                                </div>
                                <div className="btn-save">
                                <button type="button" className="button round-button">{translationUtils.getTranslation(1075, "Save")}</button>
                                </div>
                            </div>
                            
                        </div>

                        <div className="item1">
                            <div className="rectangle-header">
                                <div className="sub-headers">{translationUtils.getTranslation(2181,"Login History")}</div>
                            </div>
                            <div className="login-history-container">
                            <div className="rowpt">
                                <div className="login-history">11/12/2020 10.20 pm </div>
                                <div>User Login to site</div>
                            </div>
                            <div className="rowpt">
                                <div className="login-history">11/12/2020 10.50 pm </div>
                                <div>User Update Password to site</div>
                            </div>
                            <div className="rowpt">
                                <div className="login-history">11/12/2020 10.20 pm </div>
                                <div>User Login to site</div>
                            </div>
                            <div className="rowpt">
                                <div className="login-history">11/12/2020 10.50 pm </div>
                                <div>User Update Password to site</div>
                            </div>
                            <div className="rowpt">
                                <div className="login-history">11/12/2020 10.20 pm </div>
                                <div>User Login to site</div>
                            </div>
                            <div className="rowpt">
                                <div className="login-history">11/12/2020 10.50 pm </div>
                                <div>User Update Password to site</div>
                            </div>
                            <div className="rowpt">
                                <div className="login-history">11/12/2020 10.50 pm </div>
                                <div>User Update Password to site</div>
                            </div>
                            <div className="rowpt">
                                <div className="login-history">11/12/2020 10.50 pm </div>
                                <div>User Update Password to site</div>
                            </div>
                            </div>
                        </div>

                    </div>

                    <div className="header-details assigned-style">
                    {translationUtils.getTranslation(2183,"Assigned Locations")} 
                    <div className="line"></div>
                    </div>

                    <div className="flex-container-assigned-loc">
                        <div className="flex-child-buttons">
                            <button type="button" className="button round-button muted-button">{translationUtils.getTranslation(2184,"Edit Multiple Location Roles")} </button>
                        </div>
                        <div className="flex-child-buttons">
                            <button type="button" className="button round-button user-btn-add">{translationUtils.getTranslation(2219,"Add Location")}</button>
                        </div>
                    </div>

                    <div className="container-table">
                        <div className="table-wrapper">
                            <table className="table table-striped">
                            <thead className="table-th-style">
                                <tr>
                                <th className="table-checkbox-style">
                                    <span className="custom-checkbox">
                                    <input type="checkbox" id="selectAll"/>
                                    <label for="selectAll"></label>
                                    </span>
                                </th>
                                <th className="table-header-style">{translationUtils.getTranslation(2175,"Location Number")} <i className="fa fa-sort" aria-hidden="true"></i></th>
                                <th className="table-header-style"> {translationUtils.getTranslation(2176,"Location Name")} <i className="fa fa-sort" aria-hidden="true"></i></th>
                                <th className="table-header-style">{translationUtils.getTranslation(2177,"Roles")} <i className="fa fa-sort" aria-hidden="true"></i></th>
                                <th className="table-header-style">{translationUtils.getTranslation(1064,"Actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>
                                    <span className="custom-checkbox">
                                    <input type="checkbox" id="checkbox1" name="options[]" value="1"/>
                                    <label for="checkbox1"></label>
                                    </span>
                                </td>
                                <td>0000213456</td>
                                <td>Location 1 value</td>
                                <td>
                                    <select name="role_filter_for" className="table-dropdown">
                                    <option value="Divison Sales Manager">Divison Sales Manager</option>
                                    <option value="Marketing Manager">Marketing Manager</option>
                                    <option value="Administrator/Buyer">Administrator/Buyer</option>
                                    <option value="Divison Sales Divisiion Head Manager">Divison Sales Divisiion Head Manager</option>
                                    <option value="Divison Sales Staff">Divison Sales Staff</option>
                                    </select>
                                </td>
                                <td>
                                    <a href="#" className="done">
                                    <i className="fa fa-check done-icon user-anchor-style" aria-hidden="true"></i>
                                    {translationUtils.getTranslation(1075,"Save")}
                                    </a>
                                    <a href="#" className="remove">
                                    <i className="fa fa-times user-anchor-style" aria-hidden="true"></i>
                                    {translationUtils.getTranslation(797,"Remove")}
                                    </a>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <span className="custom-checkbox">
                                    <input type="checkbox" id="checkbox1" name="options[]" value="1"/>
                                    <label for="checkbox1"></label>
                                    </span>
                                </td>
                                <td>0000213456</td>
                                <td>Location 1 value</td>
                                <td>89 Chiaroscuro Rd, Portland, Phnom Penh</td>
                                <td>
                                    <a href="#" className="edit">
                                    <i className="fa fa-pencil user-anchor-style" aria-hidden="true"></i>
                                    Edit Role
                                    </a>
                                    <a href="#" className="remove">
                                    <i className="fa fa-times user-anchor-style" aria-hidden="true"></i>
                                    Remove
                                    </a>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <span className="custom-checkbox">
                                    <input type="checkbox" id="checkbox1" name="options[]" value="1"/>
                                    <label for="checkbox1"></label>
                                    </span>
                                </td>
                                <td>0000213456</td>
                                <td>Location 1 value</td>
                                <td>89 Chiaroscuro Rd, Portland, Phnom Penh</td>
                                <td>
                                    <a href="#" className="edit">
                                    <i className="fa fa-pencil user-anchor-style" aria-hidden="true"></i>
                                    Edit Role
                                    </a>
                                    <a href="#" className="remove">
                                    <i className="fa fa-times user-anchor-style" aria-hidden="true"></i>
                                    Remove
                                    </a>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <span className="custom-checkbox">
                                    <input type="checkbox" id="checkbox1" name="options[]" value="1"/>
                                    <label for="checkbox1"></label>
                                    </span>
                                </td>
                                <td>0000213456</td>
                                <td>Location 1 value</td>
                                <td>89 Chiaroscuro Rd, Portland, Phnom Penh</td>
                                <td>
                                    <a href="#" className="edit">
                                    <i className="fa fa-pencil user-anchor-style" aria-hidden="true"></i>
                                    Edit Role
                                    </a>
                                    <a href="#" className="remove">
                                    <i className="fa fa-times user-anchor-style" aria-hidden="true"></i>
                                    {translationUtils.getTranslation(797, "Remove")}
                                    </a>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <span className="custom-checkbox">
                                    <input type="checkbox" id="checkbox1" name="options[]" value="1"/>
                                    <label for="checkbox1"></label>
                                    </span>
                                </td>
                                <td>0000213456</td>
                                <td>Location 1 value</td>
                                <td>89 Chiaroscuro Rd, Portland, Phnom Penh</td>
                                <td>
                                    <a href="#" className="edit">
                                    <i className="fa fa-pencil user-anchor-style" aria-hidden="true"></i>
                                    Edit Role
                                    </a>
                                    <a href="#" className="remove">
                                    <i className="fa fa-times user-anchor-style" aria-hidden="true"></i>
                                    {translationUtils.getTranslation(797, "Remove")}
                                    </a>
                                </td>
                                </tr>
                                <tr>
                                <td>
                                    <span className="custom-checkbox">
                                    <input type="checkbox" id="checkbox1" name="options[]" value="1"/>
                                    <label for="checkbox1"></label>
                                    </span>
                                </td>
                                <td>0000213456</td>
                                <td>Location 1 value</td>
                                <td>89 Chiaroscuro Rd, Portland, Phnom Penh</td>
                                <td>
                                    <a href="#" className="edit">
                                    <i className="fa fa-pencil user-anchor-style" aria-hidden="true"></i>
                                    Edit Role
                                    </a>
                                    <a href="#" className="remove">
                                    <i className="fa fa-times user-anchor-style" aria-hidden="true"></i>
                                    {translationUtils.getTranslation(797, "Remove")}
                                    </a>
                                </td>
                                </tr>
                            </tbody>
                            </table>
                            
                        </div>
                    </div>
                    

                </div>
            </div>
        )
    }
}
export default manageuser;
