import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {
  Security,
  SecureRoute,
  LoginCallback,
  AuthService,
} from "@okta/okta-react";
import { createBrowserHistory } from "history";
import config from "./config";
import MainWrapper from "./page/main-wrapper";
import UserTokens from "./containers/delegatedAdmin/token/UserTokens";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const App = () => {
  const authService = new AuthService({
    ...config.oidc,
    isAuthenticated: async () => {
      let isUserAuthenticated = false;
      const existsData = await authService._oktaAuth.session.exists();
      console.log(existsData, "--- existsData");
      await authService._oktaAuth.session.exists().then(async (exists) => {
        console.log(exists, "-- exists");
        if (exists) {
          const idToken = await authService.getTokenManager().get("idToken");
          const accessToken = await authService
            .getTokenManager()
            .get("accessToken");
          isUserAuthenticated = !!(idToken && accessToken);
          console.log(idToken, "-- idToken (If exists=true)");
          console.log(accessToken, "-- accessToken (If exists=true)");
          console.log(
            isUserAuthenticated,
            "-- isUserAuthenticated (If exists=true)"
          );
        }
      });
      if (!isUserAuthenticated) {
        console.log(isUserAuthenticated, "-- !isUserAuthenticated");
        console.log(authService, "-- authService (If !isUserAuthenticated)");
        await authService._oktaAuth.token
          .getWithoutPrompt({ responseType: ["token", "id_token"] })
          .then(function (res) {
            authService._oktaAuth.tokenManager.add(
              "idToken",
              res.tokens.idToken
            );
            authService._oktaAuth.tokenManager.add(
              "accessToken",
              res.tokens.accessToken
            );
            isUserAuthenticated = true;
          })
          .catch(function (err) {
            console.log(err);
          });
      }
      return isUserAuthenticated;
    },
  });
  return (
    <Router history={history}>
      <Security authService={authService}>
        <SecureRoute exact path="/" component={MainWrapper} />
        <SecureRoute
          exact
          path="/:langCode/myparker/:type"
          component={MainWrapper}
        />
        <SecureRoute exact path="/:langCode/mpsearch" component={MainWrapper} />
        <Route path="/implicit/callback" component={LoginCallback} />
      </Security>
      <Route exact path="/EN/myparker/tokenmanage" component={UserTokens} />
    </Router>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
