import React, { useState, useEffect } from 'react';

const PopUpComponent = (props) => {
  const { status, message } = props;
  const [count, setCount] = useState(500);
  useEffect(() => {
    const interval = setTimeout(() => {
      //console.log('This will run every second!');
      setCount(count - 500);
    }, 5000);
  }, []);

  return (
    <>
      {status == 'Success' || status == 'success' ? (
        <div
          className="ph-alert ph-alert__success"
          id="success-alert"
          style={{ display: 'block', opacity: 500 }}
        >
          {message}
        </div>
      ) : status == 'P_Success' || status == 'P_success' ? (
        <div
          className="ph-alert informational_v1"
          id="info-alert"
          style={{ display: 'block', opacity: 500 }}
        >
          {message}
        </div>
      ) : (
        <div
          class="ph-alert ph-alert__warning"
          id="warning-alert"
          style={{ display: 'block', opacity: 500 }}
        >
          {message}
        </div>
      )}

      {/*<div className="ph-modal fade show in" id="editMultipleLocation" tabIndex={-1} role="dialog" aria-labelledby="Reset Password Modal" style={{display: 'block'}}>
        <div className="modal-dialog" role="document">
          <div className="ph-modal__content ph-modal__content__wider" style={{width: '30%'}}>
            <div className="d-flex justify-content-end m-0 p-0">
              <span data-dismiss="modal" aria-label="Close" className="ph-modal__header--close ph-modal-header-close-font-size" aria-hidden="true"><i className="fas fa-times" aria-hidden="true" /></span></div>
            <div className="ph-modal__body">
              <form>
                <div className="item-spacing" style={{textAlign: 'left'}}>
                  <span>{message}</span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default PopUpComponent;
