import React, { Component } from 'react'
import './assets/styles/custom-styles.css';
import './assets/styles/media-styles.css';
import './assets/styles/sidebar.css';
import ResultTypeTabs from './components/ResultTypeTabs';
import PageTitle  from './components/PageTitle';
import Pagination from './components/Pagination';
// import "https://cdn.tailwindcss.com";

export default class MyParkerSearch extends Component {

    render() {
            return (
                <div className="search-app">
              
                    <PageTitle />
                    <div className="ph-search-wrapper container-fluid">     
                    <ResultTypeTabs />
                     {/* <Pagination />  */}
                    </div>
              </div>
                )

            }
}