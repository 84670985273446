import React from 'react';
import * as translationUtils from '../../common-utils/translation_utils'

const L1TilesList = props => {
    let flag=false;
    const {l1Tiles, orgName } = props;
    console.log("orgName",orgName)
    function splitDesktopL1Cat(colNum) {
        let jQuery=window.jQuery;
        var colNum = colNum;
        var total = jQuery(".tileList").children('div').size();
        var count = total / colNum;
        var column = 0;
        if(total%3 == 0){
            total = total;
        }else{
            total = total+2;
        }
        let row=3;
	    let index=0;
        for (var i = 0; i < total/3; i++) {
            //column++;
            
            jQuery(".acme-support-assets-block").append('<div class="col-xs-12 col-md-12 pad0 marT30" id="new-productUll' + i + '"></div>');
         //for(var j=index;j<row;j++){
            jQuery("#new-productUll" + i).html(jQuery(".tileList div#L1").slice(0,3));
         //}   
        
        index = index+3;
		row = row+3;
        }
        //jQuery('#new-product-flyout').css('display', '')
        jQuery(".tileList").remove();
        /* Adjust Botton and Top Margin for ACME Support Assets */
        jQuery( document ).ready(function() {
            jQuery('.acme-support-assets-block').children('div:first').removeClass('marT30');
            jQuery('.acme-support-assets-block').children('div:last').addClass('marB10');
            jQuery('.acmeSupportTB .acme-support-block').children('div:first').removeClass('next-row');
            });
       }
       function splitMobileL1Cat(colNum) {
        let jQuery=window.jQuery;
        var colNum = colNum;
        var total = jQuery(".catTileList1").children('div').size();
        var count = total / colNum;
        var column = 0;
        if(total%3 == 0){
            total = total;
        }else{
            total = total+2;
        }
        let row=3;
	    let index=0;
        for (var i = 0; i < total/3; i++) {
            //column++;
            
            jQuery("#catTileL1").append('<div class="col-sm-12 pad0 next-row" id="new-catl1' + i + '"></div>');
         //for(var j=index;j<row;j++){
            jQuery("#new-catl1" + i).html(jQuery(".catTileList1 div#L2").slice(0,3));
         //}   
        
        index = index+3;
		row = row+3;
        }
        //jQuery('#new-product-flyout').css('display', '')
        jQuery(".catTileList1").remove();
        /* Adjust Botton and Top Margin for ACME Support Assets */
        jQuery( document ).ready(function() {
            jQuery('#catTileL1').children('div:first').removeClass('next-row');
            });
       }
       let jQuery=window.jQuery;
       
       if(!jQuery('body').hasClass('support-page')){
        jQuery('body').addClass('support-page');
       }
       jQuery('.support-heading-block').click(function() {
        var id = jQuery(this).find('p')[0].id
        if (id == 'P825716539')
        jQuery('form[ name="searchForm3"]').append('<input type="hidden" name="isSupport" value="Y">');
        if(jQuery(this).find('p').hasClass('CAD')){
            jQuery('form[ name="searchForm3"]').append('<input type="hidden" name="contentType" value="cad">');
        }if(jQuery(this).find('p').hasClass('config')){
            jQuery('form[ name="searchForm3"]').append('<input type="hidden" name="contentType" value="config">');
        }  
    
        jQuery('form[ name="searchForm3"]').append('<input type="hidden" name="audianceType" value="private">');
        jQuery('form[ name="searchForm3"]').append('<input type="hidden" name="isSupportL1" value="Y">');
        jQuery('form[ name="searchForm3"]').append('<input type="hidden" name="supportType' + jQuery.parker.search.localeCode + '_fq" value=' + id + '>');
        //$("input[name='supportTypeEN_fq']").val(id)
        jQuery('.searchForm #keyWord').val('*')
        jQuery('form[ name="searchForm3"]').submit();
    });

       if(l1Tiles!=null && l1Tiles!=undefined){
        splitDesktopL1Cat(3);
        splitMobileL1Cat(3);
        
       }
      

    return (
        <div>
            { (l1Tiles.length>0 && l1Tiles!=null) &&
        <div className="container products-categories-container acme-support-assets-block secton-headings hidden-xs ipad-hidden" id="tileL1">
                      <h1 className="left">{window.name+" "+translationUtils.getTranslation(1710,"Support Asset Categories")}</h1> 
        
        <div class="tileList">
            
			 {
                    l1Tiles  && l1Tiles.map((tiles, index) => 
                                            
                        <div id="L1" className="col-xs-12 col-md-4 col-sm-4 support-block ">
                            <div className="left support-heading-block" tabIndex={0}>
                                <p id={tiles.supportCatCode_si} className={tiles.supportCatType_si!=null?tiles.supportCatType_si:''}>{tiles.supportCatTitle_si}</p>
                             </div>
                             <div className="right support-image">
                            <img src={"http://previewdev.parker.com/parkerimages"+tiles.supportCatImage_si} height={115} width={115} alt="Support Image" title={tiles.supportCatTitle_si} />
                             </div>
                        </div>
				
             )}

		</div> 
             </div> }
            {/*Mobile view*/}
            { (l1Tiles.length>0 && l1Tiles!=null) &&
            <div className="container products-categories-container secton-headings hidden-lg acmeSupportTB" id="catTileL1">
                      <h1 className="acme-support-heading">{window.name+" "+translationUtils.getTranslation(1710,"Support Asset Categories")}</h1>
        
        <div class="catTileList1">
            
			 {
                    l1Tiles  && l1Tiles.map((tiles, index) => 
                                            
                        <div id="L2" className="col-xs-12 col-md-4 col-sm-4 support-block ">
                            <div className="left support-heading-block" tabIndex={0}>
                                <p id={tiles.supportCatCode_si} className={tiles.supportCatType_si!=null?tiles.supportCatType_si:''}>{tiles.supportCatTitle_si}</p>
                             </div>
                             <div className="right support-image">
                            <img src={"http://previewdev.parker.com/parkerimages"+tiles.supportCatImage_si} height={90} width={90} alt="Support Image" title={tiles.supportCatTitle_si} />
                             </div>
                        </div>
				
             )}

		</div> 
             </div> }

        </div>
        
        
        
									
    )
}

export default L1TilesList;
